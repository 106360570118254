import { useEffect, useState } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import style from "./cssModules/newSolicitude.module.css";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import {
  getClientes,
  getFunctionaries,
  insertNewSolicitude,
  storage,
  updateDocIdSolicitudes,
  updateSolicitude,
} from "../firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import EvaluacionRiesgos from "../components/evaluacionDeRiesgos";
import { ToastContainer, toast } from "react-toastify";
import data2 from "../resources/colombia.json";
import ToggleButton from "../components/toggleButton";
import { useAuthContext } from "../contexts/authContext";
import NewDairySolicitudes from "../components/newDairySolicitudes";
import imagenes from "../resources/images/imagenes";

const expresiones = {
  usuario: /^[a-zA-Z0-9\_\-]{4,16}$/,
  fechas: /^(2)(\d{3})[\/\-]\d{2}[\/\-]\d{2}$/, // Letras, numeros, guion y guion_bajo
  nombre: /^[a-zA-ZÀ-ÿ\s.-_]{1,200}$/, // Letras y espacios, pueden llevar acentos.
  tipoDesplazamiento: /^[a-zA-ZÀ-ÿ\s\-]{1,40}$/,
  observaciones: /^[\s\S]*$/,
  password: /^.{4,12}$/, // 4 a 12 digitos.
  correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  telefono: /^\d{7,14}$/, // 7 a 14 numeros.
};

const valorReset = { campo: "", valido: null };

export default function NewSolicitudeView() {
  const { user, isLoading } = useAuthContext();
  const fechaActual = new Date();
  const day = fechaActual.getDate();
  const month = fechaActual.getMonth() + 1;
  const year = fechaActual.getFullYear();
  let time = new Date().toLocaleTimeString();
  let fechaActualizada = `${year}/${month}/${day}`;
  const [cliente, setCliente] = useState(valorReset);
  const [funcionarios, setFuncionarios] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(valorReset);
  const [fechaFin, setFechaFin] = useState(valorReset);
  const [tipoDeDesplazamiento, setTipoDeDesplazamiento] = useState(valorReset);
  const [riesgo, setRiesgo] = useState("");
  const [observaciones, setObservaciones] = useState(valorReset);
  const [dataClientes, setDataClientes] = useState([]);
  const [dataFuncionarios, setDataFuncionarios] = useState([]);
  const [solicitudes, setSolicitudes] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [progress, setProgress] = useState(0);
  const [linkRoute, setLinkRoute] = useState("");
  const [selectedInitialDepartment, setSelectedInitialDepartment] =
    useState(valorReset);
  const [initialTowns, setInitialTowns] = useState([]);
  const [selectedInitialTown, setSelectedInitialTown] = useState(valorReset);
  const [selectedFinalDepartment, setSelectedFinalDepartment] =
    useState(valorReset);
  const [finalTowns, setFinalTowns] = useState([]);
  const [selectedFinalTown, setSelectedFinalTown] = useState(valorReset);
  const [funcionariosFiltrados, setFuncionariosFiltrados] = useState([]);
  const [mensajeJSON, setMensajeJSON] = useState("");
  const [completeRoute, setCompleteRoute] = useState(valorReset);
  const [puntuacionDeRiesgo, setPuntuacionDeRiesgo] = useState(0);
  const [rutaDelDia, setRutaDelDia] = useState("");
  const [evaluacionDeRiesgos, setEvaluacionDeRiesgos] = useState({});
  const [botonHabilitado, setBotonHabilitado] = useState(false);
  const [res, setRes] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    getClientes()
      .then((resClientes) => setDataClientes([...resClientes]))
      .catch((error) => console.error(error));
    getFunctionaries()
      .then((resFuncionarios) => setDataFuncionarios([...resFuncionarios]))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    setRiesgo(mensajeJSON);
  }, [mensajeJSON]);

  const getClaseCSS = (estadoInputColor) => {
    if (estadoInputColor === "Crítico") {
      return style.circleCritico;
    } else if (estadoInputColor === "Alto") {
      return style.circleAlto;
    } else if (estadoInputColor === "Medio") {
      return style.circleMedio;
    } else {
      return style.circleBajo;
    }
  };

  const eliminarFuncionario = (funcionario) => {
    const funcionarioEliminado = funcionarios.filter((n) => n !== funcionario);
    setFuncionarios(funcionarioEliminado);
  };

  const validacionFechaInicio = () => {
    if (expresiones.fechas.test(fechaInicio.campo)) {
      setFechaInicio({ ...fechaInicio, valido: true });
    } else {
      setFechaInicio({ ...fechaInicio, valido: false });
    }
  };

  const cambioDeClaseFechaInicio = () => {
    if (fechaInicio.valido !== null) {
      if (fechaInicio.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const validacionFechaFin = () => {
    /* if(fechaFin.campo===''){ */
    if (expresiones.fechas.test(fechaFin.campo)) {
      setFechaFin({ ...fechaFin, valido: true });
    } else {
      setFechaFin({ ...fechaFin, valido: false });
    }
    /* } */
  };

  const cambioDeClaseFechaFin = () => {
    if (fechaFin.valido !== null) {
      if (fechaFin.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const validacionDeCliente = () => {
    if (expresiones.nombre.test(cliente.campo)) {
      setCliente({ ...cliente, valido: true });
    } else {
      setCliente({ ...cliente, valido: false });
    }
  };

  const cambioDeClaseCliente = () => {
    if (cliente.valido !== null) {
      if (cliente.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const validacionTipoDeDesplazamiento = () => {
    if (expresiones.tipoDesplazamiento.test(tipoDeDesplazamiento.campo)) {
      setTipoDeDesplazamiento({ ...tipoDeDesplazamiento, valido: true });
    } else {
      setTipoDeDesplazamiento({ ...tipoDeDesplazamiento, valido: false });
    }
  };

  const cambioDeClaseDesplazamiento = () => {
    if (tipoDeDesplazamiento.valido !== null) {
      if (tipoDeDesplazamiento.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const validacionDepartamentoInicial = () => {
    if (expresiones.nombre.test(selectedInitialDepartment.campo)) {
      setSelectedInitialDepartment({
        ...selectedInitialDepartment,
        valido: true,
      });
    } else {
      setSelectedInitialDepartment({
        ...selectedInitialDepartment,
        valido: false,
      });
    }
  };

  const cambioDeClaseDepartamentoInicial = () => {
    if (selectedInitialDepartment.valido !== null) {
      if (selectedInitialDepartment.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const cambioDeClaseDepartamentoFinal = () => {
    if (selectedFinalDepartment.valido !== null) {
      if (selectedFinalDepartment.valido === false) {
        return style.inputWrong;
      }
    }
  };

  function handleOnChangeSectionClient(event) {
    setCliente({ ...cliente, campo: event.target.value });
    filtrarFuncionariosPorCliente(event.target.value);
  }

  function agregarOpcion(e) {
    const opcionSeleccionada = e.target.value;
    setFuncionarios([...funcionarios, opcionSeleccionada]);
  }

  const filtrarFuncionariosPorCliente = (clienteId) => {
    const funcionariosFiltrados = dataFuncionarios.filter(
      (dataFuncionarios) =>
        dataFuncionarios.client.toUpperCase() === clienteId.toUpperCase()
    );
    const funcionariosOrdenados = funcionariosFiltrados.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setFuncionariosFiltrados(funcionariosOrdenados);
  };

  function handleOnChangeScrollType() {
    const seccion2 = document.querySelector("#tipoDesplazamiento");
    setTipoDeDesplazamiento({ ...tipoDeDesplazamiento, campo: seccion2.value });
  }

  // Función para actualizar los municipios cuando se seleccione un departamento
  const handleInitialDepartmentChange = (event) => {
    filtrarMunicipioInicialPorDepartamento(event.target.value);
    setSelectedInitialDepartment({
      ...selectedInitialDepartment,
      campo: event.target.value,
    });
  };

  const filtrarMunicipioInicialPorDepartamento = (departamentoInicial) => {
    try {
      const selectedData = data2.departamentos.find(
        (depto) => depto.departamento === departamentoInicial
      );
      setInitialTowns(selectedData.municipios);
    } catch (error) {
      cambioDeClaseDepartamentoInicial(departamentoInicial);
    }
  };

  const handleFinalDepartmentChange = (event) => {
    filtrarMunicipioFinalPorDepartamento(event.target.value);
    setSelectedFinalDepartment({
      ...selectedFinalDepartment,
      campo: event.target.value,
    });
  };

  const filtrarMunicipioFinalPorDepartamento = (departamentoFinal) => {
    try {
      const selectedDataFinal = data2.departamentos.find(
        (depto) => depto.departamento === departamentoFinal
      );
      setFinalTowns(selectedDataFinal.municipios);
    } catch (error) {
      cambioDeClaseDepartamentoFinal(departamentoFinal);
    }
  };

  function handleOptionChange(event, optionValue) {
    const isChecked = event.target.checked;
    setSelectedOptions((prevSelectedOptions) => {
      if (isChecked) {
        return [...prevSelectedOptions, optionValue];
      } else {
        return prevSelectedOptions.filter((value) => value !== optionValue);
      }
    });
  }

  function handleChangeInputs(e) {
    const valor = e.target.value;
    if (valor.startsWith(" ")) return;
    if (e.target.name === "rutaDelDia") {
      setRutaDelDia(e.target.value.toUpperCase());
    }
    if (e.target.name === "itinerarioDeComision") {
      setCompleteRoute({
        ...completeRoute,
        campo: e.target.value.toUpperCase(),
      });
    }
    if (e.target.name === "observaciones") {
      setObservaciones({
        ...observaciones,
        campo: e.target.value,
      });
    }
  }

  const validacionObservaciones = () => {
    if (expresiones.observaciones.test(observaciones.campo)) {
      setObservaciones({ ...observaciones, valido: true });
    } else {
      setObservaciones({ ...observaciones, valido: false });
    }
  };

  const cambioDeClaseObservaciones = () => {
    if (observaciones.valido !== null) {
      if (observaciones.valido === false) {
        return style.inputWrong;
      }
    }
  };

  const uploadFile = () => {
    if (fileUpload == null) return;
    const fileRef = ref(storage, `files/${fileUpload.name + uuidv4()}`);
    const uploadTask = uploadBytesResumable(fileRef, fileUpload);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        console.error(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setLinkRoute(url);
        });
      }
    );
  };

  // Llamar a verificarCamposLlenos cada vez que cambie un campo
  useEffect(() => {
    const verificarCamposLlenos = () => {
      if (
        fechaInicio.campo !== "" &&
        fechaFin.campo !== "" &&
        cliente.campo !== "" &&
        completeRoute.campo !== "" &&
        selectedInitialTown.campo !== "" &&
        selectedFinalTown.campo !== "" &&
        linkRoute !== "" &&
        funcionarios.length > 0 &&
        riesgo !== ""
      ) {
        setBotonHabilitado(true);
      } else {
        setBotonHabilitado(false);
      }
    };
    verificarCamposLlenos();
  }, [
    fechaInicio.campo,
    fechaFin.campo,
    cliente.campo,
    completeRoute.campo,
    selectedInitialTown.campo,
    selectedFinalTown.campo,
    linkRoute,
    funcionarios,
    riesgo,
  ]);

  const notify = () => {
    toast.success("Solicitud creada satisfactoriamente", {
      theme: "colored",
    });
  };

  const notifyError = () => {
    toast.error("Error al crear la solicitud", {
      theme: "colored",
    });
  };

  async function handleOnSubmit(e) {
    e.preventDefault();
    try {
      await addInformation();
      setCliente(valorReset);
      setFechaInicio(valorReset);
      setFechaFin(valorReset);
      setTipoDeDesplazamiento(valorReset);
      setCompleteRoute(valorReset);
      setSelectedInitialDepartment(valorReset);
      setSelectedInitialTown(valorReset);
      setSelectedFinalDepartment(valorReset);
      setSelectedFinalTown(valorReset);
      setFuncionarios([]);
      setLinkRoute("");
      setObservaciones(valorReset);
      setProgress(0);
      setRutaDelDia("");
    } catch (error) {
      console.error(error);
    }
  }

  async function addInformation() {
    try {
      if (
        fechaInicio.campo !== "" &&
        fechaFin.campo !== "" &&
        cliente.campo !== "" &&
        completeRoute.campo !== "" &&
        selectedInitialTown.campo !== "" &&
        selectedFinalTown.campo !== "" &&
        linkRoute !== "" &&
        funcionarios.length !== 0 &&
        riesgo !== ""
      ) {
        const fechaInicioDate = new Date(fechaInicio.campo + "T00:00:00");
        const fechaFinDate = new Date(fechaFin.campo + "T00:00:00");
        const newSolicitude = {
          initialDate: fechaInicioDate,
          finalDate: fechaFinDate,
          client: cliente.campo.toUpperCase(),
          initialDepartment: selectedInitialDepartment.campo.toUpperCase(),
          initialTown: selectedInitialTown.campo.toUpperCase(),
          finalDepartment: selectedFinalDepartment.campo.toUpperCase(),
          finalTown: selectedFinalTown.campo.toUpperCase(),
          routeImage: linkRoute,
          scrollType: tipoDeDesplazamiento.campo.toUpperCase(),
          functionaries: funcionarios,
          risk: riesgo.toUpperCase(),
          diaryRoute: rutaDelDia,
          completeRoute: completeRoute.campo,
          observations: observaciones.campo,
          actualDate: fechaActualizada,
          actualTime: time,
          timeStamp: serverTimestamp(),
          evaluacionDeRiesgos,
          state: "Nuevo",
          uid: user.uid,
          id: uuidv4(),
        };
        const response = await insertNewSolicitude(newSolicitude);
        setData(newSolicitude);
        newSolicitude.docId = response.id;
        setSolicitudes([...solicitudes, newSolicitude]);
        response && response.id && updateDocIdSolicitudes(response);
        updateDocIdSolicitudes(response);
        setRes(response);
        notify();
      } else {
        throw new Error("Llene todos los campos");
      }
    } catch (error) {
      notifyError(error);
      console.error(error);
    }
  }

  async function handleUpdateStateSolicitude(id, state) {
    await updateSolicitude(id, { state: state })
      .then(() => notify())
      .then(setRes(null))
      .catch((error) => console.error(error));
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <h2>Gerenciamiento de viaje</h2>
          {res ? (
            <div>
              <section className={style.checkContainer}>
                <label
                  htmlFor=""
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    color: "#808080",
                  }}
                >
                  Gerenciamiento exitoso
                  <img
                    src={imagenes.check}
                    alt="Gerenciamiento exitoso"
                    className={style.imgCheck}
                  ></img>
                </label>
              </section>
              <section className={style.contenedor}>
                <h2>Primer punto de control</h2>
                <NewDairySolicitudes
                  idSolicitude={res.id}
                  onUpdateSolicitude={handleUpdateStateSolicitude}
                  data={data}
                ></NewDairySolicitudes>
              </section>
            </div>
          ) : (
            <form
              className={style.formulario}
              action=""
              onSubmit={handleOnSubmit}
            >
              <div className={style.displayGrid}>
                <div className={style.fechas}>
                  <label className={style.label}>Fecha inicio </label>
                  <input
                    value={fechaInicio.campo}
                    type="date"
                    name="fechaInicio"
                    onKeyUp={validacionFechaInicio}
                    onBlur={validacionFechaInicio}
                    className={cambioDeClaseFechaInicio(fechaInicio)}
                    id="fechaInicio"
                    onChange={(e) =>
                      setFechaInicio({ ...fechaInicio, campo: e.target.value })
                    }
                  />
                </div>
                <div className={style.fechas}>
                  <label className={style.label}>Fecha fin </label>
                  <input
                    value={fechaFin.campo}
                    type="date"
                    name="fechaFin"
                    className={cambioDeClaseFechaFin(fechaFin)}
                    onKeyUp={validacionFechaFin}
                    onBlur={validacionFechaFin}
                    onChange={(e) =>
                      setFechaFin({ ...fechaFin, campo: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className={style.displayGridUbicaciones}>
                <label>Cliente</label>
                <select
                  value={cliente.campo}
                  className={cambioDeClaseCliente(cliente)}
                  name="clientes"
                  onChange={handleOnChangeSectionClient}
                  onBlur={validacionDeCliente}
                >
                  <option value={{ campo: "", valido: null }}>
                    Seleccionar
                  </option>
                  {dataClientes?.map((cliente) => (
                    <option key={cliente.id} value={cliente.name}>
                      {cliente.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>
                  <strong>Origen</strong>
                </label>
                <div className={style.displayGridUbicaciones}>
                  <label>Departamento</label>
                  <select
                    value={selectedInitialDepartment.campo}
                    className={cambioDeClaseDepartamentoInicial(
                      selectedInitialDepartment
                    )}
                    id="departamentosOrigen"
                    onChange={handleInitialDepartmentChange}
                    onBlur={validacionDepartamentoInicial}
                    onSelect={validacionDepartamentoInicial}
                  >
                    <option value="">Seleccionar</option>
                    {data2.departamentos.map((depto) => (
                      <option
                        key={depto.departamento}
                        value={depto.departamento}
                      >
                        {depto.departamento}
                      </option>
                    ))}
                  </select>
                  <label>Municipio</label>
                  <select
                    value={selectedInitialTown.campo}
                    className="section"
                    id="municipiosOrigen"
                    onChange={(event) =>
                      setSelectedInitialTown({
                        ...selectedInitialTown,
                        campo: event.target.value,
                      })
                    }
                  >
                    <option value="">Seleccionar</option>
                    {initialTowns.map((municipio) => (
                      <option key={municipio} value={municipio}>
                        {municipio}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label>
                  <strong>Destino</strong>
                </label>
                <div className={style.displayGridUbicaciones}>
                  <label>Departamento</label>
                  <select
                    value={selectedFinalDepartment.campo}
                    className="section"
                    id="departamentosDestino"
                    onChange={handleFinalDepartmentChange}
                  >
                    <option value="">Seleccionar</option>
                    {data2.departamentos.map((depto) => (
                      <option
                        key={depto.departamento}
                        value={depto.departamento}
                      >
                        {depto.departamento}
                      </option>
                    ))}
                  </select>
                  <label>Municipio</label>
                  <select
                    value={selectedFinalTown.campo}
                    id="municipiosDestino"
                    className="section"
                    onChange={(event) =>
                      setSelectedFinalTown({
                        ...selectedFinalTown,
                        campo: event.target.value,
                      })
                    }
                  >
                    <option value="">Seleccionar</option>
                    {finalTowns.map((municipio) => (
                      <option key={municipio} value={municipio}>
                        {municipio}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={style.files}>
                  <label>Imagen o pdf</label>
                  {/*Falta agregar el metodo para obtener los archivos y subirlos a firebase*/}
                  <input
                    type="file"
                    id="file"
                    placeholder="Agregar un archivo"
                    onChange={(event) => {
                      setFileUpload(event.target.files[0]);
                    }}
                  />
                  <h3>Uploaded: {progress}%</h3>
                  <button type="button" className="btn" onClick={uploadFile}>
                    Upload
                  </button>
                </div>
                <div className={style.files}>
                  <label>Ruta del día</label>
                  <input
                    value={rutaDelDia}
                    type="text"
                    className="input"
                    name="rutaDelDia"
                    onChange={handleChangeInputs}
                  />
                </div>
                <div className={style.files}>
                  <label>Itinerario de comisión</label>
                  <input
                    value={completeRoute.campo}
                    type="text"
                    className="input"
                    name="itinerarioDeComision"
                    onChange={handleChangeInputs}
                  />
                </div>
              </div>
              <label>Tipo de desplazamiento</label>
              <select
                id="tipoDesplazamiento"
                className={cambioDeClaseDesplazamiento(tipoDeDesplazamiento)}
                name="tipoDesplazamiento"
                onBlur={validacionTipoDeDesplazamiento}
                onChange={handleOnChangeScrollType}
                value={tipoDeDesplazamiento.campo}
              >
                <option value="">Seleccionar</option>
                <option value={"Aereo"}>Aéreo</option>
                <option value={"Aereo-fluvial-viceversa"}>
                  Aéreo-fluvial-viceversa
                </option>
                <option value={"Aereo-terrestre-viceversa"}>
                  Aéreo-terrestre-viceversa
                </option>
                <option value={"Aereo-terrestre-fluvial-viceversa"}>
                  Aéreo-terrestre-fluvial-viceversa
                </option>
                <option value={"Fluvial"}>Fluvial</option>
                <option value={"Terrestre"}>Terrestre</option>
              </select>
              <div className={style.contenedor}>
                <label className={style.label}>Funcionarios</label>
                <select
                  id="functionaries2"
                  className="section"
                  name="functionaries2"
                  onChange={agregarOpcion}
                  value={funcionarios}
                >
                  <option value="">Seleccionar</option>
                  {funcionariosFiltrados?.map((funcionario) => (
                    <option
                      key={funcionario.id}
                      value={funcionario.name.toUpperCase()}
                      checked={selectedOptions.includes(funcionario.name)}
                      onChange={(e) => handleOptionChange(e, funcionario.name)}
                    >
                      {funcionario.name}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn"
                  onClick={() => setFuncionarios([])}
                >
                  Borrar funcionarios
                </button>
                <div className={style.borderContainer}>
                  <ul>
                    {funcionarios.map((opcion) => (
                      <li key={opcion + uuidv4()}>
                        <span onClick={() => eliminarFuncionario(opcion)}>
                          {opcion}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <ToastContainer></ToastContainer>
              <div>
                <div className={style.contenedor}>
                  <label>¿Desea realizar la evaluación de riesgos?</label>
                  <ToggleButton
                    mensajeJSON={mensajeJSON}
                    setRiskScore={puntuacionDeRiesgo}
                    color={getClaseCSS(mensajeJSON)}
                    setOpenModal={setOpenModal}
                    riesgoInput={(riesgo) => setRiesgo(riesgo)}
                  ></ToggleButton>
                </div>
                {openModal && (
                  <EvaluacionRiesgos
                    closeModal={setOpenModal}
                    setMensajeJSON={setMensajeJSON}
                    setRiskScore={setPuntuacionDeRiesgo}
                    riskEvaluation={setEvaluacionDeRiesgos}
                  ></EvaluacionRiesgos>
                )}
              </div>
              <label>Observaciones</label>
              <input
                autoComplete="off"
                className={cambioDeClaseObservaciones(observaciones)}
                type="text"
                name="observaciones"
                onBlur={validacionObservaciones}
                onChange={handleChangeInputs}
                value={observaciones.campo}
              />
              <input
                className={style.submitBtn}
                type="submit"
                value="Crear nuevo gerenciamiento"
                disabled={!botonHabilitado}
              />
            </form>
          )}
        </div>
      </Clock>
    </DashboardWrapper>
  );
}
