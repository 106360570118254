import { useEffect, useState } from "react"
import style from "./cssModules/modal.module.css"
import InfoButton from "./infoEvaluacionRiesgos"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"

//Perfil de personas
const PERFIL_BAJO = 1
const PERFIL_MEDIO_BAJO = 2
const PERFIL_MEDIO = 3
const PERFIL_MEDIO_ALTO = 4
const PERFIL_ALTO = 5

//Estado de alerta del departamento
const RIESGO_BAJO_DEPARTAMENTO = 1
const RIESGO_MEDIO_DEPARTAMENTO = 3
const RIESGO_ALTO_DEPARTAMENTO = 4
const RIESGO_CRITICO_DEPARTAMENTO = 5

//Estado de alerta del municipio
const RIESGO_BAJO_MUNICIPIO = 1
const RIESGO_MEDIO_MUNICIPIO = 3
const RIESGO_ALTO_MUNICIPIO = 4
const RIESGO_CRITICO_MUNICIPIO = 5

//Incidentes de orden publico
const INCIDENTES_1_SEMESTRE = 1
const INCIDENTES_2_MESES = 2
const INCIDENTES_1_MES = 3
const INCIDENTES_15_DIAS = 4
const INCIDENTES_ULTIMA_SEMANA = 5

//Organizaciones delictivas
const NO_INCIDENCIA = 1
const PRESENCIA_EN_EL_AREA = 3
const ACCIONES_EN_EL_AREA = 5

//Protesta social
const PROTESTA_1_SEMESTRE = 1
const PROTESTA_2_MESES = 2
const PROTESTA_1_MES = 3
const PROTESTA_15_DIAS = 4
const PROTESTA_ULTIMA_SEMANA = 5

//Comunicaciones
const SENAL_CELULAR = 1
const SENAL_INTERMITENTE = 3
const SIN_SENAL = 4

export default function EvaluacionRiesgos({ closeModal, setMensajeJSON, setRiskScore, riskEvaluation }) {
    //state
    const [perfilPersonas, setPerfilPersonas] = useState(0)
    const [estadoAlertaDepartamento, setEstadoAlertaDepartamento] = useState(0)
    const [estadoAlertaMunicipio, setEstadoAlertaMunicipio] = useState(0)
    const [incidentesOrdenPublico, setIncidentesOrdenPublico] = useState(0)
    const [organizacionesDelictivas, setOrganizacionesDelictivas] = useState(0)
    const [protestaSocial, setProtestaSocial] = useState(0)
    const [comunicaciones, setComunicaciones] = useState(0)
    const [riskData, setRiskData] = useState({ riesgo: 0, mensaje: '' })
    const [loader, setLoader] = useState(false)

    const calcRisk = (event) => {
        event.preventDefault()
        let risk = ((((perfilPersonas + estadoAlertaDepartamento + estadoAlertaMunicipio) * 0.5) / 3) + (((incidentesOrdenPublico + organizacionesDelictivas + protestaSocial) * 0.3) / 3) + ((comunicaciones) * 0.5 / 2))
        const getMensaje = (risk) => {
            let mensaje = ""
            if (risk >= 4.6) {
                return mensaje = 'Crítico'
            } else if (risk >= 3.5 && risk < 4.6) {
                return mensaje = 'Alto'
            } else if (risk >= 2.1 && risk < 3.5) {
                return mensaje = 'Medio'
            } else {
                return mensaje = 'Bajo'
            }
        }
        setRiskData({ riesgo: risk.toFixed(1), mensaje: '' })
        setRiskData((prevRiskData) => {
            const mensaje = getMensaje(risk)
            setMensajeJSON(mensaje)
            setRiskScore(riskData.riesgo)
            return { ...prevRiskData, mensaje }
        })

        const updatedRiskEvaluation = {
            perfilDePersonas: perfilPersonas,
            estadoAlertaDepartamento: estadoAlertaDepartamento,
            estadoAlertaMunicipio: estadoAlertaMunicipio,
            incidentesOrdenPublico: incidentesOrdenPublico,
            organizacionesDelictivas: organizacionesDelictivas,
            protestaSocial: protestaSocial,
            comunicacion: comunicaciones,
            total: risk.toFixed(1),
        };
        riskEvaluation(updatedRiskEvaluation);
    }

    const downloadPDF = () => {
        const capture = document.getElementById('toPDF');
        setLoader(true);
        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg'); // Cambiar el tipo de imagen a JPEG
            const doc = new jsPDF('p', 'mm', 'a4');
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const imageWidth = pageWidth * 0.8; // Ajustar el ancho de la imagen según tus necesidades
            const imageHeight = (imageWidth * canvas.height) / canvas.width; // Mantener la proporción de aspecto
            const x = (pageWidth - imageWidth) / 2; // Centrar horizontalmente la imagen
            const y = 15; // Centrar verticalmente la imagen
            console.log(y)
            doc.addImage(imgData, 'JPEG', x, y, imageWidth, imageHeight); // Ajustar los parámetros de posición y tamaño
            setLoader(false);
            doc.save('receipt.pdf');
        });
    };

    return (
        <div className={style.modalBackground}>
            <div className={style.modalContainer} id="toPDF">
                <div className={style.titleCloseBtn}>
                    <button onClick={() => { closeModal(false) }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </div>
                <div className={style.title}>
                    <h1>Evaluación de riesgos</h1>
                </div>
                <div className={style.subtitle}>
                    <h1>Información de riesgos</h1>
                </div>
                <div className={style.body}>
                    <ul>
                        <li>
                            <label>Perfil de las personas</label>
                            <select name="perfilPersona" id="perfilPersona" onChange={(event) => setPerfilPersonas(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={PERFIL_BAJO}>Bajo</option>
                                <option value={PERFIL_MEDIO_BAJO}>Bajo-medio</option>
                                <option value={PERFIL_MEDIO}>Medio</option>
                                <option value={PERFIL_MEDIO_ALTO}>Medio-alto</option>
                                <option value={PERFIL_ALTO}>Alto</option>
                            </select>
                        </li>
                        <li>
                            <label>Estado de alerta departamento</label>
                            <select name="alertaDepartamento" id="alertaDepartamento" onChange={(event) => setEstadoAlertaDepartamento(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={RIESGO_BAJO_DEPARTAMENTO}>Bajo</option>
                                <option value={RIESGO_MEDIO_DEPARTAMENTO}>Medio</option>
                                <option value={RIESGO_ALTO_DEPARTAMENTO}>Alto</option>
                                <option value={RIESGO_CRITICO_DEPARTAMENTO}>Crítico</option>
                            </select>
                        </li>
                        <li>
                            <label>Estado de alerta municipio</label>
                            <select name="alertaMunicipio" id="alertaMunicipio" onChange={(event) => setEstadoAlertaMunicipio(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={RIESGO_BAJO_MUNICIPIO}>Bajo</option>
                                <option value={RIESGO_MEDIO_MUNICIPIO}>Medio</option>
                                <option value={RIESGO_ALTO_MUNICIPIO}>Alto</option>
                                <option value={RIESGO_CRITICO_MUNICIPIO}>Crítico</option>
                            </select>
                        </li>
                    </ul>
                </div>
                <div className={style.subtitle}>
                    <h1>Amenazas</h1>
                </div>
                <div className={style.body}>
                    <ul>
                        <li>
                            <label>Incidentes de orden público</label>
                            <select name="incidentes" id="incidentes" onChange={(event) => setIncidentesOrdenPublico(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={INCIDENTES_1_SEMESTRE}>Incidentes - 1 semestre</option>
                                <option value={INCIDENTES_2_MESES}>Incidentes - 2 meses</option>
                                <option value={INCIDENTES_1_MES}>Incidentes - 1 meses</option>
                                <option value={INCIDENTES_15_DIAS}>Incidentes - 15 días</option>
                                <option value={INCIDENTES_ULTIMA_SEMANA}>Incidentes - última semana</option>
                            </select>
                        </li>
                        <li>
                            <label>Organizaciones delictivas</label>
                            <select name="organizacionesDelictivas" id="organizacionesDelictivas" onChange={(event) => setOrganizacionesDelictivas(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={NO_INCIDENCIA}>No incidencia</option>
                                <option value={PRESENCIA_EN_EL_AREA}>Presencia en el área</option>
                                <option value={ACCIONES_EN_EL_AREA}>Acciones en el área</option>
                            </select>
                        </li>
                        <li>
                            <label>Protesta social</label>
                            <select name="protestaSocial" id="protestaSocial" onChange={(event) => setProtestaSocial(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={PROTESTA_1_SEMESTRE}>Incidentes - 1 semestre</option>
                                <option value={PROTESTA_2_MESES}>Incidentes - 2 meses</option>
                                <option value={PROTESTA_1_MES}>Incidentes - 1 meses</option>
                                <option value={PROTESTA_15_DIAS}>Incidentes - 15 días</option>
                                <option value={PROTESTA_ULTIMA_SEMANA}>Incidentes - última semana</option>
                            </select>
                        </li>
                    </ul>
                </div>
                <div className={style.subtitle}>
                    <h1>Comunicaciones</h1>
                </div>
                <div className={style.body}>
                    <ul>
                        <li>
                            <label>Comunicaciones</label>
                            <select name="comunicaciones" id="comunicaciones" onChange={(event) => setComunicaciones(parseInt(event.target.value))}>
                                <option value=""></option>
                                <option value={SENAL_CELULAR}>Señal celular</option>
                                <option value={SENAL_INTERMITENTE}>Señal intermitente</option>
                                <option value={SIN_SENAL}>No hay señal</option>
                            </select>
                        </li>
                    </ul>
                </div>
                <div className={style.footer}>
                    <div>
                        <InfoButton></InfoButton>
                    </div>
                    <div className={style.mensajeContainer}>
                        <p className={style.mensajeRiesgo}>El riesgo es: {riskData.riesgo} <b>{riskData.mensaje}</b></p>
                    </div>
                    <div className={style.buttonsContainer}>
                        <button className={style.submitBtn} onClick={calcRisk} type="button">Submit</button>
                        <button onClick={() => { closeModal(false) }} className={style.cancelBtn}>Cancel</button>
                        {/* <button type="button" className={style.downloadBtn} onClick={downloadPDF} disabled={!(loader === false)}>
                            <span className="material-icons md-light">file_download</span>
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    )
}