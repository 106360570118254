import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { obtenerDatosDeSeguimiento } from "../firebase/firebase";
import { useAuthContext } from "../contexts/authContext";
import DashboardWrapper from "../components/dashboardWrapper";
import { Link } from "react-router-dom";

const icon = L.icon({
  iconUrl: "./marcador-de-posicion.png",
  iconSize: [38, 38],
});

const defaultCenter = [4.679309297317709, -74.04776509871293];

function MapsTrackingWithLeaflet({ isClient }) {
  const { user } = useAuthContext();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    obtenerDatosDeSeguimiento(!!user && user.rol, user.avaiableFor)
      .then((res) => setLocations(res))
      .then(() => console.log(locations))
      .catch((error) => console.error(error));
  }, [user]);

  return (
    <DashboardWrapper>
      <MapContainer
        center={defaultCenter}
        zoom={11}
        scrollWheelZoom={false}
        style={{
          width: "100vw",
          height: "86vh",
          overflow: "hidden",
          left: 0,
          position: "absolute",
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location) => (
          <Marker
            key={location.key}
            position={[location.lat, location.lng]}
            icon={icon}
          >
            <Popup>
              <Link
                to={
                  isClient
                    ? `/client/search-client-page/${location.idSolicitude}`
                    : `/solicitude/search-solicitude/${location.idSolicitude}`
                }
              >
                {location.idSolicitude}
              </Link>
              <br />
              {location.client}
              <br />
              {location.name}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </DashboardWrapper>
  );
}

export default MapsTrackingWithLeaflet;
