import { createContext, useContext, useState, useEffect } from "react";
import {
  collection,
  collectionGroup,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebase";

export const DataContext = createContext();

export function DataContextProvider(props) {
  const [alarmTime, setAlarmTime] = useState([1, 2]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  /* useEffect(() => {
    async function fetchData() {
      try {
        let diariesArray = [];
        let alertsArray = [];
        const currentDate = new Date();

        // Realiza una consulta para obtener solicitudes en estado "Nuevo" o "En proceso"
        const requestsQuerySnapshot = await getDocs(
          query(
            collection(db, "solicitudes"),
            where("state", "in", ["Nuevo", "En proceso"])
          )
        );

        // Itera a través de las solicitudes filtradas
        for (const requestDoc of requestsQuerySnapshot.docs) {
          // Obtén la subcolección "diary" de cada solicitud
          const diariesQuerySnapshot = await getDocs(
            collection(db, `solicitudes/${requestDoc.id}/diary`)
          );

          // Itera a través de los diarios de la solicitud actual
          for (const diaryDoc of diariesQuerySnapshot.docs) {
            const data = diaryDoc.data();
            const notificationDate = new Date(
              data.notificationDateAndHour.toDate()
            );

            // Comprueba la fecha de notificación
            if (
              notificationDate.getDate() === currentDate.getDate() &&
              notificationDate.getMonth() === currentDate.getMonth() &&
              notificationDate.getFullYear() === currentDate.getFullYear()
            ) {
              diariesArray.push({ id: diaryDoc.id, ...data });
              alertsArray.push({
                id: data.idSolicitude,
                alert: notificationDate,
                openedCount: 0,
              });
            }
          }
        }

        setAlarmTime(alertsArray);
        setData(diariesArray);
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar los datos de la API:", error);
        setLoading(false);
      }
    }

    fetchData();
    const intervalId = setInterval(fetchData, 300000);
    return () => clearInterval(intervalId);
  }, []); */

  useEffect(() => {
    async function fetchData() {
      try {
        let diariesArray = [];
        let alertsArray = [];
        const currentDate = new Date();

        // Realiza una consulta para obtener solicitudes en estado "Nuevo" o "En proceso"
        const requestsQuerySnapshot = await getDocs(
          query(
            collection(db, "solicitudes"),
            where("state", "in", ["Nuevo", "En proceso"])
          )
        );

        // Itera a través de las solicitudes filtradas
        for (const requestDoc of requestsQuerySnapshot.docs) {
          // Obtén el último diario de la solicitud actual ordenado por fecha de notificación
          const diariesQuerySnapshot = await getDocs(
            query(
              collection(db, `solicitudes/${requestDoc.id}/diary`),
              orderBy("notificationDateAndHour", "desc"),
              limit(1)
            )
          );

          if (diariesQuerySnapshot.docs.length > 0) {
            const data = diariesQuerySnapshot.docs[0].data();
            const notificationDate = new Date(
              data.notificationDateAndHour.toDate()
            );

            // Comprueba la fecha de notificación
            if (
              notificationDate.getDate() === currentDate.getDate() &&
              notificationDate.getMonth() === currentDate.getMonth() &&
              notificationDate.getFullYear() === currentDate.getFullYear()
            ) {
              diariesArray.push({
                id: diariesQuerySnapshot.docs[0].id,
                ...data,
              });
              alertsArray.push({
                id: data.idSolicitude,
                alert: notificationDate,
                openedCount: 0,
              });
            }
          }
        }

        setAlarmTime(alertsArray);
        setData(diariesArray);
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar los datos de la API:", error);
        setLoading(false);
      }
    }

    fetchData();
    const intervalId = setInterval(fetchData, 500000);
    return () => clearInterval(intervalId);
  }, []);

  // ...

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        alarmTime,
        setAlarmTime,
        loading,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

export function useDataContext() {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContent must be within a DataContextProvider");
  }
  return context;
}
