import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import { updateCliente, deleteCliente, getClient } from "../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Client from "../components/client";

export default function ClientDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cliente, setCliente] = useState([]);
  const [botonHabilitado, setBotonHabilitado] = useState(false);

  const notifySuccessEdit = () => {
    toast.success("Editado satisfactoriamente", {
      theme: "colored",
    });
  };

  useEffect(() => {
    const verificarCamposLlenos = () => {
      if (nombre !== "" && telefono !== "") {
        setBotonHabilitado(true);
      } else {
        setBotonHabilitado(false);
      }
    };
    verificarCamposLlenos();
  }, [nombre, telefono]);

  useEffect(() => {
    getClient(params.id)
      .then((cliente) => setCliente(cliente))
      .catch((error) => console.error(error));
  }, [params.id]);

  async function handleDeleteCliente(id) {
    await deleteCliente(id)
      .then(notifySuccessEdit())
      .then(navigate("/admin/client/search-client"))
      .catch((error) => console.error(error));
  }

  async function handleUpdateCliente(id, field, newValue) {
    const editCliente = cliente;
    editCliente[field] = newValue;
    await updateCliente(id, editCliente)
      .then(notifySuccessEdit())
      .then(setCliente({ ...editCliente }))
      .catch((error) => console.error(error));
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <h1>Cliente: {cliente.name}</h1>
          <button type="button">
            <Link to={"/admin/client/search-client"}>
              <span className="material-icons">manage_search</span>
            </Link>
          </button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <div>
            <Client
              key={cliente.id}
              id={cliente.id}
              name={cliente.name}
              phone={cliente.phone}
              timeStamp={cliente.timeStamp}
              onUpdate={handleUpdateCliente}
              onDelete={handleDeleteCliente}
            ></Client>
          </div>
        </div>
      </Clock>
    </DashboardWrapper>
  );
}

/*client is not iterable
TypeError: client is not iterable
    at handleUserLoggedIn (http://localhost:3000/main.627c3c3a271353373382.hot-update.js:95:20)
    */
