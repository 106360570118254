import { PDFViewer, StyleSheet } from '@react-pdf/renderer'
import React, { useState } from 'react'
import DocumentFull from './pdfFiles/finalReport/DocumentFull'

const styles = StyleSheet.create({
    viewer: {
        width: '99%',
        height: '60vh'
    }
});

function PdfViewer({ data, dataDiary }) {
    const [guardarData, setGuardarData] = useState(data)
    const [guardarDataDiary, setGuardarDataDiary] = useState(dataDiary)

    return (
        <div>
            <PDFViewer style={styles.viewer}>
                {data && dataDiary ? (
                    <DocumentFull
                        data={guardarData}
                        dataDiary={guardarDataDiary}
                    />
                ) : (
                    <p>Loading...</p>
                )}
            </PDFViewer>
        </div>
    )
}

export default React.memo(PdfViewer);