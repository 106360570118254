import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
const styles = StyleSheet.create({
  // filaDeCeldasTitulo: {
  //   width: '100%',
  //   flexDirection: 'row',
  //   borderStyle: 'solid',
  //   borderBottom: 2, borderRight: 2,
  //   borderLeft: 2,
  // },
  // filaDeCeldas: {
  //   width: '100%',
  //   maxHeight: '320px',
  //   flexDirection: 'row',
  //   borderStyle: 'solid',
  //   borderBottom: 2, borderRight: 2,
  //   borderLeft: 2,
  // },
  // tableBodyPag4: {
  //   width: '87%',
  //   minHeight: '128px',
  //   marginRight: 36,
  //   flexDirection: 'column',
  //   borderStyle: 'solid',
  //   borderTop: 2
  // },
  // contenedorTitulo: {
  //   height: '29px',
  //   width: '100%',
  //   borderStyle: 'solid',
  //   borderBottom: 2,
  //   borderRight: 2,
  //   borderLeft: 2,
  //   backgroundColor: '#E7E6E6',
  //   justifyContent: 'center',
  //   alignContent: 'center',
  //   alignItems: 'center'
  // },
  // tipoCelda4: {
  //   width: '30%',
  //   borderStyle: 'solid',
  //   justifyContent: 'center',
  //   alignContent: 'center',
  //   alignItems: 'center',
  //   textAlign: 'center',
  //   padding: '5px'
  // },
  // tipoCelda5: {
  //   width: '22%',
  //   borderStyle: 'solid',
  //   justifyContent: 'center',
  //   alignContent: 'center',
  //   alignItems: 'center',
  //   textAlign: 'center',
  //   padding: '5px'
  // },
  // tipoCelda6: {
  //   width: '50%',
  //   borderStyle: 'solid',
  //   justifyContent: 'center',
  //   alignContent: 'center',
  //   alignItems: 'center',
  //   textAlign: 'center',
  //   padding: '5px'
  // },
  // fontTitulo: {
  //   fontSize: 14,
  //   textAlign: 'center',
  //   fontFamily: 'Helvetica-Bold',
  //   letterSpacing: 2
  // },
  // fontSubTitulo: {
  //   fontSize: 10,
  //   textAlign: 'center',
  //   fontFamily: 'Helvetica-Bold',
  //   letterSpacing: 1
  // },
  // fontCampoCelda: {
  //   fontSize: 10,
  //   textAlign: 'center',
  //   fontFamily: 'Helvetica',
  //   letterSpacing: 1
  // },
  // fontCampoCelda1: {
  //   fontSize: 10,
  //   textAlign: 'justify',
  //   fontFamily: 'Helvetica',
  //   letterSpacing: 1,
  //   padding: '4px'
  // }
  //PAGINA 3 DEL DOCUMENTO
  filaDeCeldasTitulo: {
    width: "100%",
    flexDirection: "row",
    borderStyle: "solid",
    borderBottom: 2,
    borderRight: 2,
    borderLeft: 2,
  },
  filaDeCeldas: {
    width: "100%",
    maxHeight: "150px",
    flexDirection: "row",
    borderStyle: "solid",
    borderBottom: 2,
    borderRight: 2,
    borderLeft: 2,
  },
  tableBodyPag4: {
    width: "87%",
    minHeight: "128px",
    marginRight: 36,
    flexDirection: "column",
    borderStyle: "solid",
    borderTop: 2,
  },
  contenedorTitulo: {
    height: "29px",
    width: "100%",
    borderStyle: "solid",
    borderBottom: 2,
    borderRight: 2,
    borderLeft: 2,
    backgroundColor: "#E7E6E6",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  tipoCelda4: {
    width: "30%",
    borderStyle: "solid",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "5px",
  },
  tipoCelda5: {
    width: "22%",
    borderStyle: "solid",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "5px",
  },
  tipoCelda6: {
    width: "50%",
    borderStyle: "solid",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "5px",
  },
  fontTitulo: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    letterSpacing: 2,
  },
  fontSubTitulo: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    letterSpacing: 1,
  },
  fontCampoCelda: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica",
    letterSpacing: 1,
  },
  fontCampoCelda1: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Helvetica",
    letterSpacing: 1,
    padding: "4px",
  },
  //PIE DE PAGINA DEL DOCUMENTO
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: -10,
    left: 0,
    right: 0,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "grey",
  },
  table: {
    bottom: "-35px",
    width: "87%",
    height: 30,
    flexDirection: "row",
  },
});

const BodyPag4 = ({ data, dataDiary }) => {
  const [dataDiaryRecibida, setDataDiaryRecibida] = useState(dataDiary);
  const maxPuntosControl = 6; // Cambia este valor al número deseado de puntos de control antes del break

  function compareObj(a, b) {
    var aKeys = Object.keys(a).sort();
    var bKeys = Object.keys(b).sort();
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    if (aKeys.join("") !== bKeys.join("")) {
      return false;
    }
    for (var i = 0; i < aKeys.length; i++) {
      if (a[aKeys[i]] !== b[bKeys[i]]) {
        return false;
      }
    }
    return true;
  }

  var obj1 = { a: 1, b: 2, c: true };
  var obj2 = { c: true, b: 2, a: 1 };
  var obj3 = { c: false, b: 2, a: 1 };

  function comparadorObjetosIguales(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === "object" && typeof val2 === "object") {
        // Recursivamente comparar objetos anidados
        if (!comparadorObjetosIguales(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        // Comparar valores directos
        return false;
      }
    }

    return true;
  }

  const renderPuntosDeControl = (puntosDeControl) => {
    console.log(compareObj(obj2, obj3));
    return puntosDeControl.map((dataDiary, index) => (
      <View style={styles.filaDeCeldas} key={index}>
        <View style={[styles.tipoCelda4]}>
          <Text style={styles.fontCampoCelda}>
            {comparadorObjetosIguales(
              {
                name: "",
                geometry: { location: { lat: 0, lng: 0 } },
              },
              dataDiary.location
            )
              ? ""
              : dataDiary.location.name}
          </Text>
        </View>
        <View
          style={[
            styles.tipoCelda5,
            { borderStyle: "solid" },
            { borderLeft: 1 },
          ]}
        >
          <Text style={styles.fontCampoCelda}>
            {dataDiary.timeStamp
              .toDate()
              .toLocaleDateString()
              .split("/")
              .reverse()
              .join("-")}
            {"\n"}
            {dataDiary.timeStamp.toDate().toLocaleTimeString()}
          </Text>
        </View>
        <View
          style={[
            styles.tipoCelda6,
            { borderStyle: "solid" },
            { borderLeft: 1 },
          ]}
        >
          <Text style={styles.fontCampoCelda1}>{dataDiary.comments}</Text>
        </View>
      </View>
    ));
  };

  const renderPuntosDeControlConBreak = () => {
    const contenedores = [];
    let puntosControlActuales = [];
    const dataOrdenada = [...dataDiaryRecibida].sort(
      (a, b) => b.timeStamp.toDate() - a.timeStamp.toDate()
    );
    dataOrdenada.forEach((dataDiary, index) => {
      puntosControlActuales.push(dataDiary);

      if (
        puntosControlActuales.length === maxPuntosControl ||
        index === dataDiaryRecibida.length - 1
      ) {
        contenedores.push(
          <View key={contenedores.length} wrap={false}>
            {renderPuntosDeControl(puntosControlActuales)}
            {index === dataDiaryRecibida.length - 1 ? null : (
              <View break style={{ flex: 1, marginTop: 8 }} />
            )}
          </View>
        );

        puntosControlActuales = [];
      }
    });

    return contenedores;
  };

  return (
    <View style={styles.tableBodyPag4} break>
      <View style={styles.contenedorTitulo}>
        <Text style={styles.fontTitulo}>Detalles Punto de Control</Text>
      </View>
      {/* FILA 1 DE DETALLES DE PUNTO DE CONTROL */}
      <View style={styles.filaDeCeldasTitulo}>
        <View style={[styles.tipoCelda4]}>
          <Text style={styles.fontSubTitulo}>Lugar</Text>
        </View>
        <View style={[styles.tipoCelda5, { borderLeft: 1 }]}>
          <Text style={styles.fontSubTitulo}>Fecha y Hora del Contacto</Text>
        </View>
        <View
          style={[
            styles.tipoCelda6,
            { borderStyle: "solid" },
            { borderLeft: 1 },
          ]}
        >
          <Text style={styles.fontSubTitulo}>Comentarios</Text>
        </View>
      </View>
      {/* FILA 2 DE DETALLES DE PUNTO DE CONTROL */}
      {renderPuntosDeControlConBreak()}
    </View>
  );
};

export default BodyPag4;

const BodyPag5 = ({ data, dataDiary }) => {
  const [dataDiaryRecibida, setDataDiaryRecibida] = useState(dataDiary);
  const maxPuntosControl = 7; // Cambia este valor al número deseado de puntos de control antes del break

  return (
    <View style={styles.tableBodyPag4}>
      {/* CONTENEDOR TITULO DETALLES DE PUNTO DE CONTROL*/}
      <View style={styles.contenedorTitulo}>
        <Text style={styles.fontTitulo}>Detalles Punto de Control</Text>
      </View>
      {/* FILA 1 DE DETALLES DE PUNTO DE CONTROL */}
      <View style={styles.filaDeCeldasTitulo}>
        <View style={[styles.tipoCelda4]}>
          <Text style={styles.fontSubTitulo}>Lugar</Text>
        </View>
        <View style={[styles.tipoCelda5, { borderLeft: 1 }]}>
          <Text style={styles.fontSubTitulo}>Fecha y Hora del Contacto</Text>
        </View>
        <View
          style={[
            styles.tipoCelda6,
            { borderStyle: "solid" },
            { borderLeft: 1 },
          ]}
        >
          <Text style={styles.fontSubTitulo}>Comentarios</Text>
        </View>
      </View>
      {/* FILA 2 DE DETALLES DE PUNTO DE CONTROL */}
      {[...dataDiaryRecibida]
        .sort((a, b) => b.timeStamp.toDate() - a.timeStamp.toDate())
        .map((dataDiary, index) => (
          <View style={styles.filaDeCeldas} key={index}>
            {" "}
            {/* Añade la clave única */}
            <View style={[styles.tipoCelda4]}>
              <Text style={styles.fontCampoCelda}>
                {dataDiary.location.name}
              </Text>
            </View>
            <View
              style={[
                styles.tipoCelda5,
                { borderStyle: "solid" },
                { borderLeft: 1 },
              ]}
            >
              <Text style={styles.fontCampoCelda}>
                {dataDiary.timeStamp
                  .toDate()
                  .toLocaleDateString()
                  .split("/")
                  .reverse()
                  .join("-")}
                {"\n"}
                {dataDiary.timeStamp.toDate().toLocaleTimeString()}
              </Text>
            </View>
            <View
              style={[
                styles.tipoCelda6,
                { borderStyle: "solid" },
                { borderLeft: 1 },
              ]}
            >
              <Text style={styles.fontCampoCelda1}>{dataDiary.comments}</Text>
            </View>
            {index === maxPuntosControl - 1 &&
              index < dataDiaryRecibida.length - 1 && (
                // Agrega el break después del último punto de control deseado
                <View break style={{ flex: 1, marginTop: 8 }} />
              )}
          </View>
        ))}
    </View>
  );
};

/* const BodyPag4 = ({ data, dataDiary }) => {
    const [dataRecibida, setDataRecibida] = useState(data)
    const [dataDiaryRecibida, setDataDiaryRecibida] = useState(dataDiary)

    return (
        <View style={styles.tableBodyPag4} wrap={false} break>
            // CONTENEDOR TITULO DETALLES DE PUNTO DE CONTROL
            <View style={styles.contenedorTitulo}>
                <Text style={styles.fontTitulo}>Detalles Punto de Control</Text>
            </View>
            // FILA 1 DE DETALLES DE PUNTO DE CONTROL 
            <View style={styles.filaDeCeldas}>
                <View style={[styles.tipoCelda4]}>
                    <Text style={styles.fontSubTitulo}>Lugar</Text>
                </View>
                <View style={[styles.tipoCelda5, { borderLeft: 1 }]}>
                    <Text style={styles.fontSubTitulo}>Fecha y Hora del Contacto</Text>
                </View>
                <View style={[styles.tipoCelda6, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                    <Text style={styles.fontSubTitulo}>Comentarios</Text>
                </View>
            </View>
            // FILA 2 DE DETALLES DE PUNTO DE CONTROL 
            {
                dataDiaryRecibida.map((dataDiary) => (
                    <View style={styles.filaDeCeldas}wrap={false} >
                        <View style={[styles.tipoCelda4]}>
                            <Text style={styles.fontCampoCelda}>{dataDiary.location.name}</Text>
                        </View>
                        <View style={[styles.tipoCelda5, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                            <Text style={styles.fontCampoCelda}>{dataDiary.timeStamp.toDate().toLocaleDateString().split('/').reverse().join('-')}{"\n"}{dataDiary.timeStamp.toDate().toLocaleTimeString()}</Text>
                        </View>
                        <View style={[styles.tipoCelda6, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                            <Text style={styles.fontCampoCelda1}>{dataDiary.comments}</Text>
                        </View>
                    </View>
                ))
            }
        </View>
    )
}
export default BodyPag4  */

/* function comparadorObjetosIguales(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  } */
