import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

function AutocompleteLeaflet(props) {
  const { selectPosition, setSelectPosition } = props;
  const [searchText, setSearchText] = useState("");
  const [listItems, setListItems] = useState([]);

  function handleSearch(e) {
    e.preventDefault();
    const params = {
      q: searchText,
      format: "json",
      addressdetails: 1,
      polygon_geojson: 0,
    };
    const queryString = new URLSearchParams(params).toString();
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setListItems(JSON.parse(result));
      })
      .catch((err) => console.log("err: ", err));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, padding: "10px" }}>
          <input
            style={{ width: "100%" }}
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "center", padding: "0px 20px" }}
        >
          <button
            onClick={handleSearch}
            type="button"
            style={{
              alignSelf: "center",
              backgroundColor: "#f89820",
              width: "100px",
              height: "30px",
              border: "none",
              color: "white",
              borderRadius: "4px",
              fontSize: "14px",
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0 0 5px 0 #f89820 inset, 0 0 5px 4px #f89820",
              },
            }}
          >
            Buscar
          </button>
        </div>
      </div>
      <div>
        <List style={{ overflow: "auto", maxHeight: "250px" }}>
          {listItems.map((item) => {
            return (
              <div key={item?.osm_id}>
                <ListItem
                  button
                  onClick={() => {
                    setSelectPosition(item);
                  }}
                >
                  <ListItemIcon>
                    <img
                      src="./marcador-de-posicion.png"
                      alt="marcador"
                      style={{ width: 38, height: 38 }}
                    ></img>
                  </ListItemIcon>
                  <ListItemText primary={item?.display_name} />
                </ListItem>
                <Divider />
              </div>
            );
          })}
        </List>
      </div>
    </div>
  );
}

export default AutocompleteLeaflet;
