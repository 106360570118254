import React from "react";
import style from "../cssModules/excelExport.module.css";
import XLSX from "xlsx";

const ExcelExport = ({ sortedData }) => {
  const handleExport = async (e) => {
    e.preventDefault();
    const sortedDataByDate = [...sortedData].sort((a, b) =>
      a.client.localeCompare(b.client)
    );
    const reporteCliente = [
      [
        "Id Solicitud Viaje",
        "Cliente",
        "Funcionarios",
        "Fecha de Creacion",
        "Fecha de Inicio",
        "Fecha de Fin",
        "Departamento Origen",
        "Municipio Origen",
        "Departamento Destino",
        "Municipio Destino",
        "Tipo de Desplazamiento",
        "Itinerario de Comision",
        "Ruta del dia",
        "Fecha y hora prox. llamada",
        "Indicador de Puntos",
        "Evaluacion de Riesgo",
        "Estado",
        "Observaciones",
      ],
      ...sortedDataByDate.map((item) => {
        return [
          item.id,
          item.client,
          item.functionaries && item.functionaries.join(", "),
          item.timeStamp && item.timeStamp.toDate().toLocaleDateString(),
          item.initialDate && item.initialDate.toDate().toLocaleDateString(),
          item.finalDate && item.finalDate.toDate().toLocaleDateString(),
          item.initialDepartment,
          item.initialTown,
          item.finalDepartment,
          item.finalTown,
          item.scrollType,
          item.completeRoute,
          item.diaryRoute,
          item.latestNotificationDateAndHour &&
            new Date(item.latestNotificationDateAndHour).toLocaleString(),
          item.datosAdicionales.length, // Indicador de puntos
          item.risk,
          item.state,
          item.observations,
        ];
      }),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(reporteCliente);

    // Calculate column widths
    const columnWidths = reporteCliente[0].map((column, columnIndex) => {
      const columnData = reporteCliente.map((row) => row[columnIndex]);
      const columnMaxLength = Math.max(
        ...columnData.map((cellValue) => String(cellValue).length)
      );
      return columnMaxLength * 7; // Adjust the multiplier to control the cell width
    });

    // Set column widths in the worksheet
    worksheet["!cols"] = columnWidths.map((width) => ({ wpx: width }));

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte del cliente");
    XLSX.writeFile(workbook, "Reporte_Cliente.xlsx");
  };

  return (
    <div className={style.contenedorBtnExportar}>
      <button
        type="button"
        onClick={handleExport}
        className={style.btnExportar}
      >
        Exportar a Excel
      </button>
    </div>
  );
};

export default ExcelExport;
