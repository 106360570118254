import { View, Text, StyleSheet, Image } from "@react-pdf/renderer";
import imagenes from "../../../resources/images/imagenes";
import { useState } from "react";

const styles = StyleSheet.create({
  tableBodyPag1: {
    width: "87%",
    maxHeight: "75%",
    marginRight: 36,
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 2,
    borderBottom: 1,
    borderRight: 2,
    borderLeft: 2,
  },
  contenedorTitulo: {
    height: "29px",
    width: "100%",
    borderStyle: "solid",
    borderBottom: 2,
    backgroundColor: "#E7E6E6",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  filaDeCeldas: {
    width: "100%",
    height: "29px",
    flexDirection: "row",
    borderStyle: "solid",
    borderBottom: 2,
  },
  tipoCelda1: {
    width: "25%",
    height: "29px",
    borderStyle: "solid",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  fontTitulo: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    letterSpacing: 2,
  },
  fontSubTitulo: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
    letterSpacing: 1,
  },
  fontCampoCelda: {
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Helvetica",
    letterSpacing: 1,
  },
});
const BodyPag1 = ({ data, dataDiary }) => {
  const [dataRecibida, setDataRecibida] = useState(data);
  const [dataDiaryRecibida, setDataDiaryRecibida] = useState(dataDiary);

  const initialDate = () => {
    const date = new Date(dataRecibida.initialDate.toDate());
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const finalDate = () => {
    const date = new Date(dataRecibida.finalDate.toDate());
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const getMensajeAlertaMunicipioDepartamento = (riesgo) => {
    switch (riesgo) {
      case 1:
        return "Bajo";
      case 3:
        return "Medio";
      case 4:
        return "Alto";
      case 5:
        return "Crítico";
      default:
        break;
    }
  };

  const getPerfilPersona = (riesgo) => {
    switch (riesgo) {
      case 1:
        return "Bajo";
      case 2:
        return "Medio-Bajo";
      case 3:
        return "Medio";
      case 4:
        return "Medio-Alto";
      case 5:
        return "Alto";
      default:
        break;
    }
  };

  const getIncidentesYProtestas = (riesgo) => {
    switch (riesgo) {
      case 1:
        return "Hace 1 semestre";
      case 2:
        return "Hace 2 meses";
      case 3:
        return "Hace 1 mes";
      case 4:
        return "Hace 15 días";
      case 5:
        return "En la última semana";
      default:
        break;
    }
  };

  const getOrganizacionesDelictivas = (riesgo) => {
    switch (riesgo) {
      case 1:
        return "Sin incidencia";
      case 3:
        return "Presencia en el área";
      case 5:
        return "Acciones en el área";
      default:
        break;
    }
  };

  const getComunicaciones = (riesgo) => {
    switch (riesgo) {
      case 1:
        return "Señal celular";
      case 3:
        return "Señal intermitente";
      case 4:
        return "Sin señal";
      default:
        break;
    }
  };

  const getImageRisk = (estadoInputColor) => {
    if (estadoInputColor === "Crítico".toUpperCase()) {
      return imagenes.AlertaCriticaJPG;
    } else if (estadoInputColor === "Alto".toUpperCase()) {
      return imagenes.AlertaAltaJPG;
    } else if (estadoInputColor === "Medio".toUpperCase()) {
      return imagenes.AlertaMediaJPG;
    } else {
      return imagenes.AlertaBajaJPG;
    }
  };

  return (
    <View style={styles.tableBodyPag1}>
      {/* CONTENEDOR TITULO SOLICITUD DE VIAJE*/}
      <View>
        <View style={styles.contenedorTitulo}>
          <Text style={styles.fontTitulo}>Solicitud de Viaje</Text>
        </View>
        {/* FILA 1 DE SOLICITUD DE VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "175px" }]}>
            <Text style={styles.fontSubTitulo}>Numero de Solicitud</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { width: "180px" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{dataRecibida.id}</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Estado</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{dataRecibida.state}</Text>
          </View>
        </View>
        {/* FILA 2 DE SOLICITUD DE VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "28.57%" }]}>
            <Text style={styles.fontSubTitulo}>Cliente</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
              { width: "71.43%" },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{dataRecibida.client}</Text>
          </View>
        </View>
      </View>

      {/* CONTENEDOR TITULO INFORMACIÓN DEL VIAJE*/}
      <View>
        <View style={styles.contenedorTitulo}>
          <Text style={styles.fontTitulo}>Información del Viaje</Text>
        </View>
        {/* FILA 1 DE INFORMACION DEL VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "28.57%" }]}>
            <Text style={styles.fontSubTitulo}>Tipo de Desplazamiento</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
              { width: "71.43%" },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{dataRecibida.scrollType}</Text>
          </View>
        </View>
        {/* FILA 2 DE INFORMACION DEL VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "30%" }]}>
            <Text style={styles.fontSubTitulo}>Fecha de Inicio</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{initialDate()}</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Fecha de Fin</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{finalDate()}</Text>
          </View>
        </View>
        {/* FILA 3 DE INFORMACION DEL VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "30%" }]}>
            <Text style={styles.fontSubTitulo}>
              Departamento de {`\n`}Origen
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>
              {dataRecibida.initialDepartment}
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Municipio de {`\n`}Origen</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>
              {dataRecibida.initialTown}
            </Text>
          </View>
        </View>
        {/* FILA 4 DE INFORMACION DEL VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "30%" }]}>
            <Text style={styles.fontSubTitulo}>
              Departamento de {`\n`}Destino
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>
              {dataRecibida.finalDepartment}
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Municipio de {`\n`}Destino</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
            ]}
          >
            <Text style={styles.fontCampoCelda}>{dataRecibida.finalTown}</Text>
          </View>
        </View>
        {/* FILA 5 DE INFORMACION DEL VIAJE */}
        <View style={styles.filaDeCeldas}>
          <View style={[styles.tipoCelda1, { width: "28.57%" }]}>
            <Text style={styles.fontSubTitulo}>
              Itinerario de {`\n`}Comisión
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderLeft: 1 },
              { width: "71.43%" },
            ]}
          >
            <Text style={styles.fontCampoCelda}>
              {dataRecibida.completeRoute}
            </Text>
          </View>
        </View>
      </View>

      {/* CONTENEDOR TITULO EVALUACIÓN DE RIESGO*/}
      <View>
        <View style={styles.contenedorTitulo}>
          <Text style={styles.fontTitulo}>Evaluación de Riesgo</Text>
        </View>
        {/* FILA 1 DE EVALUACIÓN DE RIESGO */}
        <View style={[styles.filaDeCeldas, { height: "64px" }]}>
          <View
            style={[
              styles.tipoCelda1,
              { width: "28.7%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "64px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Información de Riesgos</Text>
          </View>
          <View
            style={[styles.tipoCelda1, { width: "71.3%" }, { height: "100%" }]}
          >
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "32px" },
              ]}
            >
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                ]}
              >
                <Text style={styles.fontSubTitulo}>
                  Perfil de las {`\n`} Personas
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontSubTitulo}>
                  Estado de Alerta en el Departamento
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontSubTitulo}>
                  Estado de Alerta en el Municipio
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "32px" },
                { borderBottom: 0 },
              ]}
            >
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getPerfilPersona(
                      dataRecibida.evaluacionDeRiesgos.perfilDePersonas
                    )) ||
                    "No aplica"}
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getMensajeAlertaMunicipioDepartamento(
                      dataRecibida.evaluacionDeRiesgos.estadoAlertaDepartamento
                    )) ||
                    "No aplica"}
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getMensajeAlertaMunicipioDepartamento(
                      dataRecibida.evaluacionDeRiesgos.estadoAlertaMunicipio
                    )) ||
                    "No aplica"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* FILA 2 DE EVALUACIÓN DE RIESGO */}
        <View style={[styles.filaDeCeldas, { height: "64px" }]}>
          <View
            style={[
              styles.tipoCelda1,
              { width: "28.7%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "64px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Amenazas</Text>
          </View>
          <View
            style={[styles.tipoCelda1, { width: "71.3%" }, { height: "100%" }]}
          >
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "32px" },
              ]}
            >
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                ]}
              >
                <Text style={styles.fontSubTitulo}>
                  Incidentes de Orden Publico
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontSubTitulo}>
                  Organizaciones Delictivas
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontSubTitulo}>Protesta Social</Text>
              </View>
            </View>
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "32px" },
                { borderBottom: 0 },
              ]}
            >
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getIncidentesYProtestas(
                      dataRecibida.evaluacionDeRiesgos.incidentesOrdenPublico
                    )) ||
                    "No aplica"}
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getOrganizacionesDelictivas(
                      dataRecibida.evaluacionDeRiesgos.organizacionesDelictivas
                    )) ||
                    "No aplica"}
                </Text>
              </View>
              <View
                style={[
                  styles.tipoCelda1,
                  { width: "33%" },
                  { height: "32px" },
                  { borderStyle: "solid" },
                  { borderLeft: 1 },
                ]}
              >
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getIncidentesYProtestas(
                      dataRecibida.evaluacionDeRiesgos.protestaSocial
                    )) ||
                    "No aplica"}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* FILA 3 DE EVALUACIÓN DE RIESGO */}
        <View style={[styles.filaDeCeldas, { height: "45px" }]}>
          <View
            style={[
              styles.tipoCelda1,
              { width: "52.4%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "43px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Comunicación</Text>
          </View>
          <View
            style={[styles.tipoCelda1, { width: "47.6%" }, { height: "100%" }]}
          >
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "25px" },
                { alignItems: "center" },
              ]}
            >
              <View style={[styles.tipoCelda1, { width: "100%" }]}>
                <Text style={styles.fontSubTitulo}>Comunicación</Text>
              </View>
            </View>
            <View
              style={[
                styles.filaDeCeldas,
                { with: "100%" },
                { height: "25px" },
                { borderBottom: 0 },
                { alignItems: "center" },
              ]}
            >
              <View style={[styles.tipoCelda1, { width: "100%" }]}>
                <Text style={styles.fontCampoCelda}>
                  {(dataRecibida.evaluacionDeRiesgos &&
                    getComunicaciones(
                      dataRecibida.evaluacionDeRiesgos.comunicacion
                    )) ||
                    "No aplica"}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {/* FILA 4 DE EVALUACIÓN DE RIESGO */}
        <View style={[styles.filaDeCeldas, { height: "80px" }]}>
          <View
            style={[
              styles.tipoCelda1,
              { width: "30%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "80px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>Nivel de Riesgo</Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { width: "20%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "80px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>
              {(dataRecibida.evaluacionDeRiesgos &&
                dataRecibida.evaluacionDeRiesgos.total) ||
                "No aplica"}
            </Text>
          </View>
          <View
            style={[
              styles.tipoCelda1,
              { width: "20%" },
              { borderStyle: "solid" },
              { borderRight: 1 },
              { height: "80px" },
            ]}
          >
            <Text style={styles.fontSubTitulo}>{dataRecibida.risk}</Text>
            <Image
              src={getImageRisk(dataRecibida.risk)}
              style={{ width: "50px" }}
            />
          </View>
          <View
            style={[styles.tipoCelda1, { width: "30%" }, { height: "80px" }]}
          >
            <Text style={styles.fontSubTitulo}>Nivel de Riesgo</Text>

            {/* FILA 3 DE EVALUACIÓN DE RIESGO */}
            <View style={[{ width: "100%" }, { height: "70px" }]}>
              <View
                style={[
                  styles.filaDeCeldas,
                  { height: "17.5px" },
                  { borderTop: 1 },
                  { borderBottom: "0" },
                ]}
              >
                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>1-2</Text>
                </View>

                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { borderLeft: "1" },
                    { height: "17.5px" },
                    { backgroundColor: "#00C900" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>Bajo</Text>
                </View>
              </View>
              <View
                style={[
                  styles.filaDeCeldas,
                  { height: "17.5px" },
                  { borderTop: 1 },
                  { borderBottom: "0" },
                ]}
              >
                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>2.1 - 3.5</Text>
                </View>

                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                    { borderLeft: "1" },
                    { backgroundColor: "#FFFF00" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>Medio</Text>
                </View>
              </View>
              <View
                style={[
                  styles.filaDeCeldas,
                  { height: "17.5px" },
                  { borderTop: 1 },
                  { borderBottom: "0" },
                ]}
              >
                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>3.5 - 4.5</Text>
                </View>

                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                    { borderLeft: "1" },
                    { backgroundColor: "#fba328" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>Alto</Text>
                </View>
              </View>
              <View
                style={[
                  styles.filaDeCeldas,
                  { height: "17.5px" },
                  { borderTop: 1 },
                  { borderBottom: "0" },
                ]}
              >
                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "17.5px" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>4.6 - 5</Text>
                </View>

                <View
                  style={[
                    styles.tipoCelda1,
                    { width: "50%" },
                    { borderStyle: "solid" },
                    { height: "16.7px" },
                    { borderLeft: "1" },
                    { borderBottom: "2" },
                    { backgroundColor: "#C90202" },
                  ]}
                >
                  <Text style={styles.fontSubTitulo}>Critico</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/* CONTENEDOR TITULO OBSERVACIONES*/}
      <View>
        <View style={styles.contenedorTitulo}>
          <Text style={styles.fontTitulo}>Observaciones</Text>
        </View>
        {/* FILA 1 DE OBSERVACIONES */}
        <View style={[styles.filaDeCeldas, { height: "50px" }]}>
          <View
            style={[
              styles.tipoCelda1,
              { borderStyle: "solid" },
              { borderBottom: 0 },
              { padding: 0 },
              { width: "100%" },
              { height: "40px" },
            ]}
          >
            <Text
              style={[
                styles.fontCampoCelda,
                { textAlign: "justify" },
                { paddingLeft: "8px" },
                { paddingRight: "8px" },
              ]}
            >
              {dataRecibida.observations}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
export default BodyPag1;
