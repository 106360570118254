import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imagenes from "../resources/images/imagenes";
import style from "./cssModules/dataTable.module.css";

const TablaDatos = ({ sortedData, currentPage, itemsPerPage }) => {
  const [time, setTime] = useState(new Date());

  const getImageRisk = (estadoInputColor) => {
    if (estadoInputColor === "Crítico".toUpperCase()) {
      return imagenes.img2;
    } else if (estadoInputColor === "Alto".toUpperCase()) {
      return imagenes.img3;
    } else if (estadoInputColor === "Medio".toUpperCase()) {
      return imagenes.img4;
    } else {
      return imagenes.img5;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const sortedDataByLatestDates = sortedData.sort((a, b) => {
    if (!a.hasDatosAdicionales && b.hasDatosAdicionales) {
      return -1; // a (sin datos adicionales) viene antes que b (con datos adicionales)
    }
    if (a.hasDatosAdicionales && !b.hasDatosAdicionales) {
      return 1; // b (sin datos adicionales) viene antes que a (con datos adicionales)
    }
    return a.latestNotificationDateAndHour - b.latestNotificationDateAndHour;
  });

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = sortedDataByLatestDates.slice(startIndex, endIndex);

  return (
    <table className={style.tableStyle}>
      <thead>
        <tr className={style.tableTitles}>
          <th></th>
          <th>Cliente</th>
          <th>Funcionarios</th>
          <th>Fecha y hora prox. llamada</th>
          <th>Ruta del día</th>
          <th>Nivel de riesgo</th>
          <th>Fecha finalizacion</th>
        </tr>
      </thead>
      <tbody>
        {paginatedData.map((list) => {
          const isBlinking =
            new Date(list.latestNotificationDateAndHour) <= time;
          const isNotFinalOrCancelled =
            list.state !== "Finalizado" && list.state !== "Cancelado";

          return (
            <tr
              key={list.id}
              className={
                isBlinking && isNotFinalOrCancelled ? style.rowAlarm : null
              }
            >
              <td>
                <Link to={list.id}>
                  <span className="material-icons md-light">description</span>
                </Link>
              </td>
              <td>{list.client}</td>
              <td>{list.functionaries[0]}</td>
              <td>
                {list.latestNotificationDateAndHour !== 0 &&
                  new Date(list.latestNotificationDateAndHour).toLocaleString()}
              </td>
              <td>{list.diaryRoute}</td>
              <td>
                <img
                  src={getImageRisk(list.risk)}
                  alt="Alerta"
                  width="20px"
                  height="20px"
                />
                {list.risk}
              </td>
              {/* Mostrar la variable notificationDateAndHour del objeto más reciente */}
              <td>
                {list.finalDate
                  .toDate()
                  .toLocaleDateString()
                  .split("/")
                  .reverse()
                  .join("/")}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TablaDatos;
