import React, { useEffect, useRef, useState } from "react";

function Diary({
  id,
  idSolicitude,
  comments,
  location,
  locationProvitional,
  notificationDate,
  notificationHour,
  notificationDateAndHour,
  timeStamp,
  onUpdateDiary,
}) {
  const [currentNotificationDateAndHour, setCurrentNotificationDateAndHour] =
    useState(
      notificationDateAndHour instanceof Date
        ? notificationDateAndHour
        : new Date()
    );
  const [editNotificationDateAndHour, setEditNotificationDateAndHour] =
    useState(false);
  const notificationDateAndHourRef = useRef(null);

  useEffect(() => {
    if (notificationDateAndHourRef.current) {
      notificationDateAndHourRef.current.focus();
    }
  }, [editNotificationDateAndHour]);

  function handleEditNotificationDateAndHour() {
    setEditNotificationDateAndHour(true);
  }

  function handleUpdateNotificationDateAndHour() {
    setEditNotificationDateAndHour(false);
    onUpdateDiary(
      id,
      idSolicitude,
      "notificationDateAndHour",
      currentNotificationDateAndHour
    );
  }

  return (
    <tr key={id}>
      <td>{location.name}</td>
      <td>{comments}</td>
      <td>
        <span>{timeStamp.toDate().toLocaleString()}</span>
      </td>
      <td>
        {editNotificationDateAndHour ? (
          <span>
            <input
              ref={notificationDateAndHourRef}
              type="datetime-local"
              value={currentNotificationDateAndHour.toISOString().slice(0, 16)}
              onChange={(e) =>
                setCurrentNotificationDateAndHour(new Date(e.target.value))
              }
            />
          </span>
        ) : (
          <span>{notificationDateAndHour.toDate().toLocaleString()}</span>
        )}
      </td>
    </tr>
  );

  /* {
        JSON.stringify(location) !== JSON.stringify({ name: '', geometry: { location: { lat: 0, lng: 0 } } }) ?
            (
                locationProvitional 
            )
            :
            ( location.name )
    } */

  /* const [currentNotificationDateAndHour, setCurrentNotificationDateAndHour] = useState(timeStamp instanceof Timestamp ? timeStamp.toDate() : new Date())
    const [editNotificationDateAndHour, setEditNotificationDateAndHour] = useState(false)
    const notificationDateAndHourRef = useRef(null)

    useEffect(() => {
        if (notificationDateAndHourRef.current) {
            notificationDateAndHourRef.current.focus()
        }
    }, [editNotificationDateAndHour])

    function handleEditNotificationDateAndHour(e) {
        const newTimestamp = new Date(e.target.value);
        const timestamp = Timestamp.fromDate(newTimestamp);
        setCurrentNotificationDateAndHour(newTimestamp);
        setEditNotificationDateAndHour(false)
        onUpdateDiary(id, idSolicitude, "notificationDateAndHour", currentNotificationDateAndHour)
    } */

  /* return (
        <tr key={id}>
            <td>{location.name}</td>
            <td>{comments}</td>
            <td>
                <span>{timeStamp.toDate().toLocaleString()}</span>
            </td>
            <td onClick={handleEditNotificationDateAndHour}>
                {
                    editNotificationDateAndHour ?
                        <span>
                            <input
                                ref={notificationDateAndHourRef}
                                type="datetime-local"
                                value={currentNotificationDateAndHour}
                                onChange={handleEditNotificationDateAndHour}
                            />
                            
                        </span>
                        :
                        <span >{notificationDateAndHour.toDate().toLocaleString()}</span>
                }
                
            </td>
        </tr>
    ) */
}

export default Diary;

/* function handleBlurNotificationDateAndHour(e) {
        const newTimestamp = e.target.value;
        const timestamp = Timestamp.fromDate(new Date(newTimestamp));
        setCurrentNotificationDateAndHour(new Date(newTimestamp));
        onUpdate(id, timestamp);
    } */

/* <span>{currentTimeStamp.toLocaleDateString().split('/').reverse().join('-')}<br></br><span><time>{currentTimeStamp.getHours()}</time>:<time>{currentTimeStamp.getMinutes()}</time></span></span> */

/* <button type='button' onClick={handleUpdateFinalDate}>Submit</button> */

/* <span>{notificationDateAndHour.toDate().toLocaleDateString().split('/').reverse().join('-')}<br></br><span><time>{notificationDateAndHour.toDate().getHours()}</time>:<time>{notificationDateAndHour.toDate().getMinutes()}</time></span></span> */
