import { v4 } from "uuid";

export const dropDownAdminModule = [
  {
    id: v4(),
    title: "Cliente",
    path: "/admin/client/search-client",
  },
  {
    id: v4(),
    title: "Funcionario",
    path: "/admin/functionary/search-functionary",
  },
  {
    id: v4(),
    title: "Contactos FFP",
    path: "/admin/contactsFFP/search-contactFFP",
  },
  {
    id: v4(),
    title: "Usuarios",
    path: "/admin/users/add-users",
  },
];

export const dropDownOperatorModule = [
  {
    id: v4(),
    title: "Cliente",
    path: "/admin/client/search-client",
  },
  {
    id: v4(),
    title: "Funcionario",
    path: "/admin/functionary/search-functionary",
  },
  {
    id: v4(),
    title: "Contactos FFP",
    path: "/admin/contactsFFP/search-contactFFP",
  },
];
