import { useEffect, useState } from "react"
import DashboardWrapper from "../components/dashboardWrapper"
import Clock from "../components/clock"
import style from "./cssModules/newClientView.module.css"
import { serverTimestamp } from "firebase/firestore"
import { deleteContactoFFP, insertNewContactFFP, updateContactoFFP, updateDocIdContactFFP } from "../firebase/firebase"
import { v4 as uuidv4 } from "uuid"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import data2 from '../resources/colombia.json';
import { useAuthContext } from "../contexts/authContext"

export default function NewContactFFPView() {
    const { user, isLoading } = useAuthContext()
    const fechaActual = new Date()
    const day = fechaActual.getDate()
    const month = fechaActual.getMonth() + 1
    const year = fechaActual.getFullYear()
    let time = new Date().toLocaleTimeString()
    let fechaActualizada = `${year}/${month}/${day}`
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [contactosFFP, setContactosFFP] = useState([])
    const [fuerza, setFuerza] = useState('')
    const [selectedDepartment, setSelectedDepartment] = useState('')
    const [towns, setTowns] = useState([]);
    const [selectedTown, setSelectedTown] = useState('')
    const [botonHabilitado, setBotonHabilitado] = useState(false)

    // Llamar a verificarCamposLlenos cada vez que cambie un campo
    useEffect(() => {
        const verificarCamposLlenos = () => {
            if (
                nombre !== "" &&
                telefono !== "" &&
                fuerza !== "" &&
                selectedDepartment !== "" &&
                selectedTown !== ""
            ) {
                setBotonHabilitado(true);
            } else {
                setBotonHabilitado(false);
            }
        };
        verificarCamposLlenos();
    }, [
        nombre,
        telefono,
        fuerza,
        selectedDepartment,
        selectedTown
    ]);

    const notifySuccess = () => {
        toast.success("Contacto creado satisfactoriamente", {
            theme: "colored"
        });
    }

    const notifySuccessEdit = () => {
        toast.success("Editado satisfactoriamente", {
            theme: "colored"
        });
    }

    const handleDepartmentChange = (event) => {
        filtrarMunicipioPorDepartamento(event.target.value);
        setSelectedDepartment(event.target.value);
    };

    const filtrarMunicipioPorDepartamento = (departamentoSeleccionado) => {
        try {
            const selectedDataFinal = data2.departamentos.find(depto => depto.departamento === departamentoSeleccionado);
            setTowns(selectedDataFinal.municipios);
        } catch (error) {
            console.error(error)
        }
    }

    async function handleOnSubmit(e) {
        e.preventDefault()
        try {
            await addInformation().then(notifySuccess())
        } catch (error) {
            console.error(error)
        }
        setNombre("")
        setFuerza("")
        setTelefono("")
        setSelectedDepartment("")
        setSelectedTown("")
    }

    function handleOnChangeInputs(e) {
        const value = e.target.value.toUpperCase()
        if (e.target.name === "nombre") {
            setNombre(value)
        }
        if (e.target.name === "telefono") {
            setTelefono(value)
        }
    }

    async function addInformation() {
        try {
            if (nombre !== '' && telefono !== '') {
                const newContactFFPInfo = {
                    name: nombre.toUpperCase(),
                    phone: telefono,
                    department: selectedDepartment.toUpperCase(),
                    town: selectedTown.toUpperCase(),
                    force: fuerza.toUpperCase(),
                    actualDate: fechaActualizada,
                    actualTime: time,
                    timeStamp: serverTimestamp(),
                    uid: user.uid,
                    id: uuidv4()
                }
                const res = await insertNewContactFFP(newContactFFPInfo)
                newContactFFPInfo.docId = res.id
                setContactosFFP([...contactosFFP, newContactFFPInfo])
                res && res.id && updateDocIdContactFFP(res)
            } else {
                alert("Ingrese nombre y telefono del contacto")
            }
        } catch (error) {
            console.error(error)
        }
    }

    async function handleDeleteContactoFFP(id) {
        await deleteContactoFFP(id)
        const tmp = contactosFFP.filter(cliente => cliente.id !== id)
        setContactosFFP([...tmp])
    }

    async function handleUpdateContactoFFP(id, name, phone, force, department, town) {
        const contactoFFP = contactosFFP.find(item => item.id === id)
        contactoFFP.name = name
        contactoFFP.phone = phone
        contactoFFP.force = force
        contactoFFP.department = department
        contactoFFP.town = town
        try {
            await updateContactoFFP(id, contactoFFP).then(notifySuccessEdit())
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <DashboardWrapper>
            <Clock>
                <div>
                    <h1>Agregar contacto FFP</h1>
                    <form className={style.entryContainer} action="" onSubmit={handleOnSubmit}>
                        <label htmlFor="nombre">Nombre</label>
                        <input autoComplete="off" className="input" type="text" name="nombre" onChange={handleOnChangeInputs} value={nombre} />
                        <label htmlFor="fuerza">Fuerza</label>
                        <select className="section" name="fuerza" id="fuerza" onChange={(e) => setFuerza(e.target.value)} value={fuerza}>
                            <option value=""></option>
                            <option value="Policia">Policia</option>
                            <option value="Ejercito">Ejército</option>
                            <option value="Armada">Armada</option>
                        </select>
                        <label htmlFor="telefono">Telefono</label>
                        <input autoComplete="off" className="input" type="text" name="telefono" onChange={handleOnChangeInputs} value={telefono} />
                        <label htmlFor="departamentos">Departamento</label>
                        <select value={selectedDepartment} className="section" onChange={handleDepartmentChange}>
                            <option value=""></option>
                            {
                                data2.departamentos.map(depto => (
                                    <option key={depto.departamento} value={depto.departamento}>{depto.departamento}</option>
                                ))
                            }
                        </select>
                        <label htmlFor="municipios">Municipio</label>
                        <select value={selectedTown} className="section" onChange={(event) => setSelectedTown(event.target.value)}>
                            <option value=""></option>
                            {
                                towns.map(municipio => (
                                    <option key={municipio} value={municipio}>{municipio}</option>
                                ))
                            }
                        </select>
                        <input className="btn" type="submit" value="Crear nuevo contacto" disabled={!botonHabilitado} />
                    </form>
                    <ToastContainer></ToastContainer>
                </div>
            </Clock>
        </DashboardWrapper>
    )
}