import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import DashboardWrapper from "../components/dashboardWrapper"
import Clock from "../components/clock"
import style from "./cssModules/newClientView.module.css"
import { serverTimestamp } from "firebase/firestore"
import { insertNewClient, updateDocIdClient, getClientes } from "../firebase/firebase"
import { v4 as uuidv4 } from "uuid"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from "../contexts/authContext"

export default function NewClienteView() {
    const navigate = useNavigate()
    const { user, isLoading } = useAuthContext()
    const [nombre, setNombre] = useState('')
    const [telefono, setTelefono] = useState('')
    const [clientes, setClientes] = useState([])
    const [isActive, setIsActive] = useState(true)
    const [statusMessage, setStatusMessage] = useState('')
    const [botonHabilitado, setBotonHabilitado] = useState(false)

    const notifySuccess = () => {
        toast.success("Contacto creado satisfactoriamente", {
            theme: "colored"
        });
    }

    const notifySuccessEdit = () => {
        toast.success("Editado satisfactoriamente", {
            theme: "colored"
        });
    }

    useEffect(() => {
        if (isActive) {
            setStatusMessage('Activo')
        } else {
            setStatusMessage('Inactivo')
        }
    }, [isActive])

    // Llamar a verificarCamposLlenos cada vez que cambie un campo
    useEffect(() => {
        const verificarCamposLlenos = () => {
            if (
                nombre !== "" &&
                telefono !== ""
            ) {
                setBotonHabilitado(true);
            } else {
                setBotonHabilitado(false);
            }
        };
        verificarCamposLlenos();
    }, [
        nombre,
        telefono
    ]);

    useEffect(() => {
        getClientes()
            .then((resClients) => setClientes([...resClients]))
            .catch((error) => console.error(error))
    }, [])

    function handleOnChange(e) {
        const value = e.target.value.toUpperCase()
        if (value.startsWith(" ")) return
        if (e.target.name === "nombre") {
            setNombre(value)
        }
        if (e.target.name === "telefono") {
            setTelefono(value)
        }
    }

    async function handleOnSubmit(e) {
        e.preventDefault()
        try {
            await addInformation().then(notifySuccess()).then(setTimeout(() => {
                navigate("/admin/client/search-client")
            }, 2000))
        } catch (error) {
            console.error(error)
        }
        setNombre("")
        setTelefono("")
    }

    async function addInformation() {
        try {
            if (nombre !== '' && telefono !== '') {
                const newClientInfo = {
                    name: nombre.toUpperCase(),
                    phone: telefono.toUpperCase(),
                    timeStamp: serverTimestamp(),
                    state: statusMessage,
                    uid: user.uid,
                    id: uuidv4()
                }
                const res = await insertNewClient(newClientInfo)
                newClientInfo.docId = res.id
                setNombre("")
                setTelefono("")
                setClientes([...clientes, newClientInfo])
                res && res.id && updateDocIdClient(res)
            } else {
                alert("Ingrese nombre y telefono del cliente")
            }
        } catch (error) {
            console.error(error)
        }

    }

    return (

        <DashboardWrapper>
            <Clock>
                <div>
                    <h1>Agregar cliente</h1>
                    <Link to={"/admin/client/search-client"}>
                        <button>
                            <span class="material-icons">
                                manage_search
                            </span>
                        </button>
                    </Link>
                    <form className={style.entryContainer} action="" onSubmit={handleOnSubmit}>
                        <label htmlFor="nombre">Nombre</label>
                        <input autoComplete="off" className="input" type="text" name="nombre" onChange={handleOnChange} value={nombre} />
                        <label htmlFor="telefono">Telefono</label>
                        <input autoComplete="off" className="input" type="text" name="telefono" onChange={handleOnChange} value={telefono} />
                        <div>
                            <label htmlFor="activo" className={style.activo}>Activo:</label>
                            <input disabled='off' checked={isActive} className="input" type="checkbox" name="activo" onChange={handleOnChange} />
                        </div>
                        <ToastContainer position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light" />
                        <input className="btn" type="submit" value="Crear nuevo cliente" disabled={!botonHabilitado} />
                    </form>
                </div>
            </Clock>
        </DashboardWrapper>
    )
}

