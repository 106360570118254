import { Link } from 'react-router-dom';
import style from './cssModules/dataTable.module.css'

export default function DataFunctionaries ({ data }) {

    return (
        <table className={style.tableStyle}>
            <thead>
                <tr className={style.tableTitles}>
                    <th></th>
                    <th>Nombre</th>
                    <th>Cliente</th>
                    <th>Contacto</th>
                    <th>Telefono</th>
                </tr>
            </thead>
            <tbody>
                {
                    data.map((list) => {
                        return (
                            <tr key={list.id}>
                                <td>
                                    <Link to={list.id}>
                                        <span className="material-icons md-light">description</span>
                                    </Link>
                                </td>
                                <td>{list.name}</td>
                                <td>{list.client}</td>
                                <td>{list.contact}</td>
                                <td>{list.phone}</td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};
