import { View, Image, Text, StyleSheet } from "@react-pdf/renderer"
import imagenes from "../../../resources/images/imagenes";
import { useEffect, useState } from "react";

const styles = StyleSheet.create({
    contenedorTitulo1:{
        height:100,
        width:525,
        borderStyle:'solid',
        border:1,
        display:'flex',
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
        padding:'18px',
        backgroundColor:'#e9f0f5'
    },
    imagenLogo:{
        width:130,
        paddingRight:'20px',
        opacity:0.9
    },
    tituloTexto1:{
        fontSize:18,
        fontFamily:'Helvetica-Bold',
        textAlign:'right',
        paddingLeft:20
    },
    contenedorBloque1:{
        width:525,
        height:40,
        flexDirection:'row',
        alignContent:'center',
        justifyContent:'space-evenly',
        alignItems:'center',
        paddingLeft:10,
        marginTop:15
    },
    contenedorBloqueFuncionarios:{
        width:525,
        height:40,
        flexDirection:'row',
        alignContent:'center',
        justifyContent:'space-evenly',
        alignItems:'center',
        paddingLeft:10
    },
    itemBloque1:{
        width:'50%',
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
    },
    itemBloqueFuncionarios:{
        width:'100%',
        flexDirection:'row',
        alignContent:'center',
        alignItems:'center',
    },
    tituloItems:{
        fontFamily:'Helvetica-Bold',
        paddingRight:10,
        letterSpacing:'1px'
    },
    textoItems:{
        borderStyle:'solid',
        borderBottom:2,
        width:'150px'
    },
    textoItems2:{
        borderStyle:'solid',
        borderBottom:2,
        width:'85%'
    },
    contenedorTitulo:{
        height:'29px',
        width:'100%',
        borderStyle:'solid',
        borderBottom:2,
        backgroundColor:'#E7E6E6',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center'
    },
    filaDeCeldas:{
        width:'100%',
        height:100,
        flexDirection:'row',
        borderStyle:'solid',
        borderBottom:2
    },
    tipoCelda1:{
        width:'25%',
        borderStyle:'solid',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        textAlign:'center'
    },
    fontTitulo:{
        fontSize: 14,
        textAlign:'center',
        fontFamily:'Helvetica-Bold',
        letterSpacing:2
    },
    fontSubTitulo:{
        fontSize: 10,
        textAlign:'center',
        fontFamily:'Helvetica-Bold',
        letterSpacing:1
    },
    fontCampoCelda:{
        fontSize:10,
        textAlign:'center',
        fontFamily:'Helvetica',
        letterSpacing:1
    }

})
const EvaluacionBody = ({ guardarData }) => {
    const currentDate = new Date(); // Obtener la fecha y hora actual
    const formattedDate = currentDate.toLocaleString(); // Formatear la fecha y hora como una cadena legible
    const [data, setData] = useState(guardarData)

    const getMensajeAlertaMunicipioDepartamento = (riesgo) => {
        switch (riesgo) {
            case 1:
                return "Bajo"
            case 3:
                return "Medio"
            case 4:
                return "Alto"
            case 5:
                return "Crítico"
            default:
                break;
        }
    }

    const getPerfilPersona = (riesgo) => {
        switch (riesgo) {
            case 1:
                return "Bajo"
            case 2:
                return "Medio-Bajo"
            case 3:
                return "Medio"
            case 4:
                return "Medio-Alto"
            case 5:
                return "Alto"
            default:
                break;
        }
    }

    const getIncidentesYProtestas = (riesgo) => {
        switch (riesgo) {
            case 1:
                return "Hace 1 semestre"
            case 2:
                return "Hace 2 meses"
            case 3:
                return "Hace 1 mes"
            case 4:
                return "Hace 15 días"
            case 5:
                return "En la última semana"
            default:
                break;
        }
    }

    const getOrganizacionesDelictivas = (riesgo) => {
        switch (riesgo) {
            case 1:
                return "Sin incidencia"
            case 3:
                return "Presencia en el área"
            case 5:
                return "Acciones en el área"
            default:
                break;
        }
    }

    const getComunicaciones = (riesgo) => {
        switch (riesgo) {
            case 1:
                return "Señal celular"
            case 3:
                return "Señal intermitente"
            case 4:
                return "Sin señal"
            default:
                break;
        }
    }

    return (
        <View style={[{ width: 525 }, { zIndex: -1 }]}>
            {/* LOGO Y TITULO */}
            <View style={styles.contenedorTitulo1}>
                <Image src={imagenes.img1} style={styles.imagenLogo} />
                <Text style={styles.tituloTexto1}>
                    Evaluación de Riesgos
                </Text>
            </View>
            {/* BLOQUE 1 CONTIENE FECHA Y NOMBRE DEL CLIENTE */}
            <View style={styles.contenedorBloque1}>
                <View style={styles.itemBloque1}>
                    <Text style={styles.tituloItems}>Cliente :</Text>
                    <View style={styles.textoItems}>
                        <Text >{data.clienteEvRiesgo}</Text>
                    </View>
                </View>
                <View style={styles.itemBloque1}>
                    <Text style={styles.tituloItems}>Fecha y Hora :</Text>
                    <View style={styles.textoItems}>
                        <Text >{formattedDate}</Text>
                    </View>
                </View>
            </View>
            {/* BLOQUE 1 CONTIENE NOMBRES DE FUNCIONARIOS */}
            <View style={styles.contenedorBloqueFuncionarios}>
                <View style={styles.itemBloqueFuncionarios}>
                    <Text style={styles.tituloItems}>Funcionarios :</Text>
                    <View style={[styles.textoItems2,{height:'42px'}]}>
                        <Text>{data.funcionarios}</Text>
                    </View>
                </View>
            </View>
            {/* BLOQUE 1 CONTIENE PUNTO ORIGEN PUNTO DESTINO */}
            <View style={styles.contenedorBloqueFuncionarios}>
                <View style={styles.itemBloque1}>
                    <Text style={[styles.tituloItems, {letterSpacing:'0.5'}]}>Punto de Origen :</Text>
                    <View style={[styles.textoItems,{width:'50%'}]}>
                        <Text>{data.puntoOrigen}</Text>
                    </View>
                </View>
                <View style={styles.itemBloque1}>
                    <Text style={styles.tituloItems}>Punto Destino :</Text>
                    <View style={[styles.textoItems]}>
                            <Text>{data.puntoDestino}</Text>
                    </View>
                </View>
            </View>
            {/* BLOQUE 1 CONTIENE NOMBRES DE FUNCIONARIOS */}
            <View style={styles.contenedorBloqueFuncionarios}>
                <View style={styles.itemBloqueFuncionarios}>
                    <Text style={styles.tituloItems}>Fecha del Desplazamiento :</Text>
                    <View style={[styles.textoItems2, {width:'60%'}]}>
                        <Text >{data.fechaDesplazamiento}</Text>
                    </View>
                </View>
            </View>
            {/* CONTENEDOR EVALUACIÓN DE RIESGO*/}
            <View style={[{ borderStyle: 'solid' }, { border: 3 }, { marginTop: 25 }, { height: '500px' }]}>
                {/* FILA 1 DE EVALUACIÓN DE RIESGO */}
                <View style={[styles.filaDeCeldas]}>
                    <View style={[styles.tipoCelda1, { width: '28.7%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>Información de Riesgos</Text>
                    </View>
                    <View style={[styles.tipoCelda1, { width: '71.3%' }]}>

                        <View style={[styles.filaDeCeldas, { with: '100%' }]}>
                            <View style={[styles.tipoCelda1, { width: '33%' }]}>
                                <Text style={styles.fontSubTitulo}>Perfil de las {`\n`} Personas</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontSubTitulo}>Estado de Alerta en el Departamento</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontSubTitulo}>Estado de Alerta en el Municipio</Text>
                            </View>

                        </View>

                        <View style={[styles.filaDeCeldas, { with: '100%' }, { borderBottom: 0 }]}>

                            <View style={[styles.tipoCelda1, { width: '33%' }]}>
                                <Text style={styles.fontCampoCelda} >{getPerfilPersona(data.perfilDePersonas)}</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontCampoCelda} >{getMensajeAlertaMunicipioDepartamento(data.estadoAlertaDepartamento)}</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontCampoCelda} >{getMensajeAlertaMunicipioDepartamento(data.estadoAlertaMunicipio)}</Text>
                            </View>
                        </View>
                    </View>

                </View>
                {/* FILA 2 DE EVALUACIÓN DE RIESGO */}
                <View style={[styles.filaDeCeldas]}>
                    <View style={[styles.tipoCelda1, { width: '28.7%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>Amenazas</Text>
                    </View>
                    <View style={[styles.tipoCelda1, { width: '71.3%' }]}>

                        <View style={[styles.filaDeCeldas, { with: '100%' }]}>
                            <View style={[styles.tipoCelda1, { width: '33%' }]}>
                                <Text style={styles.fontSubTitulo}>Incidentes de Orden Publico</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontSubTitulo}>Organizaciones Delictivas</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontSubTitulo}>Protesta Social</Text>
                            </View>

                        </View>

                        <View style={[styles.filaDeCeldas, { with: '100%' }, { borderBottom: 0 }]}>

                            <View style={[styles.tipoCelda1, { width: '33%' }]}>
                                <Text style={styles.fontCampoCelda} >{getIncidentesYProtestas(data.incidentesOrdenPublico)}</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontCampoCelda} >{getOrganizacionesDelictivas(data.organizacionesDelictivas)}</Text>
                            </View>
                            <View style={[styles.tipoCelda1, { width: '33%' }, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                                <Text style={styles.fontCampoCelda} >{getIncidentesYProtestas(data.protestaSocial)}</Text>
                            </View>
                        </View>
                    </View>
                </View>

                {/* FILA 3 DE EVALUACIÓN DE RIESGO */}
                <View style={[styles.filaDeCeldas]}>
                    <View style={[styles.tipoCelda1, { width: '52.4%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>Comunicación</Text>
                    </View>
                    <View style={[styles.tipoCelda1, { width: '47.6%' }, { height: '100%' }]}>

                        <View style={[styles.filaDeCeldas, { with: '100%' }, { alignItems: 'center' }]}>
                            <View style={[styles.tipoCelda1, { width: '100%' }]}>
                                <Text style={styles.fontSubTitulo}>Comunicación</Text>
                            </View>

                        </View>

                        <View style={[styles.filaDeCeldas, { with: '100%' }, { borderBottom: 0 }, { alignItems: 'center' }]}>

                            <View style={[styles.tipoCelda1, { width: '100%' }]}>
                                <Text style={styles.fontCampoCelda} >{getComunicaciones(data.comunicacion)}</Text>
                            </View>

                        </View>
                    </View>
                </View>
                {/* FILA 4 DE EVALUACIÓN DE RIESGO */}
                <View style={[styles.filaDeCeldas, { height: 110 }]}>
                    <View style={[styles.tipoCelda1, { width: '30%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>Nivel de Riesgo</Text>
                    </View>
                    <View style={[styles.tipoCelda1, { width: '20%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>{data.total}</Text>
                    </View>
                    <View style={[styles.tipoCelda1, { width: '20%' }, { borderStyle: 'solid' }, { borderRight: 1 }]}>
                        <Text style={styles.fontSubTitulo}>{data.mensaje}</Text>
                        <Image src={imagenes.img3} style={{ width: '50px' }} />
                    </View>
                    <View style={[styles.tipoCelda1, { width: '30%' }]}>
                        <Text style={styles.fontSubTitulo}>Nivel de Riesgo</Text>

                        {/* FILA 3 DE EVALUACIÓN DE RIESGO */}
                        <View style={[{ width: '100%' }, { height: '70px' }]}>
                            <View style={[styles.filaDeCeldas, { height: '17.5px' }, { borderTop: 1 }, { borderBottom: '0' }]}>
                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }]}>
                                    <Text style={styles.fontSubTitulo}>1-2</Text>
                                </View>

                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { borderLeft: '1' }, { height: '17.5px' }, { backgroundColor: '#00C900' }]}>
                                    <Text style={styles.fontSubTitulo}>Bajo</Text>
                                </View>
                            </View>
                            <View style={[styles.filaDeCeldas, { height: '17.5px' }, { borderTop: 1 }, { borderBottom: '0' }]}>
                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }]}>
                                    <Text style={styles.fontSubTitulo}>2.1 - 3.5</Text>
                                </View>

                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }, { borderLeft: '1' }, { backgroundColor: '#FFFF00' }]}>
                                    <Text style={styles.fontSubTitulo}>Medio</Text>
                                </View>
                            </View>
                            <View style={[styles.filaDeCeldas, { height: '17.5px' }, { borderTop: 1 }, { borderBottom: '0' }]}>
                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }]}>
                                    <Text style={styles.fontSubTitulo}>3.5 - 4.5</Text>
                                </View>

                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }, { borderLeft: '1' }, { backgroundColor: '#fba328' }]}>
                                    <Text style={styles.fontSubTitulo}>Alto</Text>
                                </View>
                            </View>
                            <View style={[styles.filaDeCeldas, { height: '17.5px' }, { borderTop: 1 }, { borderBottom: '1' }]}>
                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '17.5px' }]}>
                                    <Text style={styles.fontSubTitulo}>4.6 - 5</Text>
                                </View>

                                <View style={[styles.tipoCelda1, { width: '50%' }, { borderStyle: 'solid' }, { height: '16.7px' }, { borderLeft: '1' }, { borderBottom: '2' }, { backgroundColor: '#C90202' }]}>
                                    <Text style={styles.fontSubTitulo}>Critico</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                {/* CONTENEDOR TITULO OBSERVACIONES*/}
                <View >
                    <View style={styles.contenedorTitulo}>
                        <Text style={styles.fontTitulo}>Observaciones</Text>
                    </View>
                    {/* FILA 1 DE OBSERVACIONES */}
                    <View style={[styles.filaDeCeldas, { height: '140px' }]}>
                        <Text style={[{ padding: 20 }]}>
                            {data.observaciones}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    )
}
export default EvaluacionBody