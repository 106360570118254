import React, { useState } from "react";
import { dropDownAdminModule, dropDownOperatorModule } from "./dropdownItems";
import style from "./cssModules/dropDown.module.css";
import { Link } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";

function DropDown({ closeDropDown }) {
  const { user } = useAuthContext();
  /* const [user, setUser]= useState({rol:"operador"}) */

  return (
    <>
      {user.rol !== "cliente" ? (
        <ul className={style.servicesSubmenu}>
          {user?.rol === "admin"
            ? dropDownAdminModule.map((valor) => (
                <li key={valor.id} className={style.subMenuItem}>
                  <Link to={valor.path} onClick={() => closeDropDown()}>
                    {valor.title}
                  </Link>
                </li>
              ))
            : user?.rol === "operador"
            ? dropDownOperatorModule.map((valor) => (
                <li key={valor.id} className={style.subMenuItem}>
                  <Link to={valor.path} onClick={() => closeDropDown()}>
                    {valor.title}
                  </Link>
                </li>
              ))
            : null}
        </ul>
      ) : null}
    </>
  );
}

export default DropDown;
