import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDiary, getSolicitude } from "../firebase/firebase";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import style4 from "./cssModules/solicitudeClientDetails.module.css";
import Diary from "../components/diary";
import Solicitude from "../components/solicitude";
import PdfViewer from "../components/pdfViewer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocumentFull from "../components/pdfFiles/finalReport/DocumentFull";

const SolicitudeClientDetails = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [dataDiary, setDataDiary] = useState(null);
  const [pdfViewer, setPdfViewer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSolicitude(params.id)
      .then((res) => setData(res))
      .catch((error) => console.error(error));
  }, [params.id]);

  useEffect(() => {
    getDiary(params.id)
      .then((data) => setDataDiary([...data]))
      .catch((error) => console.error(error));
  }, [params.id]);

  return (
    <DashboardWrapper>
      <div>
        <button
          onClick={() => navigate("/solicitude/search-solicitude")}
          className={style4.btnGoBack}
          type="button"
        >
          Atras
        </button>
        <div className={style4.boxContent}>
          <div className={style4.boxColored}>
            <h2>Gerenciamiento de viaje</h2>
          </div>
          {data ? (
            <div>
              <div className={style4.mainContainerTable}>
                <Solicitude
                  id={data.id}
                  docId={data.docId}
                  actualTime={data.actualTime}
                  actualDate={data.actualDate}
                  client={data.client}
                  completeRoute={data.completeRoute}
                  diaryRoute={data.diaryRoute}
                  finalDate={data.finalDate}
                  finalDepartment={data.finalDepartment}
                  finalTown={data.finalTown}
                  functionaries={data.functionaries}
                  initialDate={data.initialDate}
                  initialDepartment={data.initialDepartment}
                  initialTown={data.initialTown}
                  observations={data.observations}
                  risk={data.risk}
                  routeImage={data.routeImage}
                  scrollType={data.scrollType}
                  state={data.state}
                  timeStamp={data.timeStamp}
                  uid={data.uid}
                  isEditable={false}
                />
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <section className={style4.diaryHistorial}>
          <div className={style4.boxContent}>
            <div className={style4.boxColored}>
              <h2>Agendamientos de viaje</h2>
            </div>
            {dataDiary ? (
              <table className={style4.tableStyle}>
                <thead>
                  <tr>
                    <th>Ubicación</th>
                    <th>Comentarios</th>
                    <th>Fecha y hora del reporte</th>
                    <th>Fecha y hora próximo reporte</th>
                  </tr>
                </thead>
                <tbody>
                  {dataDiary && Array.isArray(dataDiary) ? (
                    [...dataDiary] // Hacemos una copia del array para no modificar el original
                      .sort(
                        (a, b) => b.timeStamp.toDate() - a.timeStamp.toDate()
                      ) // Ordenar por timeStamp en orden descendente
                      .map((list) => (
                        <Diary
                          key={list.id}
                          id={list.id}
                          idSolicitude={list.idSolicitude}
                          comments={list.comments}
                          location={list.location}
                          timeStamp={list.timeStamp}
                          notificationDate={list.notificationDate}
                          notificationHour={list.notificationHour}
                          locationProvitional={list.locationProvitional}
                          notificationDateAndHour={list.notificationDateAndHour}
                        />
                      ))
                  ) : (
                    <p>Loading ...</p>
                  )}
                </tbody>
              </table>
            ) : (
              <p>Loading ... </p>
            )}
          </div>
        </section>
        <section>
          <div className={style4.boxContent}>
            <div className={style4.boxColored}>
              <h2>Reporte PDF</h2>
            </div>
            {data && dataDiary && !pdfViewer ? (
              <div className={style4.btnContainer}>
                <button
                  className={style4.viewBtn}
                  type="button"
                  onClick={(e) => setPdfViewer(true)}
                >
                  Visualizar PDF
                </button>
                <div className={style4.PDFButton}>
                  <PDFDownloadLink
                    document={
                      <DocumentFull data={data} dataDiary={dataDiary} />
                    }
                    fileName="reporte.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Generando documento..."
                      ) : (
                        <button type="button" className={style4.downloadBtn}>
                          Descargar
                        </button>
                      )
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            ) : pdfViewer ? (
              <div className={style4.mainContainerTable}>
                <PdfViewer data={data} dataDiary={dataDiary} />
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </DashboardWrapper>
  );
};

export default SolicitudeClientDetails;
