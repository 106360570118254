import { Link } from 'react-router-dom';
import style from './cssModules/dataTable.module.css'

export default function DataContactosFFP ({ data }) {

    return (
        <table className={style.tableStyle}>
            <thead>
                <tr className={style.tableTitles}>
                    <th></th>
                    <th>Nombre</th>
                    <th>Fuerza</th>
                    <th>Telefono</th>
                    <th>Departamento</th>
                    <th>Municipio</th>
                </tr>
            </thead>
            <tbody>
                {
                    data?.map((list) => {
                        return (
                            <tr key={list.id}>
                                <td>
                                    <Link to={list.id}>
                                        <span className="material-icons md-light">description</span>
                                    </Link>
                                </td>
                                <td>{list.name}</td>
                                <td>{list.force}</td>
                                <td>{list.phone}</td>
                                <td>{list.department}</td>
                                <td>{list.town}</td>
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};
