import { Image, StyleSheet, View } from "@react-pdf/renderer"
import imagenes from "../../../resources/images/imagenes"

const styles = StyleSheet.create({
    watermark: {
        position: 'absolute',
        top: -50,
        left: 40,
        right: 0,
        bottom: 0,
        opacity: 0.2,
        alignItems: 'center',
        justifyContent: 'center',
        width:'450px'
        },
})



const WaterMark =()=>{
    return(
        <View style={styles.watermark} fixed>
            <Image src={imagenes.img1}/>
        </View>
    )
}
export default WaterMark