import { useEffect, useState } from "react";
import LeafletMap from "./leafletMap";
import AutocompleteLeaflet from "./autocompleteLeaflet";

function MapWithAutocompleteLeaflet({ locationSelected }) {
  const [selectPlace, setSelectPlace] = useState(null);

  useEffect(() => {
    // Actualizar la prop locationSelected cada vez que selectPlace cambie
    if (selectPlace) {
      const locationObject = {
        name: selectPlace.display_name || "",
        geometry: {
          location: {
            lat: selectPlace.lat || 0,
            lng: selectPlace.lon || 0,
          },
        },
      };
      locationSelected && locationSelected(locationObject);
    }
  }, [selectPlace, locationSelected]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "350px",
        padding: "10px",
        paddingLeft: "0",
      }}
    >
      <div
        style={{
          width: "50%",
        }}
      >
        <LeafletMap selectPosition={selectPlace} />
      </div>
      <div
        style={{
          display: "block",
          width: "50%",
        }}
      >
        <AutocompleteLeaflet
          selectPosition={selectPlace}
          setSelectPosition={setSelectPlace}
        />
      </div>
    </div>
  );
}

export default MapWithAutocompleteLeaflet;
