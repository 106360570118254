import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../firebase/firebase";
import React, { createContext, useContext, useState } from "react";

const auth = getAuth(app);
const firestore = getFirestore(app);
export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  function setUserWithFirebase(usuarioFirebase) {
    getRol(usuarioFirebase.uid).then((data) => {
      const userData = {
        uid: usuarioFirebase.uid,
        email: usuarioFirebase.email,
        rol: data?.rol,
        avaiableFor: data?.avaiableFor,
      };
      setUser(userData);
      setIsLoading(false);
    });
  }

  onAuthStateChanged(auth, async (usuarioFirebase) => {
    if (usuarioFirebase) {
      if (!user) {
        setUserWithFirebase(usuarioFirebase);
      }
    } else {
      setUser(null);
      console.log("No hay nadie logueado");
      setIsLoading(false);
    }
  });

  async function getRol(uid) {
    const documentRef = doc(firestore, `usuarios/${uid}`);
    try {
      const document = await getDoc(documentRef);

      if (document.exists()) {
        const dataFinal = document.data();
        return dataFinal;
      } else {
        console.log("Document does not exist");
        return null; // You can return a default value or handle the case where the document doesn't exist.
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      return null; // Handle the error as needed.
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useDataContent must be within a DataContextProvider");
  }
  return context;
}
