import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import {
  updateFuncionario,
  deleteFuncionario,
  getFunctionary,
} from "../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Functionary from "../components/functionary";

export default function FunctionaryDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [functionary, setFunctionary] = useState([]);
  const [botonHabilitado, setBotonHabilitado] = useState(false);

  const notifySuccessEdit = () => {
    toast.success("Editado satisfactoriamente", {
      theme: "colored",
    });
  };

  useEffect(() => {
    const verificarCamposLlenos = () => {
      if (nombre !== "" && telefono !== "") {
        setBotonHabilitado(true);
      } else {
        setBotonHabilitado(false);
      }
    };
    verificarCamposLlenos();
  }, [nombre, telefono]);

  useEffect(() => {
    getFunctionary(params.id)
      .then((functionary) => setFunctionary(functionary))
      .catch((error) => console.error(error));
  }, [params.id]);

  async function handleDeleteFunctionary(id) {
    await deleteFuncionario(id)
      .then(notifySuccessEdit())
      .then(navigate("/admin/client/search-client"))
      .catch((error) => console.error(error));
  }

  async function handleUpdateFunctionary(id, field, newValue) {
    const editFuncionario = functionary;
    editFuncionario[field] = newValue;
    await updateFuncionario(id, editFuncionario)
      .then(notifySuccessEdit())
      .then(setFunctionary({ ...editFuncionario }))
      .catch((error) => console.error(error));
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <h1>Funcionario: {functionary.name}</h1>
          <button type="button">
            <Link to={"/admin/functionary/search-functionary"}>
              <span className="material-icons">manage_search</span>
            </Link>
          </button>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />

          <div>
            <Functionary
              key={functionary.id}
              id={functionary.id}
              name={functionary.name}
              phone={functionary.phone}
              client={functionary.client}
              contact={functionary.contact}
              state={functionary.state}
              timeStamp={functionary.timeStamp}
              actualDate={functionary.actualDate}
              actualTime={functionary.actualTime}
              onUpdate={handleUpdateFunctionary}
              onDelete={handleDeleteFunctionary}
            ></Functionary>
          </div>
        </div>
      </Clock>
    </DashboardWrapper>
  );
}

/*client is not iterable
TypeError: client is not iterable
    at handleUserLoggedIn (http://localhost:3000/main.627c3c3a271353373382.hot-update.js:95:20)
    */
