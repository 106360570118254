import { HashRouter, Route, Routes } from "react-router-dom";
import LoginEmailView from "./routes/loginEmailView";
import SearchSolicitudes from "./routes/searchSolicitudes";
import RiskAssessment from "./routes/riskAssessment";
import { AlarmContextProvider } from "./contexts/alarmContext";
import { DataContextProvider } from "./contexts/dataContext";
import Signout from "./routes/signout";
import SearchClientView from "./routes/searchClientView";
import NewClienteView from "./routes/newClientView";
import ClientDetails from "./routes/clientDetails";
import NewContactFFPView from "./routes/newContactosFFP";
import SearchContactFFPView from "./routes/searchContactFFP";
import NewFunctionaryView from "./routes/newFunctionary";
import SearchFunctionaryView from "./routes/searchFunctionaryView";
import FunctionaryDetails from "./routes/functionaryDetails";
import UsersView from "./routes/newUsers";
import NewSolicitudeView from "./routes/newSolicitude";
import SolicitudeDetails from "./routes/solicitudeDetails";
import PdfViewer from "./components/pdfViewer";
import { ProtectedRoute } from "./components/protectedRoute";
import { useAuthContext } from "./contexts/authContext";
import ClientPageView from "./routes/clientPageView";
import MapsTrackingWithLeaflet from "./routes/mapsTrackingWithLeaflet";
import RiskAssessmentClient from "./routes/riskAssessmentClient";
import SolicitudeClientDetails from "./routes/solicitudeClientDetails";

function App() {
  const { user, isLoading } = useAuthContext();

  return (
    <HashRouter>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Routes>
          <Route
            element={
              <ProtectedRoute
                isAllowed={user === null}
                redirectTo={
                  user?.rol === "admin" || user?.rol === "operador"
                    ? "/solicitude/search-solicitude"
                    : "/client/search-client-page"
                }
              />
            }
          >
            <Route path="/" element={<LoginEmailView />} />
          </Route>
          <Route path="/signout" element={<Signout />} />
          <Route
            path="/admin/users/add-users"
            element={
              <ProtectedRoute isAllowed={!!user && user.rol === "admin"}>
                <DataContextProvider>
                  <AlarmContextProvider>
                    <UsersView />
                  </AlarmContextProvider>
                </DataContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && (user?.rol === "admin" || user?.rol === "operador")
                }
              />
            }
          >
            <Route
              path="/risk-assessment"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <RiskAssessment />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/client/search-client"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <SearchClientView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/client/add-client"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <NewClienteView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/client/search-client/:id"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <ClientDetails />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/contactsFFP/add-contactFFP"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <NewContactFFPView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/contactsFFP/search-contactFFP"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <SearchContactFFPView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/functionary/add-functionary"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <NewFunctionaryView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/functionary/search-functionary"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <SearchFunctionaryView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/admin/functionary/search-functionary/:id"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <FunctionaryDetails />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/solicitude/add-solicitude"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <NewSolicitudeView />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="solicitude/search-solicitude/:id"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <SolicitudeDetails />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="solicitude/search-solicitude/:id/pdf-viewer"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <PdfViewer />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/solicitude/search-solicitude"
              element={
                <DataContextProvider>
                  <AlarmContextProvider>
                    <SearchSolicitudes user={user} />
                  </AlarmContextProvider>
                </DataContextProvider>
              }
            />
          </Route>
          <Route
            path="/solicitude/tracking-solicitude"
            element={
              <ProtectedRoute
                isAllowed={
                  !!user && (user?.rol === "admin" || user?.rol === "operador")
                }
              >
                <DataContextProvider>
                  <AlarmContextProvider>
                    <MapsTrackingWithLeaflet isClient={false} />
                  </AlarmContextProvider>
                </DataContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/tracking-solicitude"
            element={
              <ProtectedRoute isAllowed={!!user && user?.rol === "cliente"}>
                <MapsTrackingWithLeaflet isClient={true} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/risk-assessment"
            element={
              <ProtectedRoute isAllowed={!!user && user?.rol === "cliente"}>
                <RiskAssessmentClient />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/search-client-page"
            element={
              <ProtectedRoute isAllowed={!!user && user?.rol === "cliente"}>
                <ClientPageView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client/search-client-page/:id"
            element={
              <ProtectedRoute isAllowed={!!user && user?.rol === "cliente"}>
                <SolicitudeClientDetails />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<>Not Found</>} />
        </Routes>
      )}
    </HashRouter>
  );
}
export default App;
