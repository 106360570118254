import { useContext } from "react";
import sytle from "../components/cssModules/clock.module.css"
import { AlarmContext } from "../contexts/alarmContext";

const Clock = ({ children }) => {
    const { horaCompleta } = useContext(AlarmContext)

    return (
        <div>
            <div className={sytle.clock}>
                <h1>{horaCompleta}</h1>
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}
export default Clock;


/* <div>
                <button onClick={() => pauseAlarm()}>Pausar alarma</button>
                {
                    loading === false ? data.map((data) => {
                        return (
                            <small></small>
                        )
                    }) : "Loading"
                }
                {
                    alarmTime.map((data) => {
                        console.log(data)
                        return (<small>{data}</small>
                        )
                    })
                }
            </div> */