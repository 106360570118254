import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth, db, getClientes } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import style from "./cssModules/loginEmailView.module.css";
import imagenes from "../resources/images/imagenes";
import { doc, setDoc } from "firebase/firestore";

export default function LoginEmailView() {
  const [isRegistrando, setIsRegistrando] = useState(false);
  const [rol, setRol] = useState("cliente"); // Establece un valor predeterminado para el rol
  const [avaiableFor, setAvaiableFor] = useState("all"); // Establece un valor predeterminado para avaiableFor
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getClientes()
      .then((res) => setClients(res))
      .catch((error) => console.error(error));
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.contrasena.value;

    async function registerSubmit(email, password, rol, avaiableFor) {
      const authentification = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      ).then((usuarioFirebase) => usuarioFirebase);
      console.log(authentification);
      const docuRef = await doc(db, `usuarios/${authentification.user.uid}`);
      setDoc(docuRef, {
        correo: email,
        rol,
        uid: authentification.user.uid,
        avaiableFor,
      });
    }

    if (isRegistrando) {
      //registrar
      registerSubmit(email, password, rol, avaiableFor);
    } else {
      //login
      signInWithEmailAndPassword(auth, email, password)
        .then(() => navigate("/solicitude/add-solicitude"))
        .catch((error) => console.error(error));
    }
  };

  return (
    <div className={style.appContainer}>
      <main>
        <section className={style.formMain}>
          <div className={style.formContent}>
            <section>
              <div className={style.box}>
                <section>
                  <div className={style.imageContain}>
                    <img src={imagenes.img1} alt="logo" />
                  </div>
                  <div className={style.section}>
                    <h3> Inicia sesión </h3>
                    <form onSubmit={submitHandler}>
                      <div className={style.inputBox}>
                        <input
                          className={style.inputControl}
                          id="email"
                          name="email"
                          type="email"
                          required
                          placeholder="Email address"
                        />
                      </div>
                      <div className={style.inputBox}>
                        <input
                          className={style.inputControl}
                          id="contrasena"
                          name="contrasena"
                          type="password"
                          required
                          placeholder="Password"
                        />
                      </div>
                      {isRegistrando && (
                        <label htmlFor="">
                          Rol:
                          <select
                            name="rol"
                            value={rol}
                            onChange={(e) => setRol(e.target.value)}
                          >
                            <option value="admin">Administrador</option>
                            <option value="operador">Operador</option>
                            <option value="cliente">Cliente</option>
                          </select>
                        </label>
                      )}
                      {isRegistrando && rol === "cliente" && (
                        <label htmlFor="">
                          Cliente:
                          <select
                            name="avaiableFor"
                            value={avaiableFor}
                            onChange={(e) => setAvaiableFor(e.target.value)}
                          >
                            {clients.map((clientes) => (
                              <option value={clientes.name} key={clientes.id}>
                                {clientes.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      )}
                      <button
                        type="submit"
                        className={style.btn}
                        value={isRegistrando ? "Registrar" : "Iniciar sesión"}
                      >
                        {isRegistrando ? "Registrar" : "Iniciar sesión"}
                      </button>
                      {/* <button type='button' onClick={() => setIsRegistrando(!isRegistrando)}>
                                                {
                                                    isRegistrando ? "Ya tengo una cuenta" : "Quiero registrarme"
                                                }
                                            </button> */}
                    </form>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </section>
      </main>
    </div>
  );
}
