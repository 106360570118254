import { Document, Page,StyleSheet  } from "@react-pdf/renderer";
import EvaluacionBody from "./EvaluacionBody";
import WaterMark from "./WaterMark";
import { useState } from "react";

const styles = StyleSheet.create({
    pageConfig: {
    marginBottom: 36,
    marginTop:36,
    marginLeft:36,
    marginRight:36,
    fontSize: 12,
    textAlign:'justify'
    }
})

const DocFullEvaRiesgo=({guardarData})=>{
    const [data, setData] = useState(guardarData)

    return(
        <Document>
            <Page style={styles.pageConfig}>
                <WaterMark />
                <EvaluacionBody guardarData={data} />
            </Page>
        </Document>
    )
}
export default DocFullEvaRiesgo