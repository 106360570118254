import style from "./cssModules/newDairySolicitudes.module.css";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";
import MapsWithASingleDirection from "./mapsWithASingleDirection";
import AutocompleteDiaryComents from "./autocompleteDiaryComents";
import { ToastContainer, toast } from "react-toastify";
import {
  insertNewDiarySubcolection,
  updateDocIdDiarySubcolection,
} from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/authContext";
import MapWithAutocompleteLeaflet from "./mapWithAutocompleteLeaflet";

function NewDairySolicitudes({ onUpdateSolicitude, idSolicitude, data }) {
  const [comentarios, setComentarios] = useState("");
  const [fechaYHoraNotificacion, setFechaYHoraNotificacion] = useState("");
  const [fechaYHoraReporte, setFechaYHoraReporte] = useState("");
  const [lngLatCoordenadas, setLngLatCoordenadas] = useState({
    name: "",
    geometry: { location: { lat: 0, lng: 0 } },
  });
  const [botonHabilitado, setBotonHabilitado] = useState(false);
  const [lugarProvisional, setLugarProvisional] = useState("");
  const [agenda, setAgenda] = useState([]);
  const { user, isLoading } = useAuthContext();
  const navigate = useNavigate();

  const notify = () => {
    toast.success("Solicitud creada satisfactoriamente", {
      theme: "colored",
    });
  };

  const notifyError = () => {
    toast.error("Error al crear la solicitud", {
      theme: "colored",
    });
  };

  async function handleOnSubmit2(e) {
    e.preventDefault();
    newDiary();
    if (data === "") {
      onUpdateSolicitude(idSolicitude, "Nuevo");
    } else if (data === "Nuevo") {
      onUpdateSolicitude(idSolicitude, "En proceso");
    }
    setTimeout(() => {
      navigate("/solicitude/search-solicitude");
    }, 1000);
  }

  useEffect(() => {
    const verificarCamposLlenos = () => {
      if (
        JSON.stringify(lngLatCoordenadas) !==
          JSON.stringify({
            name: "",
            geometry: { location: { lat: 0, lng: 0 } },
          }) &&
        comentarios !== "" &&
        fechaYHoraNotificacion !== "" &&
        fechaYHoraReporte !== ""
      ) {
        setBotonHabilitado(true);
      } else {
        setBotonHabilitado(false);
      }
    };
    verificarCamposLlenos();
  }, [
    comentarios,
    fechaYHoraNotificacion,
    fechaYHoraReporte,
    lngLatCoordenadas,
  ]);

  async function newDiary() {
    try {
      const dateFechaYHora = new Date(fechaYHoraNotificacion);
      const dateFechaYHoraReporte = new Date(fechaYHoraReporte);
      if (
        dateFechaYHora !== "" &&
        JSON.stringify(lngLatCoordenadas) !==
          JSON.stringify({
            name: "",
            geometry: { location: { lat: 0, lng: 0 } },
          }) &&
        comentarios !== ""
      ) {
        const newDiarySolicitude = {
          comments: comentarios,
          notificationDateAndHour: dateFechaYHora,
          /* locationProvitional: lugarProvisional, */
          location: lngLatCoordenadas,
          timeStamp: dateFechaYHoraReporte,
          idSolicitude: idSolicitude,
          uid: user.uid,
          id: uuidv4(),
        };
        const res = await insertNewDiarySubcolection(
          idSolicitude,
          newDiarySolicitude
        );
        if (res && res.id) {
          newDiarySolicitude.docId = res.id;
        }
        setAgenda([...agenda, newDiarySolicitude]);
        notify();
        res &&
          res.id &&
          (await updateDocIdDiarySubcolection(idSolicitude, res.id));
      } else {
        throw new Error("Llene todos los campos");
      }
    } catch (error) {
      console.error(error);
      notifyError();
    }
  }

  return (
    <div>
      <form action="" onSubmit={handleOnSubmit2}>
        <MapWithAutocompleteLeaflet
          locationSelected={setLngLatCoordenadas}
        ></MapWithAutocompleteLeaflet>
        <div className={style.diary}>
          <div className={style.comments}>
            <div className={style.double}>
              {/* <label htmlFor="" className={style.comentarios}>
                Lugar
              </label>
              <input
                type="text"
                value={lugarProvisional}
                onChange={(e) => setLugarProvisional(e.target.value)}
              /> */}
              <label htmlFor="fechaReporte" className={style.comentarios}>
                Fecha reporte
              </label>
              <input
                type="datetime-local"
                value={fechaYHoraReporte}
                onChange={(e) => setFechaYHoraReporte(e.target.value)}
              />
              <label htmlFor="fechaSugerida" className={style.comentarios}>
                Fecha próximo contacto
              </label>
              <input
                type="datetime-local"
                value={fechaYHoraNotificacion}
                onChange={(e) => setFechaYHoraNotificacion(e.target.value)}
              />
            </div>
            <label htmlFor="Comentarios" className={style.autocomplete}>
              Observaciones{" "}
            </label>
            <AutocompleteDiaryComents
              valorInput={setComentarios}
            ></AutocompleteDiaryComents>
          </div>

          <button
            type="submit"
            className={style.btnSubmit}
            disabled={!botonHabilitado}
          >
            Submit
          </button>
        </div>
      </form>
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default NewDairySolicitudes;
