import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import imagenes from "../../../resources/images/imagenes";
import React, { useState } from "react";

const styles = StyleSheet.create({
    tableBodyPag3: {
        width: '87%',
        minHeight: '150px',
        marginRight: 36,
        flexDirection: 'column'

    },
    contenedorTitulo: {
        height: '29px',
        width: '100%',
        borderStyle: 'solid',
        borderRight: 2,
        borderLeft: 2,
        borderTop: 2,
        backgroundColor: '#E7E6E6',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    filaDeCeldas2: {
        minHeight: '80px',
        maxHeight: '400px',
        width: '100%',
        flexDirection: 'column',
        borderStyle: 'solid',
        borderWidth: 2,
        borderBottom: 2,
        borderRight: 2,
        borderLeft: 2,
        marginBottom: 10
    },
    documentosAnexos: {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '10px',
        paddingBottom: '10px',
        opacity: 0.9
    },
    fontTitulo: {
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        letterSpacing: 2
    }
})


const BodyPag3 = ({ data, dataDiary }) => {
    const [dataRecibida, setDataRecibida] = useState(data)
    const [dataDiaryRecibida, setDataDiaryRecibida] = useState(dataDiary)

    // Estado para almacenar los datos de la imagen descargada

    return (
        <View style={styles.tableBodyPag3} wrap={false}>
            {/* CONTENEDOR TITULO FUNCIONARIOS EM DESPLAZAMIENTO*/}
            <View style={styles.contenedorTitulo} >
                <Text style={styles.fontTitulo}>Documentos Anexos</Text>
            </View>
            <View style={styles.filaDeCeldas2}>
                <Image style={styles.image} src={imagenes.imagenPrueba1} />
            </View>
            <View style={styles.filaDeCeldas2}>
                <Image style={styles.documentosAnexos} src={imagenes.imagenPrueba2} />
            </View>

        </View>
    )
}
export default BodyPag3