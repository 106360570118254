import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './contexts/authContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
)

/* 

<HashRouter>
    <Routes>
      <Route path='/' element={<LoginEmailView />} />
      <Route path='/signout' element={<Signout />} />
      <Route path='/risk-assessment' element={<RiskAssessment />} />
      <Route path='/admin/client/search-client' element={<SearchClientView />} />
      <Route path='/admin/client/add-client' element={<NewClienteView />} />
      <Route path='/admin/client/search-client/:id' element={<ClientDetails />} />
      <Route path='/admin/contactsFFP/add-contactFFP' element={<NewContactFFPView />} />
      <Route path='/admin/functionary/add-functionary' element={<NewFunctionaryView />} />
      <Route path='/admin/functionary/search-functionary' element={<SearchFunctionaryView />} />
      <Route path='/admin/functionary/search-functionary/:id' element={<FunctionaryDetails />} />
      <Route path='/admin/users/add-users' element={<UsersView />} />
      <Route path='/solicitude/add-solicitude' element={<NewSolicitudeView />} />
      <Route path='/solicitude/tracking-solicitude' element={<MapsRegister />} />
      <Route path='/solicitude/search-solicitude' element={<SearchSolicitudes />} />
      <Route path='solicitude/search-solicitude/:id' element={<SolicitudeDetails />} />
      <Route path='solicitude/search-solicitude/:id/pdf-viewer' element={<PdfViewer />} />
      <Route path='/admin/contactsFFP/search-contactFFP' element={<SearchContactFFPView />} />
    </Routes>
  </HashRouter>

 */