import { useState, useEffect, useMemo } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import style from "./cssModules/search.module.css";
import { searchSolicitudeData } from "../firebase/firebase";
import TablaDatos from "../components/dataTable";
import ExcelExport from "../components/excelFiles/ExcelExport";

export default function SearchSolicitudes() {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const keys = ["client", "functionaries", "risk", "diaryRoute"];
  const [fechaQuery, setFechaQuery] = useState("timeStamp");
  const [stateSearch, setStateSearch] = useState("Todos");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;

  const memoizedSearchSolicitudeData = useMemo(() => {
    return searchSolicitudeData(stateSearch);
  }, [stateSearch]);

  useEffect(() => {
    memoizedSearchSolicitudeData
      .then((res) => {
        setData(res);
      })
      .catch((error) => console.error(error));
  }, [memoizedSearchSolicitudeData]);

  const search = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const filteredResults = data.filter((item) => {
      const valueMatchesQuery = keys.some((key) => {
        const value = item[key];
        if (Array.isArray(value)) {
          return value.some(
            (element) =>
              typeof element === "string" &&
              element.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return (
            typeof value === "string" &&
            value.toLowerCase().includes(query.toLowerCase())
          );
        }
      });

      const selectedDate =
        fechaQuery === "initialDate"
          ? item.initialDate
          : fechaQuery === "finalDate"
          ? item.finalDate
          : item.timeStamp;

      const timeStampDate =
        selectedDate && selectedDate.seconds
          ? new Date(selectedDate.seconds * 1000)
          : null;

      let dateFilterResult = true;

      if (startDate && endDate && timeStampDate) {
        dateFilterResult =
          timeStampDate >= new Date(startDate) &&
          timeStampDate <= new Date(endDate);
      }
      if (stateSearch === "Todos") {
        return (
          (item.state === "Nuevo" ||
            item.state === "En proceso" ||
            item.state === "") &&
          valueMatchesQuery &&
          dateFilterResult
        );
      } else {
        return (
          item.state === stateSearch && dateFilterResult && valueMatchesQuery
        );
      }
    });
    const dataWithLatestDates = filteredResults.map((list) => {
      const sortedDatosAdicionales = [...(list.datosAdicionales || [])].sort(
        (a, b) =>
          (b.timeStamp?.toMillis() || 0) - (a.timeStamp?.toMillis() || 0)
      );

      const objetoMasAntiguo = sortedDatosAdicionales[0];

      return {
        ...list,
        latestNotificationDateAndHour: objetoMasAntiguo
          ? objetoMasAntiguo.notificationDateAndHour?.toMillis() || 0
          : list.notificationDateAndHour?.toMillis() || 0, // Usar la fecha de la solicitud si no hay datos adicionales
        hasDatosAdicionales:
          list.datosAdicionales && list.datosAdicionales.length > 0,
      };
    });
    return dataWithLatestDates;
  };

  function handlePreviousPage() {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(data.length / itemsPerPage))
    );
  }

  function handleSearch(e) {
    const valor = e.target.value;
    if (valor.startsWith(" ")) return;
    setQuery(valor);
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <form action="">
            <div>
              <div>
                <h1>Busqueda de gerenciamientos de viaje</h1>
              </div>
              <div>
                <div className={style.container}>
                  <div className={style.secondaryContainer}>
                    <label htmlFor="cliente">
                      Buscar cliente/funcionario/riesgo/ruta del día
                    </label>
                    <input
                      type="text"
                      className="input"
                      onChange={handleSearch}
                      value={query}
                    />
                  </div>
                </div>
                <div className="fechas">
                  <div className={style.displayGrid}>
                    <div className={style.secondaryContainer}>
                      <label className="fechaInicio">Fecha inicio </label>
                      <input
                        type="date"
                        name="fechaInicio"
                        id="fechaInicio"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                      />
                    </div>
                    <div className={style.secondaryContainer}>
                      <label htmlFor="fechaFin">Fecha fin </label>
                      <input
                        type="date"
                        name="fechaFin"
                        id="fechaFin"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                      />
                    </div>
                  </div>
                  <div className={style.displayGrid}>
                    <div className={style.secondaryContainer}>
                      <label htmlFor="">Filtro por fecha </label>
                      <select
                        name=""
                        id=""
                        onChange={(e) => setFechaQuery(e.target.value)}
                        value={fechaQuery}
                      >
                        <option value="timeStamp">Fecha de creación</option>
                        <option value="initialDate">Fecha de inicio</option>
                        <option value="finalDate">Fecha de fin</option>
                      </select>
                    </div>
                    <div className={style.secondaryContainer}>
                      <label htmlFor="">Estado </label>
                      <select
                        name=""
                        id=""
                        onChange={(e) => setStateSearch(e.target.value)}
                        value={stateSearch}
                      >
                        <option value={"Todos"}>Todos</option>
                        <option value={"Nuevo"}>Nuevo</option>
                        <option value={"En proceso"}>En proceso</option>
                        <option value={"Finalizado"}>Finalizado</option>
                        <option value={"Cancelado"}>Cancelado</option>
                      </select>
                    </div>
                  </div>
                  <div className={style.containerExcelTabla}>
                    <ExcelExport sortedData={search()} />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className={style.containerTable}>
            <TablaDatos
              sortedData={search()}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
            ></TablaDatos>
          </div>
          <div className={style.pagination}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span>
              Página {currentPage} de{" "}
              {Math.ceil(search().length / itemsPerPage)}
            </span>
            <button
              onClick={handleNextPage}
              disabled={
                currentPage === Math.ceil(search().length / itemsPerPage)
              }
            >
              Siguiente
            </button>
          </div>
        </div>
      </Clock>
    </DashboardWrapper>
  );
}
