import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import DashboardWrapper from "../components/dashboardWrapper"
import Clock from "../components/clock"
import style from "./cssModules/newClientView.module.css"
import { collection, serverTimestamp, getDocs } from "firebase/firestore"
import { db, createNewUser2, insertNewUser2 } from "../firebase/firebase"
import { v4 as uuidv4 } from "uuid"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthContext } from "../contexts/authContext"

const UsersView = () => {
  const navigate = useNavigate()
  const { user, isLoading } = useAuthContext()
  const fechaActual = new Date()
  const day = fechaActual.getDate()
  const month = fechaActual.getMonth() + 1
  const year = fechaActual.getFullYear()
  let time = new Date().toLocaleTimeString()
  let fechaActualizada = `${year}/${month}/${day}`
  const [nombre, setNombre] = useState('')
  const [correo, setCorreo] = useState('')
  const [clientes, setClientes] = useState([])
  const [categoria, setCategoria] = useState('')
  const [data, setData] = useState([])
  const [statusMessage, setStatusMessage] = useState('Activo')
  const [celular, setCelular] = useState('')
  const [botonHabilitado, setBotonHabilitado] = useState(false)
  const [password, setPassword] = useState("")

  const notify = () => {
    toast.success("Cliente creado satisfactoriamente", {
      theme: "colored"
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      let list = []
      try {
        const querySnapshot = await getDocs(collection(db, "users"))
        querySnapshot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() })
        })
        setData(list)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const verificarCamposLlenos = () => {
        if (
            nombre !== "" &&
            celular !== "" &&
            categoria !== "" &&
            correo !== ""
        ) {
            setBotonHabilitado(true);
        } else {
            setBotonHabilitado(false);
        }
    };
    verificarCamposLlenos();
}, [
    nombre,
    celular,
    correo,
    categoria
])

  async function handleOnSubmit(e) {
    e.preventDefault()
    addInformation()
    setTimeout(() => {
      navigate("/")
    }, 1000)
  }
  
  function handleOnChangeInputs(e) {
    const valor = e.target.value
    if (valor.startsWith(" ")) return
    if (e.target.name === "nombre") {
      const mayusculas = valor.toUpperCase()
      setNombre(mayusculas)
    }
    if (e.target.name === "correo") {
      setCorreo(valor)
    }
    if (e.target.name === "celular") {
      setCelular(valor)
    }
    if (e.target.name === "password") {
      setPassword(valor)
    }
  }

  async function addInformation() {
    try {
      if (nombre !== '' && correo !== '' && categoria !== "" && password !== "") {
        const newUserInfo = {
          name: nombre.toUpperCase(),
          email: correo,
          cellphone: celular,
          category: categoria,
          password,
          actualDate: fechaActualizada,
          actualTime: time,
          timeStamp: serverTimestamp(),
          state: statusMessage,
          uid: user.uid,
          id: uuidv4()
        }
        const res = await createNewUser2(newUserInfo)
        res && await insertNewUser2(res, newUserInfo)
        setNombre("")
        setCorreo("")
        setClientes([...clientes, newUserInfo])
        notify()
      } else {
        alert("Ingrese nombre y telefono del cliente")
      }
    } catch (error) {
      console.error(error)
    }

  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <h1>Agregar usuario</h1>
          <form className={style.entryContainer} action="" onSubmit={handleOnSubmit}>
            <label htmlFor="nombre">Nombre</label>
            <input autoComplete="off" className="input" type="text" name="nombre" onChange={handleOnChangeInputs} value={nombre} />
            <label htmlFor="telefono">Telefono</label>
            <input autoComplete="off" className="input" type="text" name="celular" onChange={handleOnChangeInputs} value={celular} />
            <label htmlFor="category">Categoria</label>
            <select className="section" id="category" onChange={(e) => setCategoria(e.target.value)}>
              <option value=""></option>
              <option value="Administrador">Administrador</option>
              <option value="Operador">Operador</option>
              <option value="Cliente">Cliente</option>
            </select>
            <label htmlFor="correo">Correo</label>
            <input autoComplete="off" className="input" type="text" name="correo" onChange={handleOnChangeInputs} value={correo} />
            <label htmlFor="">Contraseña</label>
            <input autoComplete="off" className="input" type="password" name="password" onChange={handleOnChangeInputs} value={password} />
            <ToastContainer position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light" />
            <input className="btn" type="submit" value="Crear nuevo usuario" disabled={!botonHabilitado}/>
          </form>
          {/* <AgendaSolicitudes></AgendaSolicitudes> */}
          <div>
            <ol>
              {
                data.map((usuario) => (
                  <li key={usuario.id}>
                    <h2>{usuario.displayName}</h2>
                    <p>{usuario.category}</p>
                    <p>Fecha de creación: <time>{usuario.actualDate}</time> - <time>{usuario.actualTime}</time> </p>
                  </li>
                ))
              }
            </ol>
          </div>
        </div>
      </Clock>
    </DashboardWrapper>

  )
}

export default UsersView
