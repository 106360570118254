import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase";
const styles = StyleSheet.create({
    tableBodyPag2: {
        width: '87%',
        minHeight: '128px',
        marginRight: 36,
        flexDirection: 'column',
        borderStyle: 'solid',
        borderTop: 2
    },
    contenedorTitulo: {
        height: '29px',
        width: '100%',
        borderStyle: 'solid',
        borderBottom: 2,
        borderLeft: 2,
        borderRight: 2,
        backgroundColor: '#E7E6E6',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    filaDeCeldas: {
        width: '100%',
        height: '29px',
        flexDirection: 'row',
        borderStyle: 'solid',
        borderBottom: 2
    },
    tipoCelda2: {
        width: '30%',
        height: '29px',
        borderStyle: 'solid',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderRight: 2
    },
    tipoCelda3: {
        width: '70%',
        height: '29px',
        borderStyle: 'solid',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        borderLeft: 2
    },
    fontTitulo: {
        fontSize: 14,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        letterSpacing: 2
    },
    fontSubTitulo: {
        fontSize: 10,
        textAlign: 'center',
        fontFamily: 'Helvetica-Bold',
        letterSpacing: 1
    },
    fontCampoCelda: {
        fontSize: 10,
        textAlign: 'center',
        fontFamily: 'Helvetica',
        letterSpacing: 1
    }
})


const BodyPag2 = ({ data, dataDiary }) => {
    const [dataRecibida, setDataRecibida] = useState(data)
    const [dataFuncionarios, setDataFuncionarios] = useState([])

    useEffect(() => {
        const fetchFuncionarios = async () => {
          try {
            // Obtén los datos de la colección "functionaries" y guárdalos en el estado
            const querySnapshot = await getDocs(collection(db, 'functionaries'));
            const funcionariosData = querySnapshot.docs.map((doc) => doc.data());
            setDataFuncionarios(funcionariosData);
          } catch (error) {
            console.error(error);
          }
        };
        fetchFuncionarios();
      }, []);
    
      const getContactByFuncionarioName = (funcionarioName) => {
        const foundFuncionario = dataFuncionarios.find((funcionario) => funcionario.name.toUpperCase() === funcionarioName);
        return foundFuncionario ? foundFuncionario.phone : null;
      };
    
      if (!dataFuncionarios || dataFuncionarios.length === 0) {
        return <Text>Cargando datos...</Text>; // Puedes mostrar un mensaje de carga mientras los datos se están recuperando
      }

    return (
        <View style={styles.tableBodyPag2} wrap={false}>
            {/* CONTENEDOR TITULO FUNCIONARIOS EM DESPLAZAMIENTO*/}
            <View style={styles.contenedorTitulo}>
                <Text style={styles.fontTitulo}>Funcionarios en Desplazamiento</Text>
            </View>
            {/* FILA 1 DE FUNCIONARIOS EM DESPLAZAMIENTO */}
            <View style={styles.filaDeCeldas}>
                <View style={[styles.tipoCelda3]}>
                    <Text style={styles.fontSubTitulo}>Nombre del Funcionario</Text>
                </View>

                <View style={[styles.tipoCelda2, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                    <Text style={styles.fontSubTitulo}>Telefono de Contacto</Text>
                </View>
            </View>
            {/* FILA DE FUNCIONARIOS EN DESPLAZAMIENTO */}
            {dataRecibida.functionaries.map((funcionario, index) => (
                <View key={index} style={styles.filaDeCeldas}>
                    {/* Celda 1: Muestra el valor del funcionario */}
                    <View style={[styles.tipoCelda3]}>
                        <Text style={styles.fontCampoCelda}>{funcionario}</Text>
                    </View>
                    {/* Celda 2: Contiene el valor "333333333333" */}
                    <View style={[styles.tipoCelda2, { borderStyle: 'solid' }, { borderLeft: 1 }]}>
                        <Text style={styles.fontCampoCelda}>{getContactByFuncionarioName(funcionario) || 'Número no encontrado'}</Text>
                    </View>
                </View>
            ))}
        </View >
    )
}
export default BodyPag2