import {Text, View,Image,StyleSheet  } from "@react-pdf/renderer";
import imagenes from "../../../resources/images/imagenes";

// Crear estilos
const styles = StyleSheet.create({
    table:{
        width:'88%',
        height: 72,
        marginRight:36,
        marginBottom:18,
        flexDirection:'row'
    },
    celdasExterna1:{
        width:'28%',
        height:'100%',
        borderStyle:'solid',
        borderWidth:2,
        borderBottom:2,
        borderRight:2,
        borderLeft:2,
        alignItems:'center',
        justifyContent:'center'
    },
    celdaCentral:{
        width:'41%',
        height:'100%',
        borderStyle:'solid',
        borderWidth:2,
        borderBottom:2,
        borderRight:2,
        borderLeft:2,
        marginRight:'1%',
        marginLeft:'1%',
        display: 'flex',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center'
    },
    celdasExterna2:{
        width:'28%',
        height:'100%',
        borderStyle:'solid',
        borderWidth:2,
        borderBottom:2,
        borderRight:2,
        borderLeft:2
    },
    celdasInterna:{
        alignContent:'center',
        alignItems:'center',
        justifyContent:'center',
        letterSpacing:'1px',
        width:'100%',
        height:'50%'
    },
    imgLogo:{
        margin:'7px',
        width:100,
    }

});

const diaDeSolicitud = () => {
    const date = new Date();
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};


const HeaderPdf =()=>{
    return(
        <View style={styles.table} fixed>
            {/* CELDA 1 DEL HEADER */}
            <View style={styles.celdasExterna1}>
                <Text>Informe</Text>
                <Text>Evaluación</Text>
                <Text>Gerenciamiento de Viaje</Text>
            </View>
            {/* CELDA 2 CONTENEDOR LOGO */}
            <View style={styles.celdaCentral}>
                <Image src={imagenes.img1} alt='' style={styles.imgLogo}/>
            </View>
            {/* CELDA TRES FECHA Y NOMBRE DEL APLICATIVO */}
            <View style={styles.celdasExterna2}>
                <View style={[styles.celdasInterna, {borderStyle:'solid'}, {borderBottom:1}]}>
                    <Text>{diaDeSolicitud()}</Text>
                </View>
                <View style={[styles.celdasInterna,{letterSpacing:'3px'}]}>
                    <Text>SISMOPI</Text>
                </View>
            </View>
        </View>
    )
}
export default HeaderPdf