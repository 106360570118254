import { Link } from "react-router-dom";
import style from "./cssModules/dashboardWrapper.module.css";
import { useState } from "react";
import DropDown from "./dropDown";
import imagenes from "../resources/images/imagenes";
import { useAuthContext } from "../contexts/authContext";

export default function DashboardWrapper({ children }) {
  const [listaDesplegable, setListaDesplegable] = useState(false);
  const { user } = useAuthContext();

  return (
    <div>
      <nav className={style.nav}>
        <img src={imagenes.img1} alt="Pilgrim" className={style.logo} />
        {user?.rol === "admin" || user?.rol === "operador" ? (
          <>
            <div
              onMouseEnter={() => setListaDesplegable(true)}
              onMouseLeave={() => setListaDesplegable(false)}
            >
              <Link to="#">Administrador</Link>
              {listaDesplegable && (
                <DropDown
                  closeDropDown={() => setListaDesplegable(false)}
                ></DropDown>
              )}
            </div>
            <Link to="/solicitude/add-solicitude">Gerenciamiento</Link>
            <Link to="/solicitude/search-solicitude">Seguimiento</Link>
            <Link to="/risk-assessment">Evaluacion de riesgos</Link>
            <Link to="/solicitude/tracking-solicitude">Tracking maps</Link>
            <Link to="/signout" className={style.div}>
              Sign out
            </Link>
          </>
        ) : (
          <>
            <Link to="/client/search-client-page">Search page</Link>
            <Link to="/client/tracking-solicitude">Tracking maps</Link>
            <Link to="/client/risk-assessment">Risk Assessment</Link>
            <Link to="/signout" className={style.div}>
              Sign out
            </Link>
          </>
        )}
      </nav>

      <div className="main-container">{children}</div>
    </div>
  );
}
