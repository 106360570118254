import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  query,
  where,
  setDoc,
  deleteDoc,
  updateDoc,
  collectionGroup,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExists(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  return res.exists();
}

//logout

export async function logout() {
  await auth.signOut();
}

//Clientes

export async function insertNewClient(client) {
  try {
    const docRef = collection(db, "client");
    const res = await addDoc(docRef, client);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function getClientes() {
  const clientes = [];
  try {
    const collectionRef = collection(db, "client");
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      const cliente = { ...doc.data() };
      cliente.docId = doc.id;
      clientes.push(cliente);
    });
    return clientes;
  } catch (error) {
    console.error(error);
  }
}

export async function getClient(id) {
  const q = query(collection(db, "client"), where("id", "==", id));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0];
    return { id: document.id, ...document.data() };
  } else {
    return console.log("No hay nada");
  }
}

export async function updateCliente(id, cliente) {
  const clientRef = doc(db, "client", id);
  try {
    const res = await updateDoc(clientRef, cliente);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteCliente(id) {
  try {
    const docRef = doc(db, "client", id);
    const res = await deleteDoc(docRef);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDocIdClient(res) {
  const docRef = doc(db, "client", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//Funcionarios

export async function getFunctionaries() {
  const functionaries = [];
  try {
    const collectionRef = collection(db, "functionaries");
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      const funcionary = { ...doc.data() };
      funcionary.docId = doc.id;
      functionaries.push(funcionary);
    });
    return functionaries;
  } catch (error) {
    console.error(error);
  }
}

export async function updateFuncionario(id, funcionario) {
  try {
    const docRef = doc(db, "functionaries", id);
    const res = await updateDoc(docRef, funcionario);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteFuncionario(id) {
  try {
    const docRef = doc(db, "functionaries", id);
    const res = await deleteDoc(docRef);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function getFunctionary(id) {
  const q = query(collection(db, "functionaries"), where("id", "==", id));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0];
    return { id: document.id, ...document.data() };
  } else {
    console.log("No hay nada");
  }
}

export async function insertNewFunctionary(functionary) {
  try {
    const docRef = collection(db, "functionaries");
    const res = await addDoc(docRef, functionary);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function updateDocIdFunctionary(res) {
  const docRef = doc(db, "functionaries", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//ContactosFFP

export async function insertNewContactFFP(contact) {
  try {
    const docRef = collection(db, "contactFFP");
    const res = await addDoc(docRef, contact);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function getContactosFFP() {
  const contactos = [];
  try {
    const collectionRef = collection(db, "contactFFP");
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      const contacto = { ...doc.data() };
      contacto.docId = doc.id;
      contactos.push(contacto);
    });
    return contactos;
  } catch (error) {
    console.error(error);
  }
}

export async function updateContactoFFP(id, contactoFFP) {
  try {
    const docRef = doc(db, "contactFFP", id);
    const res = await updateDoc(docRef, contactoFFP);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteContactoFFP(id) {
  try {
    const docRef = doc(db, "contactFFP", id);
    const res = await deleteDoc(docRef);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDocIdContactFFP(res) {
  const docRef = doc(db, "contactFFP", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//Solicitudes

export async function insertNewSolicitude(solicitude) {
  try {
    const docRef = collection(db, "solicitudes");
    const res = await addDoc(docRef, solicitude);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export const getSolicitude = async (id) => {
  const q = query(collection(db, "solicitudes"), where("id", "==", id));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const document = querySnapshot.docs[0];
    return { id: document.id, ...document.data() };
  } else {
    console.log("No hay nada");
  }
};

export async function getSolicitudes() {
  const solicitudes = [];
  try {
    const collectionRef = collection(db, "solicitudes");
    const querySnapshot = await getDocs(collectionRef);
    querySnapshot.forEach((doc) => {
      const solicitude = { ...doc.data() };
      solicitudes.push(solicitude);
    });
    return solicitudes;
  } catch (error) {
    console.error(error);
  }
}

export async function updateSolicitude(id, solicitude) {
  try {
    const docRef = doc(db, "solicitudes", id);
    const res = await updateDoc(docRef, solicitude);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteSolicitude(id) {
  try {
    const docRef = doc(db, "solicitudes", id);
    const res = await deleteDoc(docRef);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDocIdSolicitudes(res) {
  const docRef = doc(db, "solicitudes", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//Usuarios

export async function createNewUser(userInfo) {
  try {
    await createUserWithEmailAndPassword(
      auth,
      userInfo.email,
      userInfo.password
    ).then((usuario) => usuario);
  } catch (error) {
    console.error(error);
  }
}

export async function insertNewUser(document, userInfo) {
  try {
    const docRef = collection(db, `user/${document.user.uid}`);
    const res = await setDoc(docRef, userInfo);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function createNewUser2(userInfo) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userInfo.email,
      userInfo.password
    );
    return userCredential.user;
  } catch (error) {
    console.error(error);
    throw error; // Puedes lanzar el error para manejarlo en el componente que llama a esta función.
  }
}

export async function insertNewUser2(userInfo) {
  try {
    const docRef = collection(db, "users"); // Reemplaza 'usuarios' con el nombre de tu colección
    const res = await setDoc(docRef, userInfo);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateDocIdUser(res) {
  const docRef = doc(db, "user", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//Puntos de control

export async function insertNewDiary(diary) {
  try {
    const docRef = collection(db, "diary");
    const res = await addDoc(docRef, diary);
    return res;
  } catch (error) {
    console.log(error);
  }
}

export async function insertNewDiarySubcolection(id, diary) {
  try {
    const docRef = doc(db, "solicitudes", id);
    const diaryRef = collection(docRef, "diary");
    const res = await addDoc(diaryRef, diary);
    console.log("El nuevo diario se ha creado con el id:", res.id);
    return res;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDocIdDiarySubcolection(id, docId) {
  const diaryRef = doc(db, "solicitudes", id, "diary", docId);
  try {
    await updateDoc(diaryRef, {
      id: docId,
    });
  } catch (error) {
    console.error(
      "Error al actualizar el ID de documento en la subcolección diary:",
      error
    );
  }
}

export async function getDiary(id) {
  const diaries = [];
  try {
    const q = collection(db, `solicitudes/${id}/diary`);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      diaries.push({ id: doc.id, ...doc.data() });
    });
    return diaries;
  } catch (error) {
    console.error(error);
  }
}

export async function updateDiary(id, idSolicitude, diary) {
  try {
    const docRef = doc(db, "solicitudes", idSolicitude, "diary", id);
    const res = await updateDoc(docRef, diary);
    return res;
  } catch (error) {
    console.error(error);
  }
}

/* export async function updateDiarySubcollection(id, docId, diary) {
  const diaryRef = doc(db, 'solicitudes', id, "diary", docId)
  try {
    await updateDoc(diaryRef, diary);
    console.log("ID de documento actualizado correctamente en la subcolección diary.");
  } catch (error) {
    console.error("Error al actualizar el ID de documento en la subcolección diary:", error);
  }
} */

export async function updateDocIdDiary(res) {
  const docRef = doc(db, "diary", res.id);
  await updateDoc(docRef, {
    id: res.id,
  });
}

//data searchSolicitude

export async function searchSolicitudeData(estado) {
  let list = [];
  let state = ["Nuevo", "En proceso"];
  switch (estado) {
    case "Nuevo":
      state = ["Nuevo"];
      break;
    case "En proceso":
      state = ["En proceso"];
      break;
    case "Finalizado":
      state = ["Finalizado"];
      break;
    case "Cancelado":
      state = ["Cancelado"];
      break;
    default:
  }
  // Utilizamos collectionGroup() para obtener todos los documentos de "diary" en todas las solicitudes
  const subCollectionSnapshot = await getDocs(collectionGroup(db, "diary"));
  // Creamos un mapa para agrupar los datos de "diary" por su ID de solicitud
  const diaryDataMap = new Map();
  subCollectionSnapshot.forEach((subDoc) => {
    const data = subDoc.data();
    const solicitudId = data.idSolicitude;
    if (!diaryDataMap.has(solicitudId)) {
      diaryDataMap.set(solicitudId, []);
    }
    diaryDataMap.get(solicitudId).push(data);
  });
  // Obtenemos los datos de las solicitudes
  /* const querySnapshot = await getDocs(collection(db, "solicitudes")); */
  const q = query(collection(db, "solicitudes"), where("state", "in", state));
  const querySnapshot = await getDocs(q);
  for (const doc of querySnapshot.docs) {
    const solicitudData = doc.data();
    const solicitudId = doc.id;

    // Obtenemos los datos de "diary" asociados a la solicitud actual
    const datosAdicionalesData = diaryDataMap.get(solicitudId) || [];

    // Combinamos los datos de la colección principal con los datos de la subcolección
    const combinedUserData = {
      id: solicitudId,
      ...solicitudData,
      datosAdicionales: datosAdicionalesData,
    };
    list.push(combinedUserData);
  }
  // Sort the data by `notificationDateAndHour`
  list.sort((a, b) => {
    const dateA = a.datosAdicionales.timeStamp;
    const dateB = b.datosAdicionales.timeStamp;
    return new Date(dateB) - new Date(dateA); // Sort in descending order
  });
  return await list;
}

//seleccion del mapa dependiendo del rol

export async function obtenerDatosDeSeguimiento(rol, cliente) {
  if (rol === "admin" || rol === "operador") {
    console.log("getDataMapsTracking");
    return getDataMapsTracking();
  } else if (rol === "cliente") {
    console.log("getDataDiaryMapsTracking");
    return getDataDiaryMapsTracking(cliente);
  } else {
    throw new Error("Rol no válido");
  }
}

//data maps tracking admin and operator

export async function getDataMapsTracking() {
  const querySnapshot = await getDocs(collection(db, "solicitudes"));
  const solicitudes = querySnapshot.docs.map((doc) => doc.data());
  const solicitudesEnProceso = solicitudes.filter(
    (solicitud) => solicitud.state === "En proceso"
  );
  const promises = solicitudesEnProceso.map(async (solicitud, index) => {
    const diaryRef = collection(db, "solicitudes", solicitud.id, "diary");
    const diaryQuerySnapshot = await getDocs(diaryRef);
    const diaryList = diaryQuerySnapshot.docs.map((diaryDoc) =>
      diaryDoc.data()
    );

    if (diaryList.length === 0) {
      return null; // Si no hay diarios, retornamos null para que no se agregue al array de resultados.
    }

    const sortedDiary = diaryList.sort(
      (a, b) => b.timeStamp.seconds - a.timeStamp.seconds
    );

    const latestLocation = sortedDiary[0]?.location;

    return {
      idSolicitude: solicitud?.id,
      client: solicitud?.client,
      lat: latestLocation?.geometry?.location?.lat || 0,
      lng: latestLocation?.geometry?.location?.lng || 0,
      name: latestLocation?.name,
      key: index,
    };
  });

  const locations = (await Promise.all(promises)).filter(Boolean); // Filtramos los valores nulos del array de resultados.
  return locations;
}

//data maps tracking cliente

export async function getDataDiaryMapsTracking(cliente) {
  const solicitudesQuery = query(
    collection(db, "solicitudes"),
    where("state", "in", ["En proceso", "Nuevo"])
  );
  const querySnapshot = await getDocs(solicitudesQuery);

  const promises = querySnapshot.docs.map(async (doc, index) => {
    const solicitud = doc.data();

    if (solicitud.client.toUpperCase() === cliente.toUpperCase()) {
      const diaryRef = collection(db, "solicitudes", doc.id, "diary");
      const diaryQuerySnapshot = await getDocs(
        query(
          diaryRef,
          orderBy("timeStamp", "desc"), // Ordenar por timeStamp en orden descendente
          limit(1) // Limitar a un solo resultado (el último diario)
        )
      );

      const latestDiary = diaryQuerySnapshot.docs[0]?.data();

      if (!latestDiary) {
        return null; // Si no hay diarios, retornamos null para que no se agregue al array de resultados.
      }

      const latestLocation = latestDiary.location;

      return {
        idSolicitude: doc.id,
        client: solicitud.client,
        lat: latestLocation?.geometry?.location?.lat || 0,
        lng: latestLocation?.geometry?.location?.lng || 0,
        name: latestLocation?.name,
        key: index,
        latestDiary: latestDiary, // Agregamos el último diario a la respuesta
      };
    } else {
      return null;
    }
  });

  const locations = (await Promise.all(promises)).filter(Boolean); // Filtramos los valores nulos del array de resultados.
  return locations;
}

// get client solicitude

export async function getSolicitudesCliente(cliente, estado) {
  let state = ["Nuevo", "En proceso"];
  switch (estado) {
    case "Nuevo":
      state = ["Nuevo"];
      break;
    case "En proceso":
      state = ["En proceso"];
      break;
    case "Finalizado":
      state = ["Finalizado"];
      break;
    case "Cancelado":
      state = ["Cancelado"];
      break;
    default:
      console.log(state);
  }

  const solicitudesCliente = [];

  try {
    const collectionRef = collection(db, "solicitudes");
    const q = query(
      collectionRef,
      where("client", "==", cliente),
      where("state", "in", state)
    );

    const querySnapshot = await getDocs(q);

    for (const doc of querySnapshot.docs) {
      const solicitudData = doc.data();
      const solicitudId = doc.id;

      // Obtener diarios asociados a la solicitud actual utilizando collectionGroup
      const diariosCollectionRef = collectionGroup(db, "diary");
      const diariosQuery = query(
        diariosCollectionRef,
        where("idSolicitude", "==", solicitudId)
      );

      const diariosSnapshot = await getDocs(diariosQuery);

      const diarios = diariosSnapshot.docs.map((diarioDoc) => ({
        ...diarioDoc.data(),
        id: diarioDoc.id,
      }));

      // Combinar los datos de la colección principal con los datos de la subcolección
      const combinedUserData = {
        id: solicitudId,
        ...solicitudData,
        datosAdicionales: diarios,
      };

      solicitudesCliente.push(combinedUserData);
    }

    return solicitudesCliente;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

//Prototipo de search con paginacion

/* export async function getSolicitudes(
  cliente,
  estado,
  pageSize = 50,
  startAfterDoc = null
) {
  let state = ["Nuevo", "En proceso"];
  switch (estado) {
    case "Nuevo":
      state = ["Nuevo"];
      break;
    case "En proceso":
      state = ["En proceso"];
      break;
    case "Finalizado":
      state = ["Finalizado"];
      break;
    case "Cancelado":
      state = ["Cancelado"];
      break;
    default:
      console.log(state);
  }

  const solicitudesCliente = [];

  try {
    // Construir la consulta para las solicitudes
    const solicitudesQuery = query(
      collection(db, "solicitudes"),
      where("state", "in", state)
    );

    // Si hay una referencia al último documento, usar startAfter para paginación
    if (startAfterDoc) {
      solicitudesQuery = startAfter(solicitudesQuery, startAfterDoc);
    }

    // Limitar la cantidad de documentos a recuperar por página
    const solicitudesSnapshot = await getDocs(
      query(solicitudesQuery, limit(pageSize))
    );

    for (const doc of solicitudesSnapshot.docs) {
      const solicitudData = doc.data();
      const solicitudId = doc.id;

      // Obtener diarios asociados a la solicitud actual utilizando collectionGroup
      const diariosCollectionRef = collectionGroup(db, "diary");
      const diariosQuery = query(
        diariosCollectionRef,
        where("idSolicitude", "==", solicitudId)
      );

      const diariosSnapshot = await getDocs(diariosQuery);

      const diarios = diariosSnapshot.docs.map((diarioDoc) => ({
        ...diarioDoc.data(),
        id: diarioDoc.id,
      }));

      // Combinar los datos de la colección principal con los datos de la subcolección
      const combinedUserData = {
        id: solicitudId,
        ...solicitudData,
        datosAdicionales: diarios,
      };

      solicitudesCliente.push(combinedUserData);
    }

    // Devolver la última referencia para la paginación
    const lastDoc =
      solicitudesSnapshot.docs[solicitudesSnapshot.docs.length - 1];

    return { data: solicitudesCliente, lastDoc };
  } catch (error) {
    console.error(error);
    throw error;
  }
} */
