import LogoPilgrim from "./LogoPilgrim.jpg";
import AlertaCritica from "./alerta-critica.svg";
import AlertaAlta from "./alerta-alta.svg";
import AlertaMedia from "./alerta-media.svg";
import AlertaBaja from "./alerta-baja.svg";
import AlertaBajaJPG from "./alerta-baja-JPG.jpeg";
import AlertaMediaJPG from "./alerta-media-JPG.jpeg";
import AlertaAltaJPG from "./alerta-alta-JPG.jpg";
import AlertaCriticaJPG from "./alerta-critica-JPG.jpeg";
import ImagenBackground from "./4931043.jpg";
import imagenPrueba1 from "./imgGerenciamiento1.jpeg";
import imagenPrueba2 from "./imgGerenciamiento2.jpeg";
import imagenDeRiesgo from "./imagen-riesgo.png";
import fondoPilgrim from "./fondo-pilgrim.png";
import check from "./comprobado.png";

const imagenes = {
  img1: LogoPilgrim,
  img2: AlertaCritica,
  img3: AlertaAlta,
  img4: AlertaMedia,
  img5: AlertaBaja,
  img6: ImagenBackground,
  imagenPrueba1,
  imagenPrueba2,
  AlertaBajaJPG,
  AlertaMediaJPG,
  AlertaAltaJPG,
  AlertaCriticaJPG,
  imagenDeRiesgo,
  fondoPilgrim,
  check,
};

export default imagenes;
