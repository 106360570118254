import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import style from "./cssModules/newClientView.module.css";
import { serverTimestamp } from "firebase/firestore";
import {
  getClientes,
  insertNewFunctionary,
  updateDocIdFunctionary,
} from "../firebase/firebase";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthContext } from "../contexts/authContext";

export default function NewFunctionaryView() {
  const navigate = useNavigate();
  const { user, isLoading } = useAuthContext();
  const fechaActual = new Date();
  const day = fechaActual.getDate();
  const month = fechaActual.getMonth() + 1;
  const year = fechaActual.getFullYear();
  let time = new Date().toLocaleTimeString();
  let fechaActualizada = `${year}/${month}/${day}`;
  const [nombre, setNombre] = useState("");
  const [cliente, setCliente] = useState("");
  const [contacto, setContacto] = useState("");
  const [numeroCelular, setNumeroCelular] = useState("");
  const [funcionarios, setFuncionarios] = useState([]);
  const [dataClientes, setDataClientes] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [botonHabilitado, setBotonHabilitado] = useState(false);

  const notifySuccess = () => {
    toast.success("Contacto creado satisfactoriamente", {
      theme: "colored",
    });
  };

  const notifySuccessEdit = () => {
    toast.success("Editado satisfactoriamente", {
      theme: "colored",
    });
  };

  useEffect(() => {
    getClientes()
      .then((resClientes) => setDataClientes(resClientes))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (isActive) {
      setStatusMessage("Activo");
    } else {
      setStatusMessage("Inactivo");
    }
  }, [isActive]);

  useEffect(() => {
    const verificarCamposLlenos = () => {
      if (
        nombre !== "" &&
        numeroCelular !== "" &&
        contacto !== "" &&
        cliente !== ""
      ) {
        setBotonHabilitado(true);
      } else {
        setBotonHabilitado(false);
      }
    };
    verificarCamposLlenos();
  }, [nombre, numeroCelular, cliente, contacto]);

  function handleOnChangeInputs(e) {
    const valor = e.target.value.toUpperCase();
    if (valor.startsWith(" ")) return;
    if (e.target.name === "nombre") {
      setNombre(valor);
    }
    if (e.target.name === "celular") {
      setNumeroCelular(valor);
    }
  }

  async function handleOnSubmit(e) {
    e.preventDefault();
    try {
      await addInformation()
        .then(notifySuccess())
        .then(
          navigate(
            setTimeout(() => {
              navigate("/admin/functionary/search-functionary");
            }, 2000)
          )
        );
    } catch (error) {
      console.error(error);
    }
    setNombre("");
    setCliente("");
    setContacto("");
    setNumeroCelular("");
  }

  async function addInformation() {
    try {
      if (nombre !== "") {
        const newFunctionary = {
          name: nombre.toUpperCase(),
          client: cliente.toUpperCase(),
          contact: contacto.toUpperCase(),
          phone: numeroCelular,
          state: statusMessage,
          actualDate: fechaActualizada,
          actualTime: time,
          timeStamp: serverTimestamp(),
          uid: user.uid,
          id: uuidv4(),
        };
        const res = await insertNewFunctionary(newFunctionary);
        newFunctionary.docId = res.id;
        setNombre("");
        setNumeroCelular("");
        setFuncionarios([...funcionarios, newFunctionary]);
        res && res.id && updateDocIdFunctionary(res);
      } else {
        alert("Ingrese los datos correspondientes");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <h1>Agregar funcionario</h1>
          <Link to={"/admin/functionary/search-functionary"}>
            <button>
              <span class="material-icons">manage_search</span>
            </button>
          </Link>
          <form
            className={style.entryContainer}
            action=""
            onSubmit={handleOnSubmit}
          >
            <label htmlFor="nombre">Nombre</label>
            <input
              autoComplete="off"
              className="input"
              type="text"
              name="nombre"
              onChange={handleOnChangeInputs}
              value={nombre}
            />
            <label htmlFor="cliente">Cliente</label>
            <select
              id="clientes"
              className="section"
              name="clientes"
              onChange={(e) => setCliente(e.target.value)}
              value={cliente}
            >
              <option></option>
              {dataClientes.map((cliente) => (
                <option key={cliente.id} value={cliente.name}>
                  {cliente.name}
                </option>
              ))}
            </select>
            <label htmlFor="contacto">Contacto</label>
            <select
              id="contacto"
              className="section"
              name="contacto"
              onChange={(e) => setContacto(e.target.value)}
              value={contacto}
            >
              <option></option>
              <option value={"Spot"}>Spot</option>
              <option value={"Celular"}>Celular</option>
              <option value={"Email"}>Email</option>
            </select>
            <ToastContainer></ToastContainer>
            <label htmlFor="celular">Numero de telefono</label>
            <input
              autoComplete="off"
              className="input"
              type="text"
              name="celular"
              onChange={handleOnChangeInputs}
              value={numeroCelular}
            />
            <div>
              <label htmlFor="activo" className={style.activo}>
                Activo:
              </label>
              <input
                disabled="off"
                checked={isActive}
                className="input"
                type="checkbox"
                name="activo"
                onClick={() => {
                  setIsActive(!isActive);
                }}
              />
            </div>
            <input
              className="btn"
              type="submit"
              value="Crear nuevo funcionario"
              disabled={!botonHabilitado}
            />
          </form>
        </div>
      </Clock>
    </DashboardWrapper>
  );
}
