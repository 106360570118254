import { useEffect, useRef, useState } from "react";
import style from "./cssModules/link.module.css";

function Functionary({
  id,
  actualTime,
  actualDate,
  client,
  contact,
  name,
  phone,
  state,
  timeStamp,
  uid,
  onUpdate,
  onDelete,
}) {
  const [currentName, setCurrentName] = useState(name);
  const [currentPhone, setCurrentPhone] = useState(phone);
  const [currentClient, setCurrentClient] = useState(client);
  const [currentContact, setCurrentContact] = useState(contact);
  const [currentState, setCurrentState] = useState(state);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editClient, setEditClient] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editState, setEditState] = useState(false);

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const clientRef = useRef(null);
  const contactRef = useRef(null);
  const stateRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [editName]);

  useEffect(() => {
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }, [editPhone]);

  useEffect(() => {
    if (clientRef.current) {
      clientRef.current.focus();
    }
  }, [editClient]);

  useEffect(() => {
    if (contactRef.current) {
      contactRef.current.focus();
    }
  }, [editContact]);

  useEffect(() => {
    if (stateRef.current) {
      stateRef.current.focus();
    }
  }, [editState]);

  function handleEditName() {
    setEditName(true);
  }
  function handleEditPhone() {
    setEditPhone(true);
  }
  function handleEditClient() {
    setEditClient(true);
  }
  function handleEditContact() {
    setEditContact(true);
  }
  function handleEditState() {
    setEditState(true);
  }

  function handleChangeName(e) {
    setCurrentName(e.target.value);
  }
  function handleChangePhone(e) {
    setCurrentPhone(e.target.value);
  }
  function handleChangeContact(e) {
    setCurrentContact(e.target.value);
  }
  function handleChangeClient(e) {
    setCurrentClient(e.target.value);
  }
  function handleChangeState(e) {
    setCurrentState(e.target.value);
  }

  function handleBlurName() {
    setEditName(false);
    onUpdate(id, "name", currentName);
  }

  function handleBlurPhone() {
    setEditPhone(false);
    onUpdate(id, "phone", currentPhone);
  }
  function handleBlurContact() {
    setEditContact(false);
    onUpdate(id, "contact", currentContact);
  }
  function handleBlurClient() {
    setEditClient(false);
    onUpdate(id, "client", currentClient);
  }
  function handleBlurState() {
    setEditState(false);
    onUpdate(id, "state", currentState);
  }

  function handleDelete() {
    onDelete(id);
  }

  return (
    <div className={style.link} key={id}>
      <div className={style.linkInfo}>
        <div className={style.linkTitle}>
          {editName ? (
            <>
              <input
                ref={nameRef}
                value={currentName}
                onChange={handleChangeName}
                onBlur={handleBlurName}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditName}>
                <span className="material-icons md-light">edit</span>
              </button>
              <span>
                {currentName} -{" "}
                <small>
                  Fecha de creación: {actualDate} - <time>{actualTime}</time>{" "}
                </small>
              </span>
            </>
          )}
        </div>
        <div>
          {editContact ? (
            <>
              <input
                ref={contactRef}
                value={currentContact}
                onChange={handleChangeContact}
                onBlur={handleBlurContact}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditContact}>
                <span className="material-icons md-light">edit</span>
              </button>
              {currentContact}
            </>
          )}
        </div>
        <div>
          {editPhone ? (
            <>
              <input
                ref={phoneRef}
                value={currentPhone}
                onChange={handleChangePhone}
                onBlur={handleBlurPhone}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditPhone}>
                <span className="material-icons md-light">edit</span>
              </button>
              {currentPhone}
            </>
          )}
        </div>
        <div>
          {editClient ? (
            <>
              <input
                ref={clientRef}
                value={currentClient}
                onChange={handleChangeClient}
                onBlur={handleBlurClient}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditClient}>
                <span className="material-icons md-light">edit</span>
              </button>
              {currentClient}
            </>
          )}
        </div>
        <div>
          {editState ? (
            <>
              <input
                ref={stateRef}
                value={currentState}
                onChange={handleChangeState}
                onBlur={handleBlurState}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditState}>
                <span className="material-icons md-light">edit</span>
              </button>
              {currentState}
            </>
          )}
        </div>
      </div>
      <div className={style.linkActions}>
        <button className={style.btnDelete} onClick={handleDelete}>
          <span className="material-icons">delete</span>
        </button>
      </div>
    </div>
  );
}

export default Functionary;
