import style from './cssModules/autocompleteDiaryComents.module.css'

function AutocompleteDiaryComents({ valorInput }) {
  const options = ["Funcionario(a) confirma inicio de desplazamiento hacia", "Funcionario(a) confirma que se encuentra en desplazamiento", "Funcionario(a) confirma inicio de actividades", "Funcionario(a) confirma la llegada a la poblacion para inicio de las actividades", "Funcionario(a) confirma que se encuentra en desarrollo de sus actividades", "Funcionario(a) no responde las llamadas, se deja mensaje de voz y/o whatsapp", "Funcionario(a) confirma la llegada al aeropuerto para el viaje", "Funcionario(a) confirma la finalizacion de actividades", "Funcionario(a) confirma la finalizacion de comision - se cierra cuadro"];

  const handleInputChange = (e) => {
    const value = e.target.value;
    valorInput(value)
  };

  return (
    <div>
      {/* <textarea aria-live='frameworks' onChange={handleInputChange}></textarea> */}
      <input type="text" list='frameworks' onChange={handleInputChange} className={style.input}/>
      <datalist id='frameworks'>
        {
          options.map((valor) => (
            <option value={valor} key={valor}></option>
          ))
        }
      </datalist>
    </div>
  )
}

export default AutocompleteDiaryComents