import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import { getSolicitudesCliente } from "../firebase/firebase";
import { useAuthContext } from "../contexts/authContext";
import style from "./cssModules/search.module.css";
import TablaDatos from "../components/dataTable";
import ExcelExport from "../components/excelFiles/ExcelExport";
import DataTableClient from "../components/dataTableClient";

function ClientPageView() {
  const { user } = useAuthContext();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const keys = ["functionaries", "risk", "diaryRoute"];
  const [stateSearch, setStateSearch] = useState("Todos");
  const [currentPage, setCurrentPage] = useState(1);
  const [clienteQuery, setClienteQuery] = useState("");
  const [searchPage, setSearchPage] = useState(1);
  const itemsPerPage = 100;

  useEffect(() => {
    if (!!user && user.rol && user.avaiableFor) {
      getSolicitudesCliente(user.avaiableFor, stateSearch)
        .then((res) => {
          console.log(res);
          return setData(res);
        })
        .catch((error) => console.error(error));
    }
  }, [user, stateSearch]);

  const search = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return data
      .filter((item) => {
        const valueMatchesQuery = keys.some((key) => {
          const value = item[key];
          if (Array.isArray(value)) {
            return value.some(
              (element) =>
                typeof element === "string" &&
                element.toLowerCase().includes(query.toLowerCase())
            );
          } else {
            return (
              typeof value === "string" &&
              value.toLowerCase().includes(query.toLowerCase())
            );
          }
        });

        const clienteMatchesQuery =
          !clienteQuery ||
          (stateSearch === "Todos" &&
            item.client.toLowerCase() === clienteQuery.toLowerCase());

        const estadoMatchesQuery =
          stateSearch === "Todos" ||
          (stateSearch !== "Todos" && item.state === stateSearch);

        return clienteMatchesQuery && estadoMatchesQuery && valueMatchesQuery;
      })
      .slice(startIndex, endIndex)
      .map((list) => {
        const sortedDatosAdicionales = [...(list.datosAdicionales || [])].sort(
          (a, b) =>
            (b.timeStamp?.toMillis() || 0) - (a.timeStamp?.toMillis() || 0)
        );

        const objetoMasAntiguo = sortedDatosAdicionales[0];

        return {
          ...list,
          latestNotificationDateAndHour: objetoMasAntiguo
            ? objetoMasAntiguo.notificationDateAndHour?.toMillis() || 0
            : list.notificationDateAndHour?.toMillis() || 0,
          hasDatosAdicionales: !!(
            list.datosAdicionales && list.datosAdicionales.length > 0
          ),
        };
      });
  };

  function handlePreviousPage() {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  }

  function handleNextPage() {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(data.length / itemsPerPage))
    );
  }

  return (
    <DashboardWrapper>
      <>
        <div>
          <form
            action=""
            style={{
              width: "100%",
              fontSize: "15px",
            }}
          >
            <div>
              <div>
                <h1>Busqueda de gerenciamientos de viaje</h1>
              </div>
              <div>
                <div className={style.container}>
                  <div className={style.secondaryContainer}>
                    <label htmlFor="cliente">
                      Buscar funcionario/riesgo/ruta del día
                    </label>
                    <input
                      type="text"
                      className="input"
                      onChange={(e) => setQuery(e.target.value)}
                      value={query}
                    />
                  </div>
                </div>

                <div className={style.displayGrid}>
                  <div className={style.secondaryContainer}>
                    <label htmlFor="">Estado </label>
                    <select
                      name=""
                      id=""
                      onChange={(e) => setStateSearch(e.target.value)}
                      value={stateSearch}
                    >
                      <option value={"Todos"}>Activos</option>
                      <option value={"Nuevo"}>Nuevo</option>
                      <option value={"En proceso"}>En proceso</option>
                      <option value={"Finalizado"}>Finalizado</option>
                      <option value={"Cancelado"}>Cancelado</option>
                    </select>
                  </div>
                </div>
                <div className={style.containerExcelTabla}>
                  <ExcelExport sortedData={search()} />
                </div>
              </div>
            </div>
          </form>
          <div className={style.containerTable}>
            <TablaDatos
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              sortedData={search()}
            />
          </div>
          <div className={style.pagination}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              Anterior
            </button>
            <span>
              Página {currentPage} de{" "}
              {Math.ceil(search().length / itemsPerPage)}
            </span>
            <button
              onClick={handleNextPage}
              disabled={
                currentPage === Math.ceil(search().length / itemsPerPage)
              }
            >
              Siguiente
            </button>
          </div>
        </div>
      </>
    </DashboardWrapper>
  );
}

export default ClientPageView;
