import { useEffect, useState } from "react";
import style from "../components/cssModules/toggleButton.module.css"

const ToggleButton = ({ setOpenModal, color, mensajeJSON, riesgoInput, handleFormReset , setRiskScore }) => {
    const [condicional, setCondicional] = useState(false);
    const [valorRiesgo, setValorRiesgo] = useState(riesgoInput)

    useEffect(() => {
        // Actualizar la prop riesgoInput cada vez que valorRiesgo cambie
        riesgoInput(valorRiesgo)
      }, [valorRiesgo]);

      function handleUpdated(e){
        setValorRiesgo(e.target.value)
        riesgoInput(e.target.value)
      }

    return (
        <div>
            <label className={style.containerToggle}>
                <input type="checkbox" onClick={() => setCondicional(!condicional)} className={style.checkBtn} />
                <span className={style.toggleButton} />
            </label>
            {
                condicional
                    ?
                    <div className={style.evaluationModule}>
                        <button type="button" className='btn' onClick={() => { 
                            setOpenModal(true)
                            setValorRiesgo("")
                            }}>Evaluacion de riesgo</button>
                        <label htmlFor="riesgo" className={style.label}>El nivel de riesgo es: </label>
                        <div className={color}></div>
                        {/* <strong>{puntuacionDeRiesgo}</strong> */}
                        <strong>{mensajeJSON.toUpperCase()}</strong>
                        <strong>{setRiskScore[0]}</strong>

                    </div>
                    :
                    <div>
                        <label htmlFor="" className={style.label}>Riesgo</label>
                        <select onChange={handleUpdated} value={valorRiesgo}>
                            <option value="">Seleccione un valor</option>
                            <option value="Bajo">Bajo</option>
                            <option value="Medio">Medio</option>
                            <option value="Alto">Alto</option>
                            <option value="Crítico">Crítico</option>
                        </select>
                    </div>
            }
        </div>
    )
}

export default ToggleButton