import { Text, StyleSheet, View} from "@react-pdf/renderer"


const styles= StyleSheet.create({
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: -10,
        left: 0,
        right: 0,       
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        textAlign: 'center',
        color: 'grey',
        },
    table:{
        width:'87%',
        height: 36,
        flexDirection:'row',

    }
})
const FooterPdf =()=>{
    
    const currentDate = new Date(); // Obtener la fecha y hora actual
    const formattedDate = currentDate.toLocaleString(); // Formatear la fecha y hora como una cadena legible

return(
    <View style={styles.table}fixed> 
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages} \n \n ${formattedDate}`
            )} />
    </View>
    )
}
export default FooterPdf