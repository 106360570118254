import { createContext, useContext, useState, useEffect } from "react";
import { DataContext } from "./dataContext";

export const AlarmContext = createContext()

let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export function AlarmContextProvider({ children }) {
    const { alarmTime, setAlarmTime } = useContext(DataContext)
    const [hourDigital, setHourDigital] = useState("");
    const [minutesDigital, setMinutesDigital] = useState("");
    const [amPm, setAmPm] = useState("");
    const [dayNow, setDayNow] = useState("");
    const [monthNow, setMonthNow] = useState("");
    const [yearNow, setYearNow] = useState("");
    const [hasAlarm, setHasAlarm] = useState(false);
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const horaCompleta = time.toLocaleTimeString()

    useEffect(() => {
        setInterval(() => {
            let date = new Date();

            let HH = date.getHours(),
                MM = date.getMinutes(),
                day = date.getDate(),
                month = date.getMonth(),
                year = date.getFullYear(),
                ampm;

            if (HH >= 12) {
                HH = HH - 12;
                ampm = "p. m.";
            } else {
                ampm = "a. m.";
            }

            if (HH === 0) HH = 12;
            if (HH < 10) HH = `0${HH}`;
            if (MM < 10) MM = `0${MM}`;

            setHourDigital(HH);
            setMinutesDigital(MM);
            setAmPm(ampm);
            setDayNow(day);
            setMonthNow(months[month]);
            setYearNow(year);
        }, 1000);
    }, []);

    const activeNotifications = {};

    function showNotification(diaryID) {
        // Verificar si la notificación ya está activa para este ID y cerrarla si es necesario
        if (activeNotifications[diaryID]) {
            activeNotifications[diaryID].close();
        }

        // Buscar la alerta correspondiente a diaryID
        const alert = alarmTime.find((alarm) => alarm.id === diaryID);

        // Crear y mostrar la notificación si existe la alerta y openedCount es 0
        if (alert && alert.openedCount === 0) {
            if ('Notification' in window) {
                Notification.requestPermission().then(function (permission) {
                    if (permission === 'granted') {
                        const notification = new Notification('Punto de control pendiente', {
                            body: `El gerenciamiento: ${diaryID}, tiene puntos de control pendientes`,
                            // Otras opciones de configuración
                        });
                        // Guardar la notificación activa en el objeto por su ID
                        activeNotifications[diaryID] = notification;
                        // Configurar un manejador para eliminar la notificación del objeto cuando se cierre
                        notification.onclose = function () {
                            delete activeNotifications[diaryID];
                        };
                        // Incrementar el contador de aperturas
                        alert.openedCount += 1;
                        notification.onclick = function () {
                            window.open(`https://pilgrimsismopiv2.pilgrimsecurity.net/#/solicitude/search-solicitude/${diaryID}`, '_blank'); // Reemplaza con tu URL
                        };
                    }
                });
            }
        }
    }

    const handleAlarmClick = (diaryId) => {
        // Supongamos que tienes una función updateAlarm que actualiza las alarmas
        const updatedAlarms = alarmTime.map((alarm) => {
            if (alarm.id === diaryId) {
                // Incrementa openedCount solo si la alarma coincide con el diaryId
                return { ...alarm, openedCount: alarm.openedCount + 1 };
            }
            return alarm;
        });
        setAlarmTime(updatedAlarms); // Actualiza el estado con las alarmas actualizadas
    };

    alarmTime && alarmTime.forEach((alarma) => {
        let alarmHours = alarma.alert.getHours();
        const alarmMinutes = alarma.alert.getMinutes();
        // Determinar si es "a.m." o "p.m." y ajustar las horas en consecuencia
        let alarmAmPm = "a. m.";
        if (alarmHours >= 12) {
            alarmAmPm = "p. m.";
            if (alarmHours > 12) {
                alarmHours -= 12;
            }
        } else if (alarmHours === 0) {
            alarmHours = 12;
        }
        // Ajustar las horas y minutos con ceros iniciales si son menores de 10
        const formattedAlarmHours = alarmHours < 10 ? `0${alarmHours}` : alarmHours;
        const formattedAlarmMinutes = alarmMinutes < 10 ? `0${alarmMinutes}` : alarmMinutes;
        // Formatear la hora completa en el formato de 12 horas
        const formattedAlarmTime = `${formattedAlarmHours}:${formattedAlarmMinutes} ${alarmAmPm}`;
        if ((formattedAlarmTime === `${hourDigital}:${minutesDigital} ${amPm}`) && alarma.openedCount === 0) {
            showNotification(alarma.id)
        }
    })

    return (
        <AlarmContext.Provider value={{
            time,
            horaCompleta,
            hourDigital,
            minutesDigital,
            amPm,
            dayNow,
            monthNow,
            yearNow,
            alarmTime,
            setAlarmTime, // Asegúrate de que setAlarmTime esté en el contexto
            hasAlarm,
            handleAlarmClick,
            setHasAlarm,
        }}>
            {children}
        </AlarmContext.Provider>
    )
}

export function useAlarmContext() {
    const context = useContext(AlarmContext)
    if (!context) {
        throw new Error("useAlarmContent must be within a AlarmContextProvider")
    }
    return context
}

/* ----------------- funcion para crear alertas (no funciona) ----------------
const respuesta = window.confirm(`Tiene puntos de control pendientes: ${diaryID}. ¿Desea diligenciarlos ahora?`);
        setOpenWindow(false)
        if (respuesta) {
            const link = document.createElement("a");
            link.href = `#/solicitude/search-solicitude/${diaryID}`;
            link.click();
        } */


        /* --------- funcion para crear ventanas ----------
        const newWindow = (diaryID) => {
        const newWindowRef = window.open(
            `https://76.74.150.115/#/solicitude/search-solicitude/${diaryID}`, // URL de la nueva ventana
            "MiNuevaVentana", // Nombre de la ventana (puedes personalizarlo)
            "width=600,height=400" // Especificaciones de la ventana (ancho y alto)
        );
        setOpenWindow(false)
        return newWindowRef;
        
    } */

    /* ----------- notificaciones que permanecen activas ---------------
    // Mantener un objeto para rastrear las notificaciones activas por ID
    const activeNotifications = {};

    function showNotification(diaryID) {
        // Verificar si la notificación ya está activa para este ID y cerrarla si es necesario
        if (activeNotifications[diaryID]) {
            activeNotifications[diaryID].close();
        }

        // Crear y mostrar la notificación
        if ('Notification' in window) {
            Notification.requestPermission().then(function (permission) {
                if (permission === 'granted') {
                    const notification = new Notification('Título de la notificación', {
                        body: `Contenido de la notificación ${diaryID}`,
                        // Otras opciones de configuración
                    });

                    // Guardar la notificación activa en el objeto por su ID
                    activeNotifications[diaryID] = notification;

                    // Configurar un manejador para eliminar la notificación del objeto cuando se cierre
                    notification.onclose = function () {
                        delete activeNotifications[diaryID];
                    };
                }
            });
        }
    } */

    /* ------------ Metodo para pausar notificaciones, no funciona -------------
    const pauseAlarm = (id) => {
        // Obtener las alarmas almacenadas en el localStorage
        const storedAlarms = JSON.parse(localStorage.getItem("alarms")) || [];
        // Filtrar las alarmas para eliminar la que coincide con el ID proporcionado
        const updatedAlarms = storedAlarms.filter((alarm) => alarm.id !== id);
        // Actualizar el localStorage con las alarmas actualizadas
        localStorage.setItem("alarms", JSON.stringify(updatedAlarms));
        // Actualizar el estado de alarmas
        setAlarmTime([...updatedAlarms]);
    }; */