import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from "react"
import style from "./cssModules/search.module.css"
import { getFunctionaries } from "../firebase/firebase"
import DashboardWrapper from "../components/dashboardWrapper"
import Clock from "../components/clock"
import DataFunctionaries from '../components/dataFunctionaries'

export default function SearchFunctionaryView() {
    const [data, setData] = useState([])
    const [query, setQuery] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [searchPage, setSearchPage] = useState(1); // Current page for search results
    const keys = ["client", "contact", "name", "phone"]
    const itemsPerPage = 20;

    useEffect(() => {
        getFunctionaries()
            .then((resFunctionaries) => setData([...resFunctionaries]))
            .catch((error) => console.error(error))
    }, [])

    function next() {
        if (data.filter(funcionario => (
            keys.some((key) => funcionario[key].toUpperCase().includes(query.toUpperCase()))
        )).length > currentPage + itemsPerPage) {
            setCurrentPage(currentPage + itemsPerPage)
            setSearchPage(searchPage + itemsPerPage)
        }
    }


    function back() {
        if (currentPage > 0) {
            setCurrentPage(currentPage - itemsPerPage)
            setSearchPage(searchPage - itemsPerPage)
        }
    }

    function handleSearch(e) {
        setCurrentPage(0)
        const valor = e.target.value
        if (valor.startsWith(" ")) return
        setQuery(valor.toUpperCase())
    }

    const paginacion = () => {
        if (query.length === 0) (
            data.slice(currentPage, currentPage + itemsPerPage)
        )
        const dataOrdenada = [...data].sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
        const datosFiltrados = dataOrdenada.filter(functionary => (
            keys.some(key => functionary[key].toUpperCase().includes(query.toUpperCase()))
        ))
        return datosFiltrados.slice(currentPage, currentPage + itemsPerPage)
    }

    const filteredData = data.filter((funcionario) =>
        keys.some((key) => funcionario[key].toUpperCase().includes(query.toUpperCase()))
    );
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <DashboardWrapper>
            <Clock>
                <div>
                    <form action="">
                        <div>
                            <div>
                                <h1>Busqueda de funcionarios</h1>
                            </div>
                            <div>
                                <div className={style.container}>
                                    <div className={style.secondaryContainer}>
                                        <label htmlFor="funcionario">Buscar por funcionario/cliente/contacto/telefono</label>
                                        <input type="text" className="input" onChange={handleSearch} value={query} />
                                    </div>
                                    <Link to={"/admin/functionary/add-functionary"}>
                                        <button type='button'>
                                            <span className="material-icons">
                                                note_add
                                            </span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={style.containerTable}>
                        <DataFunctionaries data={paginacion()}></DataFunctionaries>
                    </div>
                    <div className={style.pagination}>
                        <button onClick={back} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        <span>
                            Página {Math.ceil(searchPage / itemsPerPage)}/{totalPages}
                        </span>
                        <button onClick={next} >
                            Siguiente
                        </button>
                    </div>
                </div>
            </Clock>
        </DashboardWrapper>
    )
}