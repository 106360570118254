import { useState } from "react";
import style from './cssModules/infoEvaluacionRiesgos.module.css'

function InfoButton() {
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const handleInfoOpen = () => {
        setIsInfoOpen(true);
    };

    const handleInfoClose = () => {
        setIsInfoOpen(false);
    };

    return (
        <div className={style.contenedorBoton}>
            <button type="button" className={style.btnInfo} onMouseEnter={handleInfoOpen} onMouseLeave={handleInfoClose}><span className="material-icons md-light">info</span></button>
            {isInfoOpen && <InfoPopup />}
        </div>
    );
}

function InfoPopup() {
    return <div className={style.infoPopup}>
        <h3 className={style.title}>Criterio evaluacion de riesgo</h3>
        <table>
            <thead>
                <tr>
                    <th>
                        1-2
                    </th>
                    <th>
                        2.1-3.5
                    </th>
                    <th>
                        3.6-4.5
                    </th>
                    <th>
                        4.6-5
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={style.colorVerde}>
                        <label htmlFor="">BAJO</label>
                    </td>
                    <td className={style.colorAmarillo}>
                        <label htmlFor="">MEDIO</label>
                    </td>
                    <td className={style.colorNaranja}>
                        <label htmlFor="">ALTO</label>
                    </td>
                    <td className={style.colorRojo}>
                        <label htmlFor="">CRÍTICO</label>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default InfoButton;