import React, { useRef, useState } from 'react'
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import style from './cssModules/mapsWithASingleDirection.module.css'

const containerStyle = {
    width: '535px',
    height: '250px'
};

const center = {
    lat: 4.679309297317709,
    lng: -74.04776509871293
};

function MapsWithAutocomplete({ datosLgnLat }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY_MAPS,
        libraries: ['places'],
    })

    const [map, setMap] = React.useState(null)
    const [selectedPlace, setSelectedPlace] = useState(null);

    /** @type React.MutableRefObject<HTMLInputElement> */
    const originRef = useRef()

    const handlePlaceSelect = () => {
        const geocoder = new window.google.maps.Geocoder();
        const place = originRef.current.value;

        geocoder.geocode({ address: place }, (results, status) => {
            if (status === 'OK' && results && results.length > 0) {
                const { lat, lng } = results[0].geometry.location;
                setSelectedPlace({ name: place, geometry: { location: { lat: lat(), lng: lng() } } });
                datosLgnLat({ name: place, geometry: { location: { lat: lat(), lng: lng() } } })
            }
        });
    };


    function clearRoute() {
        originRef.current.value = ''
        setSelectedPlace()
        datosLgnLat({ name: '', geometry: { location: { lat: 0, lng: 0 } } })
    }

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <div className={style.container}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={15}
                center={selectedPlace && selectedPlace.geometry ? selectedPlace.geometry.location : center}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                {
                    selectedPlace && selectedPlace.geometry && (
                        <Marker position={selectedPlace.geometry.location} />
                    )
                }

                { /* Child components, such as markers, info windows, etc. */}
                <></>
            </GoogleMap>
            <div className={style.secondContainer}>
                <label htmlFor="origin">Lugar</label>
                <Autocomplete
                    style={{ width: '100%', height: '40px', paddingLeft: '16px', marginTop: '10px', marginBottom: '10px' }}
                    types={['geocode']}
                >
                    <input type="text" ref={originRef} />
                </Autocomplete>
                <button type='button' onClick={handlePlaceSelect} className={style.submitBtn}>Seleccionar</button>
                <button type='button' onClick={clearRoute} className={style.cancelBtn}>Cancelar</button>
            </div>
        </div>
    ) : <></>
}

export default React.memo(MapsWithAutocomplete)
