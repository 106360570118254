import React from "react";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import { useState } from "react";
import style from "./cssModules/riskAssessment.module.css";
import imagenes from "../resources/images/imagenes";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocFullEvaRiesgo from "../components/pdfFiles/riskAssessment/DocFullEvaRiesgo";

//Perfil de personas
const PERFIL_BAJO = 1;
const PERFIL_MEDIO_BAJO = 2;
const PERFIL_MEDIO = 3;
const PERFIL_MEDIO_ALTO = 4;
const PERFIL_ALTO = 5;

//Estado de alerta del departamento
const RIESGO_BAJO_DEPARTAMENTO = 1;
const RIESGO_MEDIO_DEPARTAMENTO = 3;
const RIESGO_ALTO_DEPARTAMENTO = 4;
const RIESGO_CRITICO_DEPARTAMENTO = 5;

//Estado de alerta del municipio
const RIESGO_BAJO_MUNICIPIO = 1;
const RIESGO_MEDIO_MUNICIPIO = 3;
const RIESGO_ALTO_MUNICIPIO = 4;
const RIESGO_CRITICO_MUNICIPIO = 5;

//Incidentes de orden publico
const INCIDENTES_1_SEMESTRE = 1;
const INCIDENTES_2_MESES = 2;
const INCIDENTES_1_MES = 3;
const INCIDENTES_15_DIAS = 4;
const INCIDENTES_ULTIMA_SEMANA = 5;

//Organizaciones delictivas
const NO_INCIDENCIA = 1;
const PRESENCIA_EN_EL_AREA = 3;
const ACCIONES_EN_EL_AREA = 5;

//Protesta social
const PROTESTA_1_SEMESTRE = 1;
const PROTESTA_2_MESES = 2;
const PROTESTA_1_MES = 3;
const PROTESTA_15_DIAS = 4;
const PROTESTA_ULTIMA_SEMANA = 5;

//Comunicaciones
const SENAL_CELULAR = 1;
const SENAL_INTERMITENTE = 3;
const SIN_SENAL = 4;

function RiskAssessment() {
  //state
  const [perfilPersonas, setPerfilPersonas] = useState(0);
  const [estadoAlertaDepartamento, setEstadoAlertaDepartamento] = useState(0);
  const [estadoAlertaMunicipio, setEstadoAlertaMunicipio] = useState(0);
  const [incidentesOrdenPublico, setIncidentesOrdenPublico] = useState(0);
  const [organizacionesDelictivas, setOrganizacionesDelictivas] = useState(0);
  const [protestaSocial, setProtestaSocial] = useState(0);
  const [comunicaciones, setComunicaciones] = useState(0);
  const [clienteEvRiesgo, setClienteEvRiesgo] = useState("");
  const [evaluacionDeRiesgo, setEvaluacionDeRiesgo] = useState({});
  const [observaciones, setObservaciones] = useState("");
  const [funcionarios, setFuncionarios] = useState("");
  const [puntoOrigen, setPuntoOrigen] = useState("");
  const [puntoDestino, setPuntoDestino] = useState("");
  const [fechaDesplazamiento, setFechaDesplazamiento] = useState("");

  const calcRisk = (event) => {
    event.preventDefault();
    let risk =
      ((perfilPersonas + estadoAlertaDepartamento + estadoAlertaMunicipio) *
        0.5) /
        3 +
      ((incidentesOrdenPublico + organizacionesDelictivas + protestaSocial) *
        0.3) /
        3 +
      (comunicaciones * 0.5) / 2;
    const getMensaje = (risk) => {
      if (risk >= 4.6) {
        return "Crítico";
      } else if (risk >= 3.5 && risk < 4.6) {
        return "Alto";
      } else if (risk >= 2.1 && risk < 3.5) {
        return "Medio";
      } else {
        return "Bajo";
      }
    };
    const mensaje = getMensaje(risk);
    const updatedRiskEvaluation = {
      perfilDePersonas: perfilPersonas,
      estadoAlertaDepartamento: estadoAlertaDepartamento,
      estadoAlertaMunicipio: estadoAlertaMunicipio,
      incidentesOrdenPublico: incidentesOrdenPublico,
      organizacionesDelictivas: organizacionesDelictivas,
      protestaSocial: protestaSocial,
      comunicacion: comunicaciones,
      fechaDesplazamiento,
      funcionarios,
      puntoOrigen,
      puntoDestino,
      clienteEvRiesgo,
      observaciones,
      total: risk.toFixed(1),
      mensaje,
    };

    setEvaluacionDeRiesgo(updatedRiskEvaluation);
  };

  function handleChangeInputs(e) {
    const valor = e.target.value;
    if (valor.startsWith(" ")) return;
    if (e.target.name === "cliente") {
      setClienteEvRiesgo(e.target.value);
    }
    if (e.target.name === "puntoDeDestino") {
      setPuntoDestino(e.target.value);
    }
    if (e.target.name === "puntoDeOrigen") {
      setPuntoOrigen(e.target.value);
    }
    if (e.target.name === "fechaDesplazamiento") {
      setFechaDesplazamiento(e.target.value);
    }
    if (e.target.name === "funcionarios") {
      setFuncionarios(e.target.value);
    }
    if (e.target.name === "observaciones") {
      setObservaciones(e.target.value);
    }
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div className={style.boxContent2}>
          <div className={style.modalContainer2}>
            <div className={style.modalTitleContainer}>
              <h2>Evaluación de riesgos</h2>
              <img
                alt="nada"
                src={imagenes.imagenDeRiesgo}
                className={style.watermark}
              ></img>
            </div>
            <section className={style.infoClient}>
              <div>
                <div className={style.body2}>
                  <label htmlFor="">Cliente</label>
                  <input
                    type="text"
                    value={clienteEvRiesgo}
                    name="cliente"
                    onChange={handleChangeInputs}
                  />
                </div>
                <div className={style.body2}>
                  <label htmlFor="">Punto de destino</label>
                  <input
                    type="text"
                    value={puntoDestino}
                    name="puntoDeDestino"
                    onChange={handleChangeInputs}
                  />
                </div>
              </div>
              <div>
                <div className={style.body2}>
                  <label htmlFor="">Fecha de desplazamiento</label>
                  <input
                    type="date"
                    value={fechaDesplazamiento}
                    name="fechaDesplazamiento"
                    onChange={handleChangeInputs}
                  />
                </div>
                <div className={style.body2}>
                  <label htmlFor="">Punto de origen</label>
                  <input
                    type="text"
                    value={puntoOrigen}
                    name="puntoDeOrigen"
                    onChange={handleChangeInputs}
                  />
                </div>
              </div>
            </section>
            <div className={style.infoFuncionaries}>
              <label htmlFor="">Funcionarios</label>
              <input
                type="text"
                value={funcionarios}
                name="funcionarios"
                onChange={handleChangeInputs}
              />
            </div>
            <div className={style.subtitle2}>
              <h2>Información de riesgos</h2>
            </div>
            <div className={style.body2}>
              <ul>
                <li>
                  <label>Perfil de las personas</label>
                  <select
                    name="perfilPersona"
                    id="perfilPersona"
                    onChange={(event) =>
                      setPerfilPersonas(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={PERFIL_BAJO}>Bajo</option>
                    <option value={PERFIL_MEDIO_BAJO}>Bajo-medio</option>
                    <option value={PERFIL_MEDIO}>Medio</option>
                    <option value={PERFIL_MEDIO_ALTO}>Medio-alto</option>
                    <option value={PERFIL_ALTO}>Alto</option>
                  </select>
                </li>
                <li>
                  <label>Estado de alerta departamento</label>
                  <select
                    name="alertaDepartamento"
                    id="alertaDepartamento"
                    onChange={(event) =>
                      setEstadoAlertaDepartamento(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={RIESGO_BAJO_DEPARTAMENTO}>Bajo</option>
                    <option value={RIESGO_MEDIO_DEPARTAMENTO}>Medio</option>
                    <option value={RIESGO_ALTO_DEPARTAMENTO}>Alto</option>
                    <option value={RIESGO_CRITICO_DEPARTAMENTO}>Crítico</option>
                  </select>
                </li>
                <li>
                  <label>Estado de alerta municipio</label>
                  <select
                    name="alertaMunicipio"
                    id="alertaMunicipio"
                    onChange={(event) =>
                      setEstadoAlertaMunicipio(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={RIESGO_BAJO_MUNICIPIO}>Bajo</option>
                    <option value={RIESGO_MEDIO_MUNICIPIO}>Medio</option>
                    <option value={RIESGO_ALTO_MUNICIPIO}>Alto</option>
                    <option value={RIESGO_CRITICO_MUNICIPIO}>Crítico</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className={style.subtitle2}>
              <h2>Amenazas</h2>
            </div>
            <div className={style.body2}>
              <ul>
                <li>
                  <label>Incidentes de orden público</label>
                  <select
                    name="incidentes"
                    id="incidentes"
                    onChange={(event) =>
                      setIncidentesOrdenPublico(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={INCIDENTES_1_SEMESTRE}>
                      Incidentes - 1 semestre
                    </option>
                    <option value={INCIDENTES_2_MESES}>
                      Incidentes - 2 meses
                    </option>
                    <option value={INCIDENTES_1_MES}>
                      Incidentes - 1 meses
                    </option>
                    <option value={INCIDENTES_15_DIAS}>
                      Incidentes - 15 días
                    </option>
                    <option value={INCIDENTES_ULTIMA_SEMANA}>
                      Incidentes - última semana
                    </option>
                  </select>
                </li>
                <li>
                  <label>Organizaciones delictivas</label>
                  <select
                    name="organizacionesDelictivas"
                    id="organizacionesDelictivas"
                    onChange={(event) =>
                      setOrganizacionesDelictivas(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={NO_INCIDENCIA}>No incidencia</option>
                    <option value={PRESENCIA_EN_EL_AREA}>
                      Presencia en el área
                    </option>
                    <option value={ACCIONES_EN_EL_AREA}>
                      Acciones en el área
                    </option>
                  </select>
                </li>
                <li>
                  <label>Protesta social</label>
                  <select
                    name="protestaSocial"
                    id="protestaSocial"
                    onChange={(event) =>
                      setProtestaSocial(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={PROTESTA_1_SEMESTRE}>
                      Incidentes - 1 semestre
                    </option>
                    <option value={PROTESTA_2_MESES}>
                      Incidentes - 2 meses
                    </option>
                    <option value={PROTESTA_1_MES}>Incidentes - 1 meses</option>
                    <option value={PROTESTA_15_DIAS}>
                      Incidentes - 15 días
                    </option>
                    <option value={PROTESTA_ULTIMA_SEMANA}>
                      Incidentes - última semana
                    </option>
                  </select>
                </li>
              </ul>
            </div>
            <div className={style.subtitle2}>
              <h2>Comunicaciones</h2>
            </div>
            <div className={style.body2}>
              <ul>
                <li>
                  <label>Comunicaciones</label>
                  <select
                    name="comunicaciones"
                    id="comunicaciones"
                    onChange={(event) =>
                      setComunicaciones(parseInt(event.target.value))
                    }
                  >
                    <option value=""></option>
                    <option value={SENAL_CELULAR}>Señal celular</option>
                    <option value={SENAL_INTERMITENTE}>
                      Señal intermitente
                    </option>
                    <option value={SIN_SENAL}>No hay señal</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className={style.body2}>
              <h2>Recomendaciones</h2>
              <textarea
                className={style.textArea}
                id=""
                cols=""
                rows=""
                name="observaciones"
                onChange={handleChangeInputs}
                value={observaciones}
              ></textarea>
            </div>
            <div className={style.riskTable}>
              <div>
                <h2 className={style.title2}>Criterio evaluacion de riesgo</h2>
                <table className={style.infoRisksTable}>
                  <thead>
                    <tr>
                      <th>1-2</th>
                      <th>2.1-3.5</th>
                      <th>3.6-4.5</th>
                      <th>4.6-5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={style.colorVerde2}>
                        <label htmlFor="">BAJO</label>
                      </td>
                      <td className={style.colorAmarillo2}>
                        <label htmlFor="">MEDIO</label>
                      </td>
                      <td className={style.colorNaranja2}>
                        <label htmlFor="">ALTO</label>
                      </td>
                      <td className={style.colorRojo2}>
                        <label htmlFor="">CRÍTICO</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className={style.mensajeRiesgo2}>
                El riesgo es: {evaluacionDeRiesgo.total}{" "}
                <b>{evaluacionDeRiesgo.mensaje}</b>
              </p>
              <div className={style.footer2}>
                <button
                  className={style.submitBtn2}
                  onClick={calcRisk}
                  type="button"
                >
                  Calcular
                </button>
                {evaluacionDeRiesgo.total ? (
                  <div className={style.PDFButton}>
                    <PDFDownloadLink
                      document={
                        <DocFullEvaRiesgo guardarData={evaluacionDeRiesgo} />
                      }
                      fileName="reporte.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Generando documento..."
                        ) : (
                          <span className="material-icons md-light">
                            download_for_offline
                          </span>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Clock>
    </DashboardWrapper>
  );
}

export default RiskAssessment;
