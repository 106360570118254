import React, { useEffect, useState, useRef } from "react";
import style4 from "./cssModules/solicitude.module.css";
import { Timestamp } from "firebase/firestore";
import { getFunctionaries } from "../firebase/firebase";

function Solicitude({
  id,
  actualTime,
  actualDate,
  client,
  completeRoute,
  diaryRoute,
  finalDate,
  finalDepartment,
  finalTown,
  functionaries,
  initialDate,
  initialDepartment,
  initialTown,
  observations,
  risk,
  routeImage,
  scrollType,
  state,
  timeStamp,
  uid,
  onUpdateSolicitude,
  isEditable,
}) {
  const [currentDiaryRoute, setCurrentDiaryRoute] = useState(diaryRoute);
  const [editDiaryRoute, setEditDiaryRoute] = useState(false);
  const diaryRouteRef = useRef(null);
  const [currentFunctionaries, setCurrentFunctionaries] =
    useState(functionaries);
  const [editFunctionaries, setEditFunctionaries] = useState(false);
  const functionariesRef = useRef(null);
  const [currentFinalDate, setCurrentFinalDate] = useState(finalDate);
  const [editFinalDate, setEditFinalDate] = useState(false);
  const finalDateRef = useRef(null);
  const [addFunctionary, setAddFunctionary] = useState(false);
  const [dataFuncionarios, setDataFuncionarios] = useState([]);
  const [funcionariosFiltrados, setFuncionariosFiltrados] = useState([]);
  const [currentObservations, setCurrentObservations] = useState(observations);
  const [editObservations, setEditObservations] = useState(false);
  const observationsRef = useRef(null);

  useEffect(() => {
    getFunctionaries()
      .then((res) => setDataFuncionarios(res))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (diaryRouteRef.current) {
      diaryRouteRef.current.focus();
    }
  }, [editDiaryRoute]);

  function handleUpdateDiaryRoute() {
    setEditDiaryRoute(false);
    onUpdateSolicitude(id, "diaryRoute", currentDiaryRoute);
  }

  useEffect(() => {
    if (finalDateRef.current) {
      finalDateRef.current.focus();
    }
  }, [editFinalDate]);

  function handleUpdateFinalDate(e) {
    const newTimestamp = new Date(e.target.value + "T00:00:00");
    const timestamp = Timestamp.fromDate(newTimestamp);
    setCurrentFinalDate(newTimestamp);
    setEditFinalDate(false);
    onUpdateSolicitude(id, "finalDate", timestamp);
  }

  useEffect(() => {
    if (functionariesRef.current) {
      functionariesRef.current.focus();
    }
  }, [editFunctionaries]);

  function handleEditFunctionaries() {
    setEditFunctionaries(false);
    onUpdateSolicitude(id, "functionaries", currentFunctionaries);
  }

  const eliminarFuncionario = (funcionario) => {
    const nuevosFuncionarios = currentFunctionaries.filter(
      (n) => n !== funcionario
    );
    setCurrentFunctionaries(nuevosFuncionarios);
  };

  const filtrarPorCliente = (clienteId) => {
    setAddFunctionary(true);
    const funcionariosFiltrados = dataFuncionarios.filter(
      (dataFuncionario) =>
        dataFuncionario.client.toUpperCase() === clienteId.toUpperCase()
    );
    setFuncionariosFiltrados(funcionariosFiltrados);
  };

  const adicionarFuncionarios = (e) => {
    const opcionSeleccionada = e.target.value;
    setCurrentFunctionaries([...currentFunctionaries, opcionSeleccionada]);
  };

  useEffect(() => {
    if (observationsRef.current) {
      observationsRef.current.focus();
    }
  }, [editObservations]);

  function handleEditObservations() {
    setEditObservations(false);
    onUpdateSolicitude(id, "observations", currentObservations);
  }

  function handleChangeInputs(e) {
    const valor = e.target.value;
    if (valor.startsWith(" ")) return;
    if (e.target.name === "diaryRoute") {
      setCurrentDiaryRoute(e.target.value.toUpperCase());
    }
    if (e.target.name === "observations") {
      setCurrentObservations(e.target.value);
    }
  }

  return (
    <div>
      <div className={style4.margen}>
        <small>
          Fecha de creación: {actualDate} - <time>{actualTime}</time>{" "}
        </small>
      </div>
      <table className={style4.tableInfo}>
        <tbody className={style4.tableBodyDetails}>
          <tr key={initialDate + finalDate}>
            <th className={style4.tableTitles}>
              <label htmlFor="initialDate">Fecha de inicio</label>
            </th>
            <td>
              <p>
                {initialDate
                  .toDate()
                  .toLocaleDateString()
                  .split("/")
                  .reverse()
                  .join("-")}
              </p>
            </td>
          </tr>
          <tr key={finalDate}>
            <th className={style4.tableTitles}>
              <label htmlFor="finalDate">Fecha de fin</label>
            </th>
            <td>
              {editFinalDate ? (
                <span>
                  <input
                    ref={finalDateRef}
                    type="date"
                    value={currentFinalDate}
                    onChange={handleUpdateFinalDate}
                  />
                  {/* <button type='button' onClick={handleUpdateFinalDate}>Submit</button> */}
                </span>
              ) : (
                <span>
                  <p>
                    {finalDate
                      .toDate()
                      .toLocaleDateString()
                      .split("/")
                      .reverse()
                      .join("-")}
                  </p>
                  {isEditable &&
                    (state === "En proceso" || state === "Nuevo") && (
                      <button
                        type="button"
                        onClick={() => setEditFinalDate(true)}
                      >
                        Edit
                      </button>
                    )}
                </span>
              )}
            </td>
          </tr>
          <tr key={client}>
            <th className={style4.tableTitles}>
              <label htmlFor="client">Cliente</label>
            </th>
            <td>
              <p>{client}</p>
            </td>
          </tr>
          <tr key={initialDepartment + initialTown}>
            <th className={style4.tableTitles}>
              <label htmlFor="initialDepartment">Departamento inicial</label>
            </th>
            <td>
              <p>{initialDepartment}</p>
            </td>
          </tr>
          <tr key={initialTown + risk}>
            <th className={style4.tableTitles}>
              <label htmlFor="initialTown">Municipio inicial</label>
            </th>
            <td>
              <p>{initialTown}</p>
            </td>
          </tr>
          <tr key={finalDepartment + completeRoute}>
            <th className={style4.tableTitles}>
              <label htmlFor="finalDepartment">Departamento final</label>
            </th>
            <td>
              <p>{finalDepartment}</p>
            </td>
          </tr>
          <tr key={finalTown}>
            <th className={style4.tableTitles}>
              <label htmlFor="finalTown">Municipio final</label>
            </th>
            <td>
              <p>{finalTown}</p>
            </td>
          </tr>
          <tr key={completeRoute}>
            <th className={style4.tableTitles}>
              <label htmlFor="itinerarioDeViaje">Itinerario de viaje</label>
            </th>
            <td>
              <p>{completeRoute}</p>
            </td>
          </tr>
          <tr>
            <th className={style4.tableTitles}>
              <label htmlFor="rutaDelDia">Ruta del día</label>
            </th>
            <td>
              {editDiaryRoute ? (
                <span>
                  <input
                    ref={diaryRouteRef}
                    type="text"
                    name="diaryRoute"
                    value={currentDiaryRoute}
                    onChange={handleChangeInputs}
                  />
                  <button type="button" onClick={handleUpdateDiaryRoute}>
                    Submit
                  </button>
                </span>
              ) : (
                <span>
                  <p>{diaryRoute}</p>
                  {isEditable &&
                    (state === "En proceso" || state === "Nuevo") && (
                      <button
                        type="button"
                        onClick={() => setEditDiaryRoute(true)}
                      >
                        Edit
                      </button>
                    )}
                </span>
              )}
            </td>
          </tr>
          <tr key={risk}>
            <th className={style4.tableTitles}>
              <label htmlFor="riesgo">Riesgo</label>
            </th>
            <td>
              <p>{risk}</p>
            </td>
          </tr>
          <tr key={functionaries[0]}>
            <th className={style4.tableTitles}>
              <label htmlFor="functionaries">Funcionarios</label>
            </th>
            {editFunctionaries ? (
              <span>
                <ul>
                  {currentFunctionaries.map((functionary, index) => {
                    return (
                      <li key={index}>
                        <span onClick={() => eliminarFuncionario(functionary)}>
                          {functionary}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {addFunctionary ? (
                  <select name="" id="" onChange={adicionarFuncionarios}>
                    <option value="">Seleccione un funcionario</option>
                    {funcionariosFiltrados.map((funcionarioFiltrado) => (
                      <option
                        value={funcionarioFiltrado.name}
                        key={funcionarioFiltrado.id}
                      >
                        {funcionarioFiltrado.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <button type="button" onClick={filtrarPorCliente(client)}>
                    Adicionar Funcionario
                  </button>
                )}
                <button type="button" onClick={handleEditFunctionaries}>
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => setEditFunctionaries(false)}
                >
                  Cancel
                </button>
              </span>
            ) : (
              <span>
                <p>{functionaries && functionaries.join(", ")}</p>
                {isEditable &&
                  (state === "En proceso" || state === "Nuevo") && (
                    <button
                      type="button"
                      onClick={() => setEditFunctionaries(true)}
                    >
                      Edit
                    </button>
                  )}
              </span>
            )}
            {/* <td >
                            <p>{functionaries && functionaries.join(", ")}</p>
                        </td> */}
          </tr>
          <tr key={routeImage}>
            <th className={style4.tableTitles}>
              <label htmlFor="routeImage">Agenda de comisión</label>
            </th>
            <td>
              <a href={routeImage} target="blank">
                <span className="material-icons md-light">descriptions</span>
              </a>
            </td>
          </tr>
          <tr key={scrollType}>
            <th className={style4.tableTitles}>
              <label htmlFor="scrollType">Tipo de desplazamiento</label>
            </th>
            <td>
              <p>{scrollType}</p>
            </td>
          </tr>
          <tr key={observations}>
            <th className={style4.tableTitles}>
              <label htmlFor="observations">Observaciones</label>
            </th>
            <td>
              {editObservations ? (
                <span>
                  <input
                    ref={observationsRef}
                    type="text"
                    name="observations"
                    value={currentObservations}
                    onChange={handleChangeInputs}
                  />
                  <button type="button" onClick={handleEditObservations}>
                    Submit
                  </button>
                </span>
              ) : (
                <span>
                  <p>{observations}</p>
                  {isEditable &&
                    (state === "En proceso" || state === "Nuevo") && (
                      <button
                        type="button"
                        onClick={() => setEditObservations(true)}
                      >
                        Edit
                      </button>
                    )}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Solicitude;
