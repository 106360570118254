import { useEffect, useRef, useState } from "react";
import style from "./cssModules/link.module.css";

function Client({
  id,
  actualTime,
  actualDate,
  name,
  phone,
  state,
  timeStamp,
  uid,
  onUpdate,
  onDelete,
}) {
  const [currentName, setCurrentName] = useState(name);
  const [currentPhone, setCurrentPhone] = useState(phone);
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState(false);

  const nameRef = useRef(null);
  const phoneRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [editName]);

  useEffect(() => {
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }, [editPhone]);

  function handleEditName() {
    setEditName(true);
  }
  function handleEditPhone() {
    setEditPhone(true);
  }
  function handleChangeName(e) {
    setCurrentName(e.target.value);
  }
  function handleChangePhone(e) {
    setCurrentPhone(e.target.value);
  }
  function handleBlurName(e) {
    setEditName(false);
    onUpdate(id, "name", currentName);
  }
  function handleBlurPhone(e) {
    setEditPhone(false);
    onUpdate(id, "phone", currentPhone);
  }
  function handleDelete() {
    onDelete(id);
  }

  return (
    <div className={style.link} key={id}>
      <div className={style.linkInfo}>
        <div className={style.linkTitle}>
          {editName ? (
            <>
              <input
                ref={nameRef}
                value={currentName}
                onChange={handleChangeName}
                onBlur={handleBlurName}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditName}>
                <span className="material-icons md-light">edit</span>
              </button>
              <span>
                {currentName} -{" "}
                {timeStamp && timeStamp.toDate ? (
                  <small>
                    Fecha de creación: {timeStamp.toDate().toLocaleDateString()}{" "}
                    - {timeStamp.toDate().toLocaleTimeString()}
                  </small>
                ) : (
                  <></>
                )}
              </span>
            </>
          )}
        </div>
        <div>
          {editPhone ? (
            <>
              <input
                ref={phoneRef}
                value={currentPhone}
                onChange={handleChangePhone}
                onBlur={handleBlurPhone}
              />
            </>
          ) : (
            <>
              <button className={style.btnEdit} onClick={handleEditPhone}>
                <span className="material-icons md-light">edit</span>
              </button>
              {currentPhone}
            </>
          )}
        </div>
      </div>
      <div className={style.linkActions}>
        <button className={style.btnDelete} onClick={handleDelete}>
          <span className="material-icons">delete</span>
        </button>
      </div>
    </div>
  );
}

export default Client;
