import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSolicitude,
  getDiary,
  getSolicitude,
  updateDiary,
  updateSolicitude,
} from "../firebase/firebase";
import DashboardWrapper from "../components/dashboardWrapper";
import Clock from "../components/clock";
import style4 from "./cssModules/solicitudeDetails.module.css";
import NewDairySolicitudes from "../components/newDairySolicitudes";
import Diary from "../components/diary";
import Solicitude from "../components/solicitude";
import PdfViewer from "../components/pdfViewer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DocumentFull from "../components/pdfFiles/finalReport/DocumentFull";

const SolicitudeDetails = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [dataDiary, setDataDiary] = useState(null);
  const [pdfViewer, setPdfViewer] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSolicitude(params.id)
      .then((res) => setData(res))
      .catch((error) => console.error(error));
  }, [params.id]);

  useEffect(() => {
    getDiary(params.id)
      .then((data) => setDataDiary([...data]))
      .catch((error) => console.error(error));
  }, [params.id]);

  async function handleUpdateDiaries(id, idSolicitude, field, newValue) {
    const diary = dataDiary;
    diary[field] = newValue;
    await updateDiary(id, idSolicitude, diary);
    setDataDiary({ ...diary });
  }

  async function handleUpdateSolicitude(id, field, newValue) {
    const solicitudes = data;
    solicitudes[field] = newValue;
    await updateSolicitude(id, solicitudes);
    setData({ ...solicitudes });
  }

  async function handleUpdateStateSolicitude(id, state) {
    if (data.state === "Nuevo" || data.state === "") {
      const solicitudes = data;
      solicitudes.state = state;
      await updateSolicitude(id, solicitudes);
      setData({ ...solicitudes });
    }
  }

  function handleConfirmationFinishSolicitude() {
    const confirmacion = window.confirm(
      "¿Esta seguro que desea finalizar la solicitud?"
    );
    if (confirmacion) {
      handleUpdateSolicitude(params.id, "state", "Finalizado");
    }
  }

  function handleConfirmationCancelSolicitude() {
    const confirmacion = window.confirm(
      "¿Esta seguro que desea cancelar la solicitud?"
    );
    if (confirmacion) {
      handleUpdateSolicitude(params.id, "state", "Cancelado");
    }
  }

  function handleDeleteSolicitude() {
    const confirmacion = window.confirm(
      "¿Esta seguro que desea eliminar la solicitud?"
    );
    if (confirmacion) {
      deleteSolicitude(params.id);
      navigate("/solicitude/search-solicitude");
    }
  }

  return (
    <DashboardWrapper>
      <Clock>
        <div>
          <button
            onClick={() => navigate("/solicitude/search-solicitude")}
            className={style4.btnGoBack}
            type="button"
          >
            Atras
          </button>
          <div className={style4.boxContent}>
            <div className={style4.boxColored}>
              <h2>Gerenciamiento de viaje</h2>
            </div>
            {data ? (
              <div>
                <div className={style4.mainContainerTable}>
                  <Solicitude
                    id={data.id}
                    docId={data.docId}
                    actualTime={data.actualTime}
                    actualDate={data.actualDate}
                    client={data.client}
                    completeRoute={data.completeRoute}
                    diaryRoute={data.diaryRoute}
                    finalDate={data.finalDate}
                    finalDepartment={data.finalDepartment}
                    finalTown={data.finalTown}
                    functionaries={data.functionaries}
                    initialDate={data.initialDate}
                    initialDepartment={data.initialDepartment}
                    initialTown={data.initialTown}
                    observations={data.observations}
                    risk={data.risk}
                    routeImage={data.routeImage}
                    scrollType={data.scrollType}
                    state={data.state}
                    timeStamp={data.timeStamp}
                    uid={data.uid}
                    onUpdateSolicitude={handleUpdateSolicitude}
                    isEditable={true}
                  />
                </div>
                <div className={style4.deleteContainer}>
                  <button
                    type="button"
                    className={style4.cancelBtn}
                    onClick={handleDeleteSolicitude}
                  >
                    <p className={style4.middle}>Eliminar solicitud</p>
                    <span class="material-icons">delete</span>
                  </button>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          <section>
            <div className={style4.boxContent}>
              <div className={style4.boxColored}>
                <h2>Nuevo punto de control</h2>
              </div>
              {data ? (
                data?.state === "En proceso" ||
                data?.state === "Nuevo" ||
                data?.state === "" ? (
                  <div className={style4.containerDairy}>
                    <NewDairySolicitudes
                      onUpdateSolicitude={handleUpdateStateSolicitude}
                      idSolicitude={params.id}
                      data={data.state}
                    />
                  </div>
                ) : data?.state === "Finalizado" ? (
                  <p className={style4.margin}>Seguimiento finalizado</p>
                ) : (
                  <p className={style4.margin}>Seguimiento cancelado</p>
                )
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </section>
          <section className={style4.diaryHistorial}>
            <div className={style4.boxContent}>
              <div className={style4.boxColored}>
                <h2>Agendamientos de viaje</h2>
              </div>
              {dataDiary ? (
                <table className={style4.tableStyle}>
                  <thead>
                    <tr>
                      <th>Ubicación</th>
                      <th>Comentarios</th>
                      <th>Fecha y hora del reporte</th>
                      <th>Fecha y hora próximo reporte</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataDiary && Array.isArray(dataDiary) ? (
                      [...dataDiary] // Hacemos una copia del array para no modificar el original
                        .sort(
                          (a, b) => b.timeStamp.toDate() - a.timeStamp.toDate()
                        ) // Ordenar por timeStamp en orden descendente
                        .map((list) => (
                          <Diary
                            key={list.id}
                            id={list.id}
                            idSolicitude={list.idSolicitude}
                            comments={list.comments}
                            location={list.location}
                            timeStamp={list.timeStamp}
                            notificationDate={list.notificationDate}
                            notificationHour={list.notificationHour}
                            locationProvitional={list.locationProvitional}
                            notificationDateAndHour={
                              list.notificationDateAndHour
                            }
                            onUpdateDiary={handleUpdateDiaries}
                          />
                        ))
                    ) : (
                      <p>Loading ...</p>
                    )}
                  </tbody>
                </table>
              ) : (
                <p>Loading ... </p>
              )}
            </div>
          </section>
          <section>
            <div className={style4.boxContent}>
              <div className={style4.boxColored}>
                <h2>Reporte PDF</h2>
              </div>
              {data && dataDiary && !pdfViewer ? (
                <div className={style4.btnContainer}>
                  <button
                    className={style4.viewBtn}
                    type="button"
                    onClick={(e) => setPdfViewer(true)}
                  >
                    Visualizar PDF
                  </button>
                  <div className={style4.PDFButton}>
                    <PDFDownloadLink
                      document={
                        <DocumentFull data={data} dataDiary={dataDiary} />
                      }
                      fileName="reporte.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Generando documento..."
                        ) : (
                          <button type="button" className={style4.downloadBtn}>
                            Descargar
                          </button>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
              ) : pdfViewer ? (
                <div className={style4.mainContainerTable}>
                  <PdfViewer data={data} dataDiary={dataDiary} />
                </div>
              ) : null}
            </div>
          </section>
          <section>
            <div className={style4.btnContainer}>
              {data ? (
                (data.state === "En proceso" || data.state === "Nuevo") && (
                  <span>
                    <button
                      type="button"
                      className={style4.finishBtn}
                      onClick={handleConfirmationFinishSolicitude}
                    >
                      Finalizar solicitud
                    </button>
                    <button
                      type="button"
                      className={style4.cancelBtn}
                      onClick={handleConfirmationCancelSolicitude}
                    >
                      Cancelar solicitud
                    </button>
                  </span>
                )
              ) : (
                <div>Loadring...</div>
              )}
            </div>
          </section>
        </div>
      </Clock>
    </DashboardWrapper>
  );
};

export default SolicitudeDetails;
