import { Document, Page,StyleSheet  } from "@react-pdf/renderer";
import HeaderPdf from "./HeaderPdf";
import BodyPag1 from "./BodyPag1";
import FooterPdf from "./FooterPdf";
import WaterMark from "./WaterMark";
import BodyPag2 from "./BodyPag2";
import BodyPag3 from "./BodyPag3";
import BodyPag4 from "./BodyPag4";
import { useState } from "react";

// Crear estilos
const styles = StyleSheet.create({
    pageConfig: {
    marginBottom: 36,
    marginTop:36,
    marginLeft:36,
    marginRight:36,
    fontSize: 12
    }
})

const DocumentFull =({data,dataDiary})=>{
    const [guardarData, setGuardarData] = useState(data)
    const [guardarDataDiary, setGuardarDataDiary] = useState(dataDiary)

    return(
        <Document>
            <Page style={styles.pageConfig}>
                <HeaderPdf />
                <WaterMark />
                <BodyPag1 data={guardarData} dataDiary={guardarDataDiary}/>
                <BodyPag2 data={guardarData} dataDiary={guardarDataDiary}/>
                <BodyPag4 data={guardarData} dataDiary={guardarDataDiary}/>
                <FooterPdf />
            </Page>
        </Document>
    )
}

export default DocumentFull